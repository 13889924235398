const badgeStatusStyle = (payload) => {
    const { label } = payload;
    let variant = 'secondary';

    switch (payload.code) {
    case 'in_progress':
        variant = 'warning';
        break;
    case 'cancelled':
        variant = 'danger';
        break;
    case 'closed':
        variant = 'success';
        break;
    default:
        break;
    }

    return {
        label,
        variant,
    };
};

export { badgeStatusStyle as default };
