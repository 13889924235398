import { HTTP_METHOD_TYPE } from '../utils/constants';
import pageProfile from '../utils/adaptors/page-profile';
import { itemProduct, queryParams } from '../utils/adaptors';

export async function fetchPageProfileService({ $http, id, position = null }) {
    const response = await $http({
        method: HTTP_METHOD_TYPE.GET,
        url: `${$http.API.V3}profiles`,
        params: {
            'per-page': 1,
            'filter[user_id]': id,
            'use-links': 1,
        },
    });

    if (!response.data.data) return response.data;

    const { items } = response.data.data;
    const [firstItem] = items || [];

    return pageProfile(firstItem);
}

export async function fetchPageProfilePurchasedService({ $http, id }) {
    const response = await $http({
        method: HTTP_METHOD_TYPE.GET,
        url: `${$http.API.V3}profiles/${id}/purchased`,
    });

    if (!response.data.data.items) return [];

    const items = response.data.data.items
        .map((item) => itemProduct(
            item,
            {
                ch_id: id,
                ch_type: response.data.data.queryParams && response.data.data.queryParams.ch_type,
            },
            response.data.data.badges,
        ));

    return {
        products: items,
        queryParams: queryParams(response.data.data.queryParams),
    };
}

export async function fetchPageProfileFavoritesService({ $http, id }) {
    const response = await $http({
        method: HTTP_METHOD_TYPE.GET,
        url: `${$http.API.V3}profiles/${id}/favorites`,
    });

    if (!response.data.data.items) return [];

    const items = response.data.data.items
        .map((item) => itemProduct(
            item,
            {
                ch_id: id,
                ch_type: response.data.data.queryParams && response.data.data.queryParams.ch_type,
            },
            response.data.data.badges,
        ));

    return {
        products: items,
        queryParams: queryParams(response.data.data.queryParams),
    };
}
