import { HTTP_METHOD_TYPE } from '../utils/constants';
import { itemProduct, queryParams } from '../utils/adaptors';

export default async function fetchProductsRecommendedInternalService({
    $http,
    params,
    productHistory,
}) {
    const response = await $http({
        method: HTTP_METHOD_TYPE.POST,
        url: `${$http.API.V2}recommended-products`,
        params,
        data: {
            productHistory,
        },
    });

    const filteredProducts = response.data.items
        .map((item) => itemProduct(
            item,
            {
                ch_type: (response.data.query_params && response.data.query_params.ch_type),
                ch_id: params.id,
            },
            response.data.badges,
        ));

    return {
        products: filteredProducts,
        queryParams: queryParams(response.data.query_params),
    };
}
