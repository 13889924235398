import { Timestamp } from '../utils';

import { del } from '../utils/store';
import { fetchParamsService } from '../services';

const expireMinutes = 5;

export default {
    namespaced: true,

    state: () => ({}),

    mutations: {
        SET_PARAMS(state, payload) {
            const isParamsApi = (Object.prototype.hasOwnProperty.call(payload, 'api') && true) || false;
            if (isParamsApi) {
                Object.assign(payload.api, {
                    timestamp: new Date().getTime(),
                });
            }

            Object.keys(payload).forEach((key) => {
                this.$set(state, key, payload[key]);
            });
        },
        SET_PARAM: (state, payload) => {
            const key = Object.keys(payload)[0];
            Object.assign(state, {
                [key]: payload[key],
            });
        },
        DELETE_PARAM(state, payload) {
            del(state, [payload], this.$delete);
        },
    },

    actions: {
        setParams: ({ commit }, payload) => {
            commit('SET_PARAMS', payload);
        },
        setParam: ({ commit }, payload) => {
            commit('SET_PARAM', payload);
        },
        deleteParam: ({ commit }, payload) => {
            commit('DELETE_PARAM', payload);
        },
        async fetchParamsApi(
            { state, commit },
            { forceFetch = false } = { forceFetch: false },
        ) {
            try {
                const cacheExpired = () => Timestamp.expired({
                    timestamp: state.api.timestamp,
                    offsetMinutes: expireMinutes,
                });
                const keepCached = !forceFetch && state.api && !cacheExpired();

                if (keepCached) return;

                const response = await fetchParamsService({
                    $http: this.$http,
                });

                commit('SET_PARAMS', { api: response });
            } catch (error) {
                console.error('fetchParamsApi: ', error);
            }
        },
    },
};
