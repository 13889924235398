import { MONTHS } from '../constants';

export default {
    install(app) {
        const [vueVersion] = app.version.split('.');
        // TODO remove app.prototype when vue 2 is no longer supported
        const vuePrototype = vueVersion === '2' ? app.prototype : app.config.globalProperties;

        vuePrototype.$MONTHS = MONTHS;
    },
};
