export default (payload) => {
    const output = {
        title: '',
        meta: [],
        link: [],
    };

    payload.filter((meta) => (
        ['meta', 'link'].includes(meta.tag)
        && meta.attributes && Object.keys(meta.attributes).length
    ) || (
        meta.tag === 'title'
        && meta.body
    )).forEach(({ tag, body, attributes }) => {
        if (tag === 'title') {
            output.title = body;
        } else {
            output[tag].push(attributes);
        }
    });

    return output;
};
