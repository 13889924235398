import notifications from './notifications';
import { fetchSwitchesService, saveUserPreferencesService } from '../../services';

const preparePrefs = (defaults, payload) => defaults
    .map((pref) => (payload.includes(pref) ? pref : `~${pref}`))
    .join(',');

export default {
    namespaced: true,

    state: () => ({
        switch: {
            web: {
                header: {
                    btnFavorites: false,
                    formSearch: false,
                    formSearchSuggests: false,
                    formSearchProductHistory: false,
                    navCategories: false,
                },
                pageHome: {
                    sectionBlog: false,
                    sectionProductHistory: false,
                    sectionProductPersonalized: false,
                    sectionHero: false,
                },
                pageProduct: {
                    btnFavorites: false,
                    btnAddToCart: true,
                    listRecommended: false,
                    listSimilar: false,
                    listHistory: false,
                    listReviews: false,
                    recommendedTestCategories: [],
                },
                pageListing: {
                    c: {
                        listingPersonalized: false,
                    },
                    s: {
                        listingPersonalized: false,
                    },
                },
                pageSearch: {
                    listingPersonalized: false,
                },
                pageCart: {
                    listRecommended: false,
                },
                baseItemProduct: {
                    btnFavorites: false,
                },
                global: {
                    phoneNumber: false,
                    userSsr: false,
                    botSsr: false,
                    cns: false,
                    pages: {
                        account: {
                            returns: false,
                        },
                    },
                },
            },
            // TODO remove not used props from `app.*` after deleting old catalog-app project
            app: {
                header: {
                    formSearch: false,
                    formSearchSuggests: false,
                },
                menu: {
                    btnFavorites: false,
                },
                pageHome: {
                    sectionBlog: false,
                    // for web, this was renamed into sectionProductHistory
                    sectionAccountProductsPreview: false,
                },
                pageProduct: {
                    btnFavorites: false,
                    listRecommended: false,
                    listHistory: false,
                    recommendedTestCategories: [],
                },
                pageCart: {
                    listRecommended: false,
                },
                formLogin: {
                    btnGoogle: false,
                    btnApple: false,
                    btnFacebook: false,
                },
                baseItemProduct: {
                    btnFavorites: false,
                },
                global: {
                    pages: {
                        account: {
                            returns: false,
                        },
                    },
                },
            },
            serverLoadedData: null,
        },
        isSearchOpened: false,
        gdpr: {
            1: {
                enabled: null,
            },
            5: {
                enabled: null,
            },
            6: {
                enabled: null,
            },
        },
        loading: {
            web: {
                showAppLoader: null,
                showPageLoader: null,
                pageShow: null,
                appHasData: null,
            },
        },
        isVisible: {
            web: {
                footer: null,
                appBtnBackToTop: null,
                pageProduct: {
                    cta: {
                        intersectionRatio: null,
                        boundingClientRectTop: null,
                    },
                },
            },
        },
    }),

    getters: {
        gdprDefaultKeys: (state) => Object.keys(state.gdpr),
    },

    mutations: {
        SET_SWITCH(state, payload) {
            Object.keys(state.switch).forEach((key) => {
                if (!Object.prototype.hasOwnProperty.call(payload, key)) return;

                this.$set(state.switch, key, payload[key]);
            });
        },
        SET_GDPR: (state, payload) => {
            payload.defaults.forEach((key) => {
                const enabled = !!(payload.data.includes(key));
                state.gdpr[key].enabled = enabled;
            });
        },
        RESET_GDPR: (state) => {
            Object.keys(state.gdpr).forEach((key) => {
                state.gdpr[key].enabled = null;
            });
        },
        TOGGLE_SEARCH: (state, payload) => {
            let newValue = !state.isSearchOpened;
            if (typeof payload === 'boolean') newValue = payload;
            state.isSearchOpened = newValue;
        },
        SET_LOADING: (state, payload) => {
            state.loading.web = {
                ...state.loading.web,
                ...payload,
            };
        },
    },

    actions: {
        async fetchSwitches(
            { state, commit, rootState },
            { source, lang } = {
                source: rootState.params.source,
                lang: rootState.params.country.lang,
            },
        ) {
            if (state.switch.serverLoadedData) {
                commit('SET_SWITCH', { serverLoadedData: false });
                return;
            }

            const response = await fetchSwitchesService({
                $http: this.$http,
                params: {
                    source,
                    lang,
                },
            });

            if (!response) return;

            if (typeof window === 'undefined') {
                response.serverLoadedData = true;
            }
            commit('SET_SWITCH', response);
        },
        saveGdprPreferences: ({ dispatch, rootGetters }, payload) => {
            const isUserLoggedIn = rootGetters['user/isLoggedin'];

            if (isUserLoggedIn) { dispatch('saveGdprPreferencesUser', payload); }

            dispatch('saveGdprPreferencesCookies', payload);
        },
        saveCookieConsent({ dispatch }, payload) {
            const ccAnalytics = payload.includes('5') ? 'granted' : 'denied';
            const ccAds = payload.includes('6') ? 'granted' : 'denied';

            dispatch('storage/add', {
                key: 'cc_analytics',
                value: ccAnalytics,
                expires: 60 * 60 * 24 * 365,
            }, { root: true });
            dispatch('storage/add', {
                key: 'cc_ads',
                value: ccAds,
                expires: 60 * 60 * 24 * 365,
            }, { root: true });
        },
        saveGdprPreferencesCookies: ({ getters, dispatch }, payload) => {
            const prefs = preparePrefs(getters.gdprDefaultKeys, payload);

            dispatch('storage/add', {
                key: 'cookies_consent',
                value: prefs,
                expires: 60 * 60 * 24 * 365,
            }, { root: true });
        },
        saveGdprPreferencesUser({ getters, rootState }, payload) {
            const prefs = preparePrefs(getters.gdprDefaultKeys, payload);

            saveUserPreferencesService({
                $http: this.$http,
                params: {
                    source: rootState.params.source,
                    lang: rootState.params.country.lang,
                    accessToken: rootState.storage.api_v2_token,
                },
                data: prefs,
            }).catch((error) => {
                console.error('saveUserPreferencesService: ', error);
            });
        },
        setGdpr({ commit, getters }, payload) {
            commit('SET_GDPR', {
                defaults: getters.gdprDefaultKeys,
                data: payload,
            });
        },
        resetGdpr({ commit }) {
            commit('RESET_GDPR');
        },
        toggleSearch: ({ commit }, payload) => {
            commit('TOGGLE_SEARCH', payload);
        },
        setLoading: ({ commit }, payload) => {
            commit('SET_LOADING', payload);
        },
        deleteTrackingCookies({ dispatch }) {
            dispatch('storage/delete', 'globalSession', { root: true });
        },
    },

    modules: {
        notifications,
    },
};
