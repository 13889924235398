export default class FilterEvent {
    /**
     * @param {Filter} filter
     * @param {boolean|string|Object} value
     */
    constructor(filter, value) {
        this.id = filter.id;
        this.key = filter.key;
        this.isDynamic = filter.isDynamic;
        this.value = (!value ? [] : ((Array.isArray(value) && value) || [value]));
        this.metas = filter.metas;
    }
}
