import toBuilder from '../toBuilder';

const productResealedAdaptor = (payload, queryParams) => ({
    id: payload.id,
    name: payload.short_name,
    to: toBuilder(payload.slug, queryParams),
    priceComplete: payload.completePrice,
    ...(payload.newPrice && { priceNew: payload.newPrice }),
    ...(payload.description && { description: payload.description }),
});

export default productResealedAdaptor;
