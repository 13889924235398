import { fetchMenuCategoriesService } from '../../services';

export default {
    namespaced: true,

    state: () => ([]),

    mutations: {
        SET_DATA: (state, payload) => {
            state.splice(0, state.length);
            state.push(...payload);
        },
    },

    actions: {
        async fetchData({ state, commit }) {
            const stateHasData = state.length;

            if (stateHasData) return;

            await fetchMenuCategoriesService({
                $http: this.$http,
                showBlog: false,
            }).then((response) => {
                commit('SET_DATA', response);
            }).catch((error) => {
                console.error('fetchMenuCategoriesService: ', error);
            });
        },
    },
};
