export default {
    isFn: true,
    bind(el, binding) {
        if (!binding.value) return;

        let obj = {
            timer: null,
            speed: 100,
        };

        Object.assign(el, { hover: obj });

        el.addEventListener('mouseover', (event) => {
            binding.value('mouseover', event);
            obj = el.hover;
            if (obj.timer === null) {
                obj.timer = setTimeout(() => {
                    binding.value('mouseover-delayed', event);
                }, obj.speed);
                Object.assign(el, { hover: obj });
            }
        });

        el.addEventListener('mouseleave', (event) => {
            binding.value('mouseleave', event);
            obj = el.hover;
            if (obj.timer !== null) {
                clearTimeout(obj.timer);
                obj.timer = setTimeout(() => {
                    binding.value('mouseleave-delayed');
                    obj.timer = null;
                }, obj.speed);
            }
            Object.assign(el, { hover: obj });
        });

        el.addEventListener('click', () => {
            binding.value('click');
        });
    },
    unbind(el) {
        const obj = el.hover || {};
        if (obj.timer !== null) {
            clearTimeout(obj.timer);
        }
        obj.timer = null;
        Object.assign(el, { hover: obj });
    },
};
