import { HTTP_METHOD_TYPE } from '../utils/constants';

const cacheForce1h = () => {
    const date = new Date();
    return [
        date.getFullYear(),
        date.getMonth() + 1,
        date.getDate().toString().padStart(2, '0'),
        date.getHours().toString().padStart(2, '0'),
    ].join('');
};

export default async function fetchTranslateService({ $http, lang, cacheForce }) {
    const response = await $http({
        method: HTTP_METHOD_TYPE.GET,
        url: `${$http.API.V1}translate`,
        params: {
            key: 'defaults',
            language: lang,
            cacheForce: cacheForce || cacheForce1h(),
        },
    });

    return response.data;
}
