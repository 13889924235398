export default function toBuilder(payload, queryParams = {}) {
    let output = `/${payload}`;

    if (!queryParams) return output;

    const params = Object.entries(queryParams);
    const paramsUrl = params.reduce((acc, param, index) => (index === params.length - 1
        ? `${acc}${param[0]}=${param[1]}`
        : `${acc}${param[0]}=${param[1]}&`), '');
    output += `?${paramsUrl}`;

    return output;
}
