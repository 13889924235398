export default {
    data: () => ({
        modals: {},
    }),

    methods: {
        openModal(modal, payload = true) {
            if (typeof payload !== 'boolean') {
                Object.assign(payload, {
                    show: true,
                });
            }

            this.$set(this.modals, modal, payload);
        },
        closeModal(modal, payload = false) {
            if (typeof payload !== 'boolean') {
                Object.assign(payload, {
                    show: false,
                });
            }

            this.$set(this.modals, modal, payload);
        },
    },
};
