import { HTTP_METHOD_TYPE } from '../utils/constants';
import { itemProductCard } from '../utils/adaptors';

export default async function fetchProductPopularService({ $http, params }) {
    const response = await $http({
        method: HTTP_METHOD_TYPE.GET,
        url: `${$http.API.V3}top-conversion-rate-products/${params.id}`,
        params,
    });

    const items = response.data.items && response.data.items
        .map((item) => itemProductCard({
            ...item,
            queryParams: {
                ch_id: response.data.query_params.ch_id,
                ch_type: response.data.query_params.ch_type,
            },
        }));

    return {
        sectionTitleKey: 'views_saleevent_title_popular_products',
        ...(items && items.length && { items }),
    };
}
