import { EventSourcePolyfill } from 'event-source-polyfill';
import { HTTP_METHOD_TYPE } from '../utils/constants';

export async function fetchConfigService({ $http, params }) {
    const response = await $http({
        method: HTTP_METHOD_TYPE.GET,
        url: `${$http.API.BASEURL}topics`,
        params,
    });

    return response.data;
}

export async function createEventSourceInstance(payload) {
    const url = new URL(payload.hub);
    payload.topics.forEach((topic) => {
        url.searchParams.append('topic', topic);
    });

    // Polyfill needed because EventSource does not support custom header
    const evtSource = new EventSourcePolyfill(url, {
        withCredentials: false,
        headers: {
            Authorization: `Bearer ${payload.token}`,
        },
    });

    const { events } = payload;
    events.names.forEach((name) => {
        evtSource.addEventListener(name, events.callback);
    });

    return evtSource;
}
