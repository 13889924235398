const reasons = [
    {
        key: 1,
        label: 'controllers_myaccount_actionReturOrder_reasons_0002',
    },
    {
        key: 2,
        label: 'controllers_myaccount_actionReturOrder_reasons_0003',
    },
    {
        key: 3,
        label: 'controllers_myaccount_actionReturOrder_reasons_0004',
    },
    {
        key: 5,
        label: 'controllers_myaccount_actionRetur_reasons_0007',
    },
    {
        key: 6,
        label: 'controllers_myaccount_actionReturOrder_reasons_0006',
    },
];

const stepsNumbers = {
    client: 1,
    seller: 2,
    products: 3,
    pickupDate: 4,
    packagesNumber: 5,
    address: 6,
    refundMethod: 7,
    iban: 8,
};

const refundMethods = [
    {
        key: 'credit card',
        label: 'views_myaccount_retur_0022',
    },
    {
        key: 'bank transfer',
        label: 'views_cart_loadcart_0015',
    },
];

function getPickupDates(pickupDates) {
    if (!pickupDates) {
        return [];
    }
    return Object.entries(pickupDates).reduce((acc, [key, value]) => [...acc, {
        key,
        value,
    }], []);
}

function getClientGroups(payload) {
    const clientGroups = {};
    const pickupDates = getPickupDates(payload.pickup_dates);
    const packageInfo = payload.package_info;
    payload.orders.forEach((order) => {
        const orderProducts = order.products.map((product) => ({
            ...product,
            order: order.id,
            payment_type: order.payment_type,
        }));

        if (!clientGroups[order.client]) {
            clientGroups[order.client] = { sellers: {}, client: order.client };
        }

        if (!clientGroups[order.client].sellers[order.seller.hash]) {
            clientGroups[order.client].sellers[order.seller.hash] = {
                seller: payload.sellers[order.seller.hash],
                orders: [order],
                products: [...orderProducts],
            };
        } else {
            clientGroups[order.client].sellers[order.seller.hash].orders.push(order);
            clientGroups[order.client].sellers[order.seller.hash].products.push(...orderProducts);
        }
    });

    const clients = payload.clients.map((client) => ({
        id: client.id,
        name: client.name,
    }));

    return {
        clients,
        reasons,
        pickupDates,
        packageInfo,
        stepsNumbers: { ...stepsNumbers },
        data: { clientGroups, refundMethods },
    };
}

export default (payload) => ({ ...getClientGroups(payload) });
