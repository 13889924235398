export default (payload) => ({
    hasExpiredCart: payload.has_expired_cart,
    products:
        payload.has_expired_cart
            ? payload.expired_products : [...payload.products, ...payload.unavailableProducts],
    creditAmount: payload.credit_amount,
    currency: payload.currency,
    totalProductsFee: payload.total_products,
    priceConversion: payload.priceConversion,
    costAlert: payload.cost_alert,
    displayTotal: !payload.has_expired_cart && !payload.unavailableProducts.length,
    hasProducts: payload.has_expired_cart ? false : payload.products.length > 0,
});
