export default {
    computed: {
        metas() {
            // TODO moves currentPage closer to the page component, where page key is known
            const page = this.$store.getters['pages/currentPage'](this.$route.fullPath)
                || this.$store.getters['pages/currentPage'](this.$route.path);
            return (Object.prototype.hasOwnProperty.call(page, 'meta') && page.meta) || false;
        },
        skipTrackingMobileApp() {
            const { isMobileApp, isTrackingAllowed } = this.$store.state.storage;

            return ((isMobileApp === 'true' || isMobileApp === true)
                && (isTrackingAllowed === 'false' || isTrackingAllowed === false));
        },
    },

    data: () => ({
        htmlAttrs: {},
        script: [],
        noscript: [],
        dangerouslyDisableSanitizersByTagID: {},
    }),

    watch: {
        '$store.state.ui.client.activityStarted': function onActivityStarted(value) {
            if (!value) return;

            this.onActivityStarted();
        },
    },

    mounted() {
        const hasWindowGtm = !!window.google_tag_manager;
        if (hasWindowGtm) this.onLoadedGtm();
        else window.addEventListener('gtm.loaded', () => this.onLoadedGtm());
    },

    methods: {
        async onActivityStarted() {
            await new Promise((resolve) => setTimeout(resolve, 5000));

            const storeParamsApi = this.$store.state.params.api;

            if (!this.skipTrackingMobileApp) {
                const gtmId = storeParamsApi.GOOGLE.GTM.ID;
                this.addGtm({ gtmId });
                this.addGtmNoscript({ gtmId });
            }
        },
        addGtm({ gtmId }) {
            const gtm = {
                script: [{
                    key: 'gtmScript',
                    innerHTML: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                                'https://www.googletagmanager.com/gtm.js?id='+i+dl;
                                j.addEventListener('load', function() {
                                    var _ge = new CustomEvent('gtm.loaded', { bubbles: true });
                                    d.dispatchEvent(_ge);
                                });
                                f.parentNode.insertBefore(j,f);
                                })(window,document,'script','dataLayer','${gtmId}');`,
                    type: 'text/javascript',
                }],
                dangerouslyDisableSanitizersByTagID: {
                    gtmScript: ['innerHTML'],
                },
            };

            this.script.push(...gtm.script);
            Object.assign(
                this.dangerouslyDisableSanitizersByTagID,
                gtm.dangerouslyDisableSanitizersByTagID,
            );
        },
        onLoadedGtm() {
            this.$store.dispatch('datalayer/sendStoredEventsToGTM');
        },
        addGtmNoscript({ gtmId }) {
            const gtm = {
                noscript: [{
                    key: 'gtmNoscript',
                    tagPosition: 'bodyOpen',
                    innerHTML: `<iframe src="https://www.googletagmanager.com/ns.html?id=${gtmId}" height="0" width="0" style="display:none;visibility:hidden"></iframe>`,
                }],
                dangerouslyDisableSanitizersByTagID: {
                    gtmNoscript: ['innerHTML'],
                },
            };

            this.noscript.push(...gtm.noscript);
            Object.assign(
                this.dangerouslyDisableSanitizersByTagID,
                gtm.dangerouslyDisableSanitizersByTagID,
            );
        },
    },

    head() {
        const lang = this.$store.state.params.api?.lang?.iso;
        this.htmlAttrs.lang = lang;

        const output = {
            titleTemplate: (titleChunk) => titleChunk || null,
            htmlAttrs: {
                ...this.htmlAttrs,
            },
            meta: [
                { charset: 'utf-8' },
                { name: 'viewport', content: 'width=device-width, initial-scale=1, shrink-to-fit=no' },
            ],
            style: [
                // TODO uncomment when CWV tests are done
                // {
                //     key: 'styleFont',
                //     cssText: `@font-face {
                //         font-family: "Ping";
                //         src:
                //             url("/fonts/ping.woff2") format("woff2"),
                //             url("/fonts/ping.woff") format("woff");
                //         font-display: swap;
                //     }
                //     body {
                //         font-family: "Ping", system-ui;
                //     }`,
                //     type: 'text/css',
                // },
            ],
            link: [
                // TODO uncomment when CWV tests are done
                // {
                //     rel: 'preload',
                //     as: 'font',
                //     type: 'font/woff2',
                //     href: '/fonts/ping.woff2',
                // },
            ],
            script: [
                ...this.script,
                ...((this.schemaOrg && this.schemaOrg.script) || []),
            ],
            noscript: [
                ...this.noscript,
            ],
            __dangerouslyDisableSanitizersByTagID: {
                styleFont: ['cssText'],
                ...this.dangerouslyDisableSanitizersByTagID,
                ...((this.schemaOrg && this.schemaOrg.sanitizers) || {}),
            },
        };

        if (this.metas) {
            output.title = this.metas.title;
            output.meta.push(...this.metas.meta);
            output.link.push(...this.metas.link);
        }

        return output;
    },
};
