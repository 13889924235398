const banners = (payload, position) => ({
    [position]: payload.map((item) => ({
        name: item.name,
        state: item.state,
        app_url: item.app_url,
        relative_url: item.relative_url,
        link: item.link,
        target: item.target,
        manifest: item.manifest,
        end_date: item.end_date,
    })),
});

const heroItem = (payload) => payload.map((item) => ({
    name: item.name,
    path: item.relative_url,
    link: item.link,
    linkTarget: item.target,
    image: item.image,
}));

const hero = (payload) => {
    const sections = payload.hero;

    if (!sections) {
        return {};
    }

    const heroObject = {};

    Object.entries(sections).forEach(([section, sectionValue]) => {
        heroObject[section] = heroItem(sectionValue);
    });

    return { hero: heroObject };
};

export { hero, banners };
