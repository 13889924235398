<template>
    <router-link
        v-slot="{ navigate, href: routerHref }"
        custom
        :to="to"
    >
        <component
            :is="tag"
            :href="tag === 'a' && (href || routerHref)"
            :type="tag === 'button' && toButtonType"
            :class="[
                { [`btn btn-${variant}`]: variant },
                { [`btn-${size}`]: size },
                { 'btn-loading': loading },
            ]"
            :disabled="disabled || loading"
            @click="onClick($event, navigate)"
        >
            <template v-if="loading">
                <slot />
                <span
                    role="status"
                    aria-hidden="true"
                    class="spinner-border spinner-border-sm"
                    :class="[
                        { 'ms-2': !!$slots.default },
                    ]"
                />
            </template>
            <slot v-else />
        </component>
    </router-link>
</template>

<script>
export default {
    name: 'BaseBtn',

    props: {
        variant: {
            type: [Boolean, String],
            default: 'secondary',
        },
        size: {
            type: [String, Boolean],
            default: false,
        },
        href: {
            type: String,
            default: '',
        },
        to: {
            type: [String, Object],
            default: '',
        },
        tag: {
            type: String,
            default() {
                let output = 'button';

                if (this.href) output = 'a';

                return output;
            },
        },
        toButtonType: {
            type: String,
            default: 'button',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },

    methods: {
        onClick($event, navigate) {
            if (this.href) return this.$router.resolve(this.to).href;
            if (this.to) return navigate($event);

            return false;
        },
    },
};
</script>

<style lang="scss">
@import "./../scss/05-components/base-btn";
</style>
