import { capitalize } from '../filters';

const settings = {
    timeout: 1000,
    items: 6,
};
const timeoutID = [];

export default {
    data: () => ({
        datalayerItems: {},
        observers: {},
    }),

    beforeRouteUpdate(to, from, next) {
        Object.keys(this.observers).forEach((key) => {
            this.observers[key].disconnect();
        });

        timeoutID.forEach((id) => clearTimeout(id));
        timeoutID.length = 0;

        next();
    },

    watch: {
        datalayerItems: {
            handler(value) {
                Object.keys(value).forEach((key) => {
                    const list = value[key];

                    if (
                        list.data.length && (
                            list.data.length >= settings.items
                            || list.data.length === list.remaining
                        )
                    ) {
                        this.sendItemsView({
                            eventName: list.eventName,
                            items: JSON.parse(JSON.stringify(list.data)),
                            listName: list.listName,
                            listType: list.listType,
                        });

                        list.remaining -= list.data.length;
                        list.data.length = 0;
                    }
                });
            },
            deep: true,
        },
    },

    methods: {
        datalayerList({
            eventName,
            data,
            listName,
            listType,
            ref,
            selector,
            datalayerListDetailsName,
        }) {
            if (
                !data || !data.length
                || typeof ref === 'undefined'
            ) return;

            const parent = ref.$el.querySelector(selector).closest('.row');

            if (!parent) return;

            this.$set(this.datalayerItems, this.getListKey(ref), {
                data: [],
                eventName,
                ...(listName && { listName }),
                ...(listType && { listType }),
                remaining: null,
            });

            this.init({
                eventName,
                data,
                listName,
                listType,
                ref,
                parent,
                selector,
                datalayerListDetailsName,
            });

            this.attachMutation({ ref, parent, selector });
        },
        init({
            eventName,
            data: dataItems,
            listName,
            listType,
            ref,
            parent,
            selector,
            datalayerListDetailsName,
        }) {
            const elList = ref.$el;
            const elsItem = elList.querySelectorAll(selector);
            const routeName = this.$route.name;

            this.datalayerItems[this.getListKey(ref)].remaining = dataItems.length;

            this.initObserver({
                dataItems,
                ref,
                parent,
                selector,
                datalayerListDetailsName,
            });

            for (let index = 0; index < elsItem.length; index += 1) {
                const el = elsItem[index];
                const elData = dataItems[index];

                // attach on click
                el.addEventListener('click', () => {
                    this.sendItemClickEvent({
                        list: {
                            listName,
                            ref,
                            eventName,
                            listType,
                            datalayerListDetailsName,
                        },
                        item: elData,
                        index,
                        routeName,
                    });
                });

                // attach observe
                this.observers[`${this.getListKey(ref)}-intersection`].observe(el);
            }
        },
        attachMutation({ ref, parent, selector }) {
            const onMutation = (mutations) => mutations.forEach((mutation) => {
                if (mutation.type !== 'childList' || mutation.addedNodes.length === 0) return;

                const el = mutation.addedNodes[0].querySelector(selector);
                this.observers[`${this.getListKey(ref)}-intersection`].observe(el);
            });
            this.observers[`${this.getListKey(ref)}-mutation`] = new MutationObserver(onMutation);
            this.observers[`${this.getListKey(ref)}-mutation`].observe(parent, { attributes: false, childList: true, subtree: false });
        },
        initObserver({
            dataItems,
            ref,
            parent,
            datalayerListDetailsName,
        }) {
            // callback
            const onIntersection = (entries) => {
                for (let index = 0; index < entries.length; index += 1) {
                    const entry = entries[index];
                    const { target } = entry;
                    const entryCol = target.parentNode;
                    const entryIndex = Array.prototype.indexOf.call(parent.children, entryCol);

                    if (entry.isIntersecting && entry.intersectionRatio === 1) {
                        const dataItem = dataItems[entryIndex];

                        timeoutID[entryIndex] = setTimeout(() => {
                            // copy item from store to local datalayerItems
                            this.datalayerItems[this.getListKey(ref)].data.push({
                                ...dataItem,
                                position: entryIndex + 1,
                                item_list_name: datalayerListDetailsName,
                            });

                            this.observers[`${this.getListKey(ref)}-intersection`].unobserve(target);
                        }, settings.timeout);
                    } else {
                        clearTimeout(timeoutID[entryIndex]);
                    }
                }
            };

            // settings
            this.observers[`${this.getListKey(ref)}-intersection`] = new IntersectionObserver(
                onIntersection,
                { threshold: 1.0 },
            );
        },
        sendItemClickEvent({
            list: {
                listName,
                ref,
                eventName,
                listType,
                datalayerListDetailsName,
            },
            item,
            index,
            routeName,
        }) {
            // Routename + List1 || Routename + Carousel1
            // || Routename + Categoryname || Routename + Other List
            const getListName = [
                capitalize(routeName.toLowerCase()),
                capitalize((listName || this.getListKey(ref)).toLowerCase()),
            ].join(' - ');
            const payload = {
                event: eventName,
                listName: getListName,
                ...(listType && { listType }),
                data: {
                    ...item,
                    datalayer: {
                        ...item.datalayer,
                        position: index + 1,
                    },
                    item_list_name: datalayerListDetailsName,
                },
            };

            this.$store.dispatch('datalayer/listItemClick', payload);
        },
        sendItemsView({
            eventName,
            items,
            listName,
            listType = null,
        }) {
            const payload = {
                event: eventName,
                listName,
                ...(listType && { listType }),
                data: items,
            };

            this.$store.dispatch('datalayer/listItemView', payload);
        },
        getListKey(ref) {
            const { _uid: uid } = ref;
            return `${ref.$vnode.data.ref}-${uid}`;
        },
    },
};
