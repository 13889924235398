import { storageHandler } from '../../utils';
import {
    fetchUserService, modifyUserService, changePasswordService, setAllPreferencesService,
    changeSubscriptionOptionsService, newsletterSubscribeService,
    saveUserPreferencesService, fetchSessionService, notifyUserAboutProductService,
    fetchUserTokenService, registerService, generateAutoLoginEmailService,
    deleteUserAccountService, fetchDeleteUserAccountStatusService,
} from '../../services';

const expireMinutes = 5 * 60 * 1000;

export default {
    namespaced: true,

    state: () => ({}),

    getters: {
        isLoggedin: (state, _, rootState) => (state.uid && rootState.storage.api_v2_token && true)
            || false,
    },

    mutations: {
        SET_USER(state, payload) {
            Object.assign(payload, {
                timestamp: new Date().getTime(),
            });
            Object.keys(payload).forEach((key) => {
                this.$set(state, key, payload[key]);
            });
        },
        MODIFY_USER(state, payload) {
            Object.keys(payload).forEach((key) => {
                this.$set(state, key, payload[key]);
            });
        },
        REMOVE_USER(state) {
            Object.keys(state).forEach((key) => {
                this.$delete(state, key);
            });
        },
        UPDATE_CART_COUNT: (state, payload) => {
            Object.assign(state, {
                user: {
                    cartCount: payload,
                },
            });
        },
        SET_ALL_PREFERENCES(state, payload) {
            this.$set(state, 'allPreferences', payload);
        },
        SET_SESSION(state, payload) {
            this.$set(state, payload.key, payload.value);
        },
        UPDATE_WISHLIST_NUMBER: (state, payload) => {
            Object.assign(state, {
                wishlistNumber: payload.wishlistNumber,
            });
        },
    },

    actions: {
        fetchUser({
            state, dispatch, commit, rootState,
        }, {
            token = rootState.storage.api_v2_token || false,
            forceFetch = false,
        } = {}) {
            const timestampNow = new Date().getTime();
            const cacheExpired = (state.timestamp)
                ? (state.timestamp + expireMinutes) < timestampNow
                : true;
            const keepCached = !forceFetch && !cacheExpired;

            if (!token || keepCached) return false;

            return fetchUserService({
                $http: this.$http,
                params: {
                    source: rootState.params.source,
                    lang: rootState.params.country.lang,
                    accessToken: token,
                },
            }).then(async (response) => {
                const {
                    uiCookies: responseCookies,
                    ...responseUser
                } = response;

                commit('SET_USER', responseUser);

                // TODO try to reset gdpr state in a better place!
                dispatch('storage/delete', 'cookies_consent', { root: true });
                dispatch('ui/resetGdpr', { }, { root: true });

                if (responseCookies.length > 0) {
                    dispatch('ui/setGdpr', responseCookies, { root: true });
                    dispatch('ui/saveGdprPreferencesCookies', responseCookies, { root: true });
                    dispatch('ui/saveCookieConsent', responseCookies, { root: true });
                }

                if (typeof window !== 'undefined' && rootState.header.cart) {
                    await dispatch('header/cart/mergeData', {}, { root: true });
                    await dispatch('header/cart/fetchData', { forceFetch: true }, { root: true });
                }
            }).catch(() => {
                if (typeof window === 'undefined') {
                    commit('storage/DELETE_ITEMS', 'api_v2_token', { root: true });
                }
            });
        },
        modifyUser({ commit, rootState }, { data }) {
            return modifyUserService({
                $http: this.$http,
                params: {
                    source: rootState.params.source,
                    lang: rootState.params.country.lang,
                    accessToken: rootState.storage.api_v2_token,
                },
                data,
            }).then((response) => {
                commit('MODIFY_USER', response);
                return response;
            }).catch((error) => {
                console.error('modifyUser: ', error);
                return error;
            });
        },
        updateUser: ({ commit }, payload) => {
            commit('SET_USER', payload);
        },
        changePassword({ rootState }, { data }) {
            return changePasswordService({
                $http: this.$http,
                params: {
                    source: rootState.params.source,
                    lang: rootState.params.country.lang,
                    accessToken: rootState.storage.api_v2_token,
                },
                data,
            }).then((response) => response).catch((error) => {
                console.error('changePassword: ', error);
                return error;
            });
        },
        setAllPreferences({ commit, rootState }) {
            return setAllPreferencesService({
                $http: this.$http,
                params: {
                    source: rootState.params.source,
                    lang: rootState.params.country.lang,
                },
            }).then((response) => {
                commit('SET_ALL_PREFERENCES', response);
            });
        },
        changePreferences({ commit, rootState }, payload) {
            return saveUserPreferencesService({
                $http: this.$http,
                params: {
                    source: rootState.params.source,
                    lang: rootState.params.country.lang,
                    accessToken: rootState.storage.api_v2_token,
                },
                data: payload,
            }).then((response) => {
                commit('MODIFY_USER', { datalayer: response.datalayer });
                return response;
            }).catch((error) => {
                console.error('changePreferences: ', error);
                return error;
            });
        },
        changeSubscriptionOptions({ rootState }, { data }) {
            return changeSubscriptionOptionsService({
                $http: this.$http,
                params: {
                    source: rootState.params.source,
                    lang: rootState.params.country.lang,
                    accessToken: rootState.storage.api_v2_token,
                },
                data,
            }).then((response) => response).catch((error) => {
                console.error('changeSubscriptionOptions: ', error);
                return error;
            });
        },
        fetchSession({
            state, commit, dispatch, rootState,
        }) {
            // TODO write cookies only on server when ssr on
            // when csr on, write cookies only on client
            // avoid double mutations, ssr and csr
            const stateSession = state.session;
            const stateGlobalSession = state.globalSession;
            const storageSession = rootState.storage.device_id;
            const storageGlobalSession = rootState.storage.globalSession;

            if (stateGlobalSession) {
                dispatch('saveGlobalSessionStorage', stateGlobalSession);
            }

            if (storageSession) {
                if (!stateSession) commit('SET_SESSION', { key: 'session', value: storageSession });
                return dispatch('saveSessionStorage', storageSession);
            }

            if (stateSession) {
                return dispatch('saveSessionStorage', stateSession);
            }

            const payload = {
                $http: this.$http,
                params: {
                    source: rootState.params.source,
                    lang: rootState.params.country.lang,
                    withGlobalSession: storageGlobalSession ? 0 : 1,
                },
            };

            return fetchSessionService(payload).then((session) => {
                let globalSession = storageGlobalSession;
                if (!globalSession) {
                    globalSession = session.global_session.id;
                }

                const cookiesConsent = rootState.storage.cookies_consent;
                if (!cookiesConsent || !cookiesConsent.includes('~5')) {
                    commit('SET_SESSION', { key: 'globalSession', value: globalSession });
                    dispatch('saveGlobalSessionStorage', globalSession);
                }

                commit('SET_SESSION', { key: 'session', value: session.session.id });

                return dispatch('saveSessionStorage', session.session.id);
            }).catch((error) => {
                console.error('fetchSession: ', error);
            });
        },
        saveSessionStorage: ({ dispatch }, session) => {
            // save session to PHPSESSID=device_id=session to communicate with V2
            // TODO do not trigger this action/mutation on both ssr & csr
            dispatch('storage/add', { key: 'PHPSESSID', value: session }, { root: true });
            dispatch('storage/add', { key: 'device_id', value: session }, { root: true });
        },
        saveGlobalSessionStorage: ({ dispatch }, globalSession) => {
            dispatch('storage/add', {
                key: 'globalSession',
                value: globalSession,
                expires: 60 * 60 * 24 * 365 * 2,
            }, { root: true });
        },
        register({ rootState }, currentPayload) {
            const payload = {
                $http: this.$http,
                params: {
                    source: rootState.params.source,
                    lang: rootState.params.country.lang,
                },
                formData: {
                    ...currentPayload,
                },
            };

            return registerService(payload)
                .then((response) => response)
                .catch((error) => {
                    throw error;
                });
        },
        fetchUserToken({ rootState }, currentPayload) {
            const payload = {
                $http: this.$http,
                params: {
                    source: rootState.params.source,
                    lang: rootState.params.country.lang,
                },
                formData: {
                    ...currentPayload,
                },
            };

            return fetchUserTokenService(payload)
                .then((response) => response)
                .catch((error) => {
                    throw error;
                });
        },
        logout: ({ commit, dispatch, rootState }) => {
            commit('REMOVE_USER');
            return Promise.all([
                dispatch('storage/delete', 'api_v2_token', { root: true }),
                dispatch('storage/delete', 'device_id', { root: true }),
                dispatch('storage/delete', 'PHPSESSID', { root: true }),
                dispatch('storage/delete', 'cookies_consent', { root: true }),
                dispatch('storage/delete', '8ff76bbe80eec134efe90f8e4fd12492', { root: true }), // V1 legacy cookie
                dispatch('header/cart/reset', null, { root: true }),
                dispatch('shared/resetFavoriteProducts', {}, { root: true }),
                rootState.account && rootState.account.addresses && dispatch('account/addresses/resetAddresses', null, { root: true }),
            ]).then(() => {
                dispatch('fetchSession');
            });
        },
        generateAutoLoginEmail({ rootState }, {
            email, nextUrl,
        }) {
            return generateAutoLoginEmailService({
                $http: this.$http,
                params: {
                    source: rootState.params.source,
                    lang: rootState.params.country.lang,
                },
                data: {
                    email,
                    ...(nextUrl && { nextUrl }),
                },
            })
                .then((response) => response)
                .catch((error) => {
                    throw error;
                });
        },
        expireCart: ({ commit }) => commit('UPDATE_CART_COUNT', null),
        updateWishlistNumber: ({ commit }, payload) => {
            commit('UPDATE_WISHLIST_NUMBER', payload);
        },
        notifyUserAboutProduct({ rootState }, payload) {
            return notifyUserAboutProductService({
                $http: this.$http,
                params: {
                    source: rootState.params.source,
                    lang: rootState.params.country.lang,
                    accessToken: rootState.storage.api_v2_token,
                },
                data: payload.data,
            });
        },
        newsletterSubscribe({ rootState }, { data, params }) {
            const accessToken = rootState.storage.api_v2_token;
            const cookieMkt = storageHandler.read('v_mkt');

            return newsletterSubscribeService({
                $http: this.$http,
                params: {
                    ...(accessToken && { accessToken }),
                    ...(cookieMkt && { v_mkt: cookieMkt }),
                    source: rootState.params.source,
                    lang: rootState.params.country.lang,
                    ...params,
                },
                data,
            })
                .then((response) => response)
                .catch((error) => {
                    console.error('newsletterSubscribeService: ', error);
                    throw error;
                });
        },
        deleteUserAccount({ rootState }) {
            return deleteUserAccountService({
                $http: this.$http,
                params: {
                    source: rootState.params.source,
                    lang: rootState.params.country.lang,
                    accessToken: rootState.storage.api_v2_token,
                },
            })
                .then((response) => response)
                .catch((error) => {
                    console.error('deleteUserAccountService: ', error);
                    throw error;
                });
        },
        fetchDeleteUserAccountStatus({ rootState }) {
            return fetchDeleteUserAccountStatusService({
                $http: this.$http,
                params: {
                    source: rootState.params.source,
                    lang: rootState.params.country.lang,
                    accessToken: rootState.storage.api_v2_token,
                },
            })
                .then((response) => response)
                .catch((error) => {
                    console.error('deleteUserAccountService: ', error);
                    throw error;
                });
        },
    },
};
