import createApp from './app';
import { getConfig } from './configs';
import cookies from './utils/cookies';
import { loadLanguageAsync } from './utils/plugins/i18n';
import getHomeRouteLink from './utils/plugins/getHomeRouteLink';
import getAssetsUrlPrototype from './utils/plugins/getAssetsUrlVuePrototype';
import isCountry from './utils/plugins/isCountry';

const STATE = window.__INITIAL_STATE__; // eslint-disable-line no-underscore-dangle
const {
    source,
    country: { platform, lang, domain },
    env,
} = STATE.params;

const config = getConfig(env, domain);

const token = () => cookies.read('api_v2_token');

createApp({
    config,
    authorizationBearer: token(),
    source,
    platform,
    lang,
}).then(async (response) => {
    const {
        Vue, app, store,
        router, i18n, params,
    } = response;

    store.replaceState(STATE);

    // fetch & set needed data
    store.dispatch('params/setParams', params);

    await Promise.allSettled([
        store.dispatch('ui/fetchSwitches'),
        store.dispatch('user/fetchSession'),
        store.dispatch('params/fetchParamsApi'),
        loadLanguageAsync({
            i18nInstance: i18n,
            $http: app.$http,
        }),
    ]);

    loadLanguageAsync({
        i18nInstance: i18n,
        versionWanted: store.state.params.api.translateVersion,
        $http: app.$http,
    });

    router.onReady(() => {
        // plugins that need data
        Vue.use(getHomeRouteLink);
        Vue.use(getAssetsUrlPrototype);
        Vue.use(isCountry);

        app.$mount('#app');
    });
});
