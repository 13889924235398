var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.notifications.length
    ? _c(
        "div",
        { staticClass: "the-notifications" },
        [
          _vm._l(_vm.notifications, function (notification, index) {
            return [
              notification.type === "modal-alert"
                ? _c(
                    "base-modal",
                    {
                      key: `notification-${index}`,
                      attrs: {
                        visible: notification.visible,
                        title: notification.content.title,
                        variant: notification.variant,
                        "hide-backdrop": true,
                        "modal-class": "modal-alert",
                      },
                      on: {
                        hidden: function ($event) {
                          return _vm.close({ index })
                        },
                        ok: function ($event) {
                          return notification.callback.ok()
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(notification.content.body) +
                          "\n        "
                      ),
                    ]
                  )
                : notification.type === "toast"
                ? _c(
                    "base-toast",
                    {
                      key: `notification-${index}`,
                      attrs: {
                        visible: notification.visible,
                        title: notification.content.title,
                        variant: notification.variant,
                        "auto-hide": notification.autoHide,
                        "position-y": notification.positionY,
                      },
                      on: {
                        hidden: function ($event) {
                          return _vm.close({ index })
                        },
                      },
                    },
                    [
                      _c("span", {
                        domProps: {
                          innerHTML: _vm._s(notification.content.body),
                        },
                      }),
                    ]
                  )
                : notification.type === "toast-newsletter"
                ? _c("toast-newsletter", {
                    key: `notification-${index}`,
                    attrs: {
                      visible: notification.visible,
                      "tracking-params": notification.trackingParams,
                    },
                    on: {
                      hidden: function ($event) {
                        return _vm.close({ index })
                      },
                    },
                  })
                : notification.type === "modal-newsletter"
                ? _c("modal-newsletter", {
                    key: `notification-${index}`,
                    attrs: {
                      visible: notification.visible,
                      "tracking-params": notification.trackingParams,
                    },
                    on: {
                      hidden: function ($event) {
                        return _vm.close({ index })
                      },
                    },
                  })
                : _vm._e(),
            ]
          }),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }