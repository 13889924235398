import Cookies from './cookies';
import storage from './local-storage';

// TODO where do we get the condition?
const condition = true;

export default {
    create(name, value, seconds) {
        return condition
            ? Cookies.create(name, value, seconds)
            : storage.create(name, value, seconds);
    },

    read(name) {
        return condition ? Cookies.read(name) : storage.read(name);
    },

    delete(name) {
        return condition ? Cookies.delete(name) : storage.delete(name);
    },

    list() {
        return condition ? Cookies.list() : storage.list();
    },
};
