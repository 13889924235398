const datalayerUser = (payloadDatalayer) => ({
    action: payloadDatalayer.action,
    country: payloadDatalayer.country,
    currency: payloadDatalayer.currency,
    guest: payloadDatalayer.guest,

    user_id: payloadDatalayer.uid,
    user_last_purchase: payloadDatalayer.lastPurchase,
    user_first_purchase: payloadDatalayer.firstPurchase,
    user_total_orders: payloadDatalayer.ordersTotal,
    user_first_login: payloadDatalayer.memberSince,
    user_last_purchase_prod: payloadDatalayer.lastPurchasedProducts,
    user_order_delivered_val: payloadDatalayer.orderValueDelivered,
    user_order_delivered: payloadDatalayer.ordersDelivered,
    user_return_rate: payloadDatalayer.returnRate,
    user_marketing_cookies: payloadDatalayer.cookie_preferences.filter((c) => c.name === 'cookies_advanced')[0].enabled,
    user_total_orders_value: payloadDatalayer.orderValueTotal,
    user_wishlist_products_available: payloadDatalayer.wishlistProductsAvailable,
    user_returned_value: payloadDatalayer.orderValueReturned,
    user_order_returned: payloadDatalayer.orderReturned,
    user_credits: payloadDatalayer.credits,
    user_RFM: payloadDatalayer.rfm,
});

export { datalayerUser as default };
