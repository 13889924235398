import { HTTP_METHOD_TYPE } from '../utils/constants';

export default async function fetchProductStockService({ $http, params }) {
    const response = await $http({
        method: HTTP_METHOD_TYPE.GET,
        url: `${$http.API.BASEURL_PRODUCT_STOCK}`,
        params,
    });

    return {
        status: response.data.status,
        stock: response.data.stock,
    };
}
