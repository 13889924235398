import { Buffer } from 'buffer';

import { HTTP_METHOD_TYPE } from '../utils/constants';
import { pageMeta } from '../utils/adaptors';

const toBase64 = (stringValue) => Buffer.from(stringValue).toString('base64');

export default async function fetchPageMetaService({
    $http,
    params: { payload, ...params },
}) {
    const payloadBase64 = toBase64(JSON.stringify(payload));

    const response = await $http({
        method: HTTP_METHOD_TYPE.GET,
        url: `${$http.API.V2}page-data`,
        params: {
            // types:
            // product (id) | event (id) | category (id)
            // brand (id) | newPromoEvents (id) | regulation (id) | landing (id)
            // search (q)
            // static | about | careers | legal | faq
            // payment | contact | image-search
            payload: payloadBase64,
            ...params,
        },
    });

    return pageMeta(response.data.items);
}
