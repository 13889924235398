import { storageHandler } from '../../utils';

export default {
    namespaced: true,

    state: () => ({}),

    getters: {
        parsedKeyValue: (state) => (key) => {
            const keyValue = state[key];
            return keyValue && JSON.parse(keyValue);
        },
    },

    mutations: {
        SET_ITEMS(state, payload) {
            Object.keys(payload).forEach((key) => {
                this.$set(state, key, payload[key]);
            });
        },
        DELETE_ITEMS(state, key) {
            this.$delete(state, key);
        },
    },

    actions: {
        addToStore: ({ state, commit }, payload) => {
            if (Object.keys(state).length) return;

            commit('SET_ITEMS', payload);
        },
        add: ({ commit }, payload) => {
            storageHandler.create(payload.key, payload.value, payload.expires);
            commit('SET_ITEMS', { [payload.key]: payload.value });
        },
        delete: ({ commit }, key) => {
            storageHandler.delete(key);
            commit('DELETE_ITEMS', key);
        },
    },
};
