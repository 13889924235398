import datalayerCategories from './datalayer-categories-firebase';
import datalayerStockDistribution from './datalayer-stock-distribution';
import datalayerProductListIdTypeOld from './datalayer-product-list-id-type-old';
import datalayerProductListNameOld from './datalayer-product-list-name-old';

function getFirstDefinedValue(...args) {
    let result = args[0];
    for (let i = 1; i < args.length; i += 1) {
        result = result || args[i];
    }
    return result;
}

export default (payload, payloadToMerge) => {
    let item = {
        ...datalayerCategories(
            (payload.datalayer && payload.datalayer.categoryTreeEn) || payload.category_tree_en,
        ),
        ...{
            item_id: String(getFirstDefinedValue(payload.id, payload.product_id)),
            item_name: (getFirstDefinedValue(payload.nameEn, payload.name_en, payload.name, '')).trim(),
            affiliation: payload.marketplace ? 'Marketplace' : 'Store',
            item_brand: getFirstDefinedValue(
                (payload.datalayer && payload.datalayer.brand && payload.datalayer.brand.name),
                (payload.datalayer && payload.datalayer.brand),
                (payload.brand && payload.brand.name),
                '',
            ),
            item_variant: getFirstDefinedValue(
                (payload.datalayer && payload.datalayer.flags
                    && Array.isArray(payload.datalayer.flags.stock)
                    && payload.datalayer.flags.stock.join(',')),
                (payload.datalayer && payload.datalayer.stockInfo
                    && Array.isArray(payload.datalayer.stockInfo)
                    && payload.datalayer.stockInfo.join(',')),
                'No label',
            ),
            price: getFirstDefinedValue(
                (payload.datalayer && payload.datalayer.priceNewEur),
                payload.euro_selling_price,
                payload.price_noVat_eur,
            ),
            discount: getFirstDefinedValue(
                (payload.datalayer && payload.datalayer.discountValue),
                payload.discountValue,
                0,
            ),
            item_stock_fast: datalayerStockDistribution(
                getFirstDefinedValue(
                    (payload.datalayer && payload.datalayer.stockFast),
                    (payload.stock && payload.stock.fast_available),
                ),
            ),
            item_stock_supplier: datalayerStockDistribution(
                getFirstDefinedValue(
                    (payload.datalayer && payload.datalayer.stockSupplier),
                    (payload.stock && payload.stock.supplier_available),
                ),
            ),
            item_discount_range: getFirstDefinedValue(
                (payload.datalayer && payload.datalayer.discountDistribution),
                payload.discountDistribution,
            ),
            item_list_name: '',
            // Sets extra parameters into GA4 item predefined dimension (creative_slot)
            // Parameters are used and parsed only in big query
            // Example with empty vars: ` | var1 | a var |  | another var`
            // Our case: `daysToDelivery | stockAvailable`
            ...(payload?.datalayer?.stockAvailable >= 0) && {
                creative_slot: `${
                    getFirstDefinedValue(
                        (payload.datalayer.daysToDelivery), payload.daysToDelivery, '',
                    )
                } | ${
                    getFirstDefinedValue(payload.stockAvailable)
                }`,
            },

        },
        ...datalayerProductListIdTypeOld(payload),
        ...payloadToMerge,
    };
    item = datalayerProductListNameOld(item);
    return item;
};
