import formatLabels from './format-labels';
import badgesStyle from './badges-style';

const pageProduct = (product, dataLayer) => ({
    components: {
        ...product.components,
        ...(product.components.labels && {
            labels: {
                type: product.components.labels.type,
                data: formatLabels(product.components.labels.data),
            },
        }),
        ...(product.components.badges) && {
            badges: {
                type: product.components.badges.type,
                data: badgesStyle(product.components.badges.data),
            },
        },
        ...((product.components.vouchers || product.components.promoMessages) && {
            promoMessages: [
                ...(product.components.vouchers ? product.components.vouchers.data : []),
                ...(product.components.promoMessages ? product.components.promoMessages.data : []),
            ],
        }),
    },
    id: product.sku,
    ...(product.categoryId && { categoryId: product.categoryId }),
    stock: product.stock,
    ...(product.saleEventId && { saleEventId: product.saleEventId }),
    datalayer: dataLayer,
});

export default pageProduct;
