const review = ({
    comment, date, score, alias, media,
}) => ({
    comment,
    date,
    score,
    author: alias,
    alias,
    media,
});

const productReviewsAdaptor = (payload) => ({
    ...((payload.rating || payload.showButton) && {
        summary: {
            ...(payload.rating && {
                count: payload.rating.count,
                score: payload.rating.score,
                distribution: payload.rating.distribution,
            }),
            ...(payload.showButton && { showButton: payload.showButton }),
        },
    }),
    ...(payload.items.length && {
        [`page-${payload.pagination.currentPage}`]: {
            items: payload.items.map((item) => review(item)),
        },
        pagination: payload.pagination,
    }),
});

export default productReviewsAdaptor;
