export default (userId) => {
    // TODO: Remove after new set of SVGs is ready
    const excludedNumbers = new Set(
        [4, 5, 12, 17, 18, 25, 29, 30, 32, 39, 40, 42, 46],
    );
    const numbers = Array.from(
        { length: 50 },
        (_, i) => i + 1,
    ).filter((num) => !excludedNumbers.has(num));
    const index = userId % numbers.length;

    return numbers[index];
};
