const getAddressesOfTypeZero = (addresses) => addresses?.filter((a) => a.address_type === 0) ?? [];

const getPrimaryAddress = (addresses) => (addresses ? addresses.filter((a) => a.primary)[0] : []);

const getFormConfigData = (payload) => {
    const formFields = {};

    Object.keys(payload).forEach((key) => {
        formFields[key] = {
            ...payload[key],
        };
    });

    return formFields;
};

export default (payload) => ({
    addresses: getAddressesOfTypeZero(payload.id.options),
    primaryAddress: getPrimaryAddress(payload.id.options),
    states: payload.state_id.options,
    config: getFormConfigData(payload),
});
