export default (payload) => ({
    id: payload.id,
    title: payload.title,
    title_en: payload.title_en,
    slug: payload.slug,
    href: payload.href,
    imageSrc: payload.large_image.src,
    description: payload.short_description,
    countdown: payload.has_countdown && payload.end_date,
    datalayer: {
        id: payload.website_url,
    },
});
