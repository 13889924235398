import { capitalize } from '../filters';

export default {
    methods: {
        datalayerListDetails(list) {
            if (typeof list.ref === 'undefined') return;

            const dataItems = list.data;
            const listName = capitalize((list.listName || list.ref.$vnode.data.ref).toLowerCase());

            this.$store.dispatch('datalayer/listDetails', {
                event: list.eventName,
                data: {
                    name: `${capitalize(this.$route.name.toLowerCase())} - ${listName}`,
                    ...(list.datalayerListDetailsName && {
                        name_en: list.datalayerListDetailsName,
                    }),
                    products: dataItems.length,
                    productsInStock: this.$_countItemsInStock(dataItems),
                    productsOutOfStock: this.$_countItemsOutStock(dataItems),
                },
            });
        },
        $_countItemsInStock(items) {
            return items.length - this.$_countItemsOutStock(items);
        },
        $_countItemsOutStock(items) {
            return items.filter((item) => item.flags && item.flags.stock.includes('SOLD_OUT')).length;
        },
    },
};
