import { version, getCurrentInstance } from 'vue';

export const useCurrentInstance = () => {
    const output = {
        globalProperties: null,
        $store: null,
        $t: null,
        uid: null,
    };

    // TODO remove this when vue 2 is no longer supported
    if (version.startsWith('2')) {
        const {
            $root: globalProperties,
            $store,
            $i18n,
            _uid: uid,
        } = getCurrentInstance().proxy;
        output.globalProperties = globalProperties;
        output.$store = $store;
        output.$t = $i18n.t.bind($i18n);
        output.uid = uid;
    } else {
        const {
            appContext: {
                config: { globalProperties },
                provides: { store },
            },
            uid,
        } = getCurrentInstance();
        output.globalProperties = globalProperties;
        output.$store = store;
        output.uid = uid;
    }

    return {
        ...output,
    };
};
