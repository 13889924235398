import { NOTIFICATION_TYPE, SOURCE } from '../../utils/constants';
import { pushNotification } from '../../utils';
import { set } from '../../utils/store';
import { fetchConfigService, createEventSourceInstance } from '../../services';
import cnsPaymentUpdate from './cns-payment-update';

export default {
    namespaced: true,

    modules: {
        messages: {
            modules: {
                orderPaymentUpdate: cnsPaymentUpdate,
            },
        },
    },

    state: () => ({}),

    mutations: {
        SET_DATA(state, payload) {
            Object.keys(payload).forEach((key) => {
                set(state, [key], payload[key], this.$set);
            });
        },
        SET_MESSAGES(state, { key, value }) {
            set(state.messages, [key], value, this.$set);
        },
    },

    actions: {
        async openCnsConnection({
            state, dispatch, commit, rootState,
        }) {
            const disableForApp = rootState.params.source === SOURCE.APP
                && !rootState.ui.switch.app.global.cns;
            const disableForWeb = [SOURCE.MOBILE, SOURCE.SITE].includes(rootState.params.source)
                && !rootState.ui.switch.web.global.cns;

            if (disableForApp || disableForWeb) return;

            await dispatch('fetchConfig');

            const { hub, topics, token } = state.config;

            if (!hub || !topics || !token) return;

            const evtNames = ['announcement', 'orderPaymentUpdate', 'cartExpireSoon', 'error'];

            const evtSource = await createEventSourceInstance({
                hub,
                topics,
                token,
                events: {
                    names: evtNames,
                    callback: (payload) => dispatch('handleCnsResponse', payload),
                },
            });

            commit('SET_DATA', { config: { ...state.config, evtSource } });
        },
        handleCnsResponse: ({ dispatch }, payload) => {
            switch (payload.type) {
            case 'orderPaymentUpdate':
                dispatch('cns/saveOrderPaymentStatus', payload, { root: true });
                try {
                    const payloadMessage = JSON.parse(payload.data);
                    if (!payloadMessage.notification) {
                        break;
                    }
                    pushNotification(
                        dispatch,
                        NOTIFICATION_TYPE.DANGER,
                        payloadMessage.notification,
                        20000,
                    );
                } catch (err) { console.error(err); }
                break;
            case 'cartExpireSoon':
                try {
                    const payloadMessage = JSON.parse(payload.data);
                    pushNotification(
                        dispatch,
                        NOTIFICATION_TYPE.WARNING,
                        payloadMessage.message,
                        15000,
                    );
                } catch (err) { console.error(err); }
                break;
            case 'announcement':
                try {
                    const payloadMessage = JSON.parse(payload.data);
                    pushNotification(
                        dispatch,
                        NOTIFICATION_TYPE.INFO,
                        payloadMessage.message,
                        15000,
                    );
                } catch (err) { console.error(err); }
                break;
            default:
                break;
            }
        },
        fetchConfig({ commit, rootState }) {
            const { api_v2_token: accessToken, device_id: deviceId } = rootState.storage;
            return fetchConfigService({
                $http: this.$http,
                params: {
                    source: rootState.params.source,
                    lang: rootState.params.country.lang,
                    ...(accessToken && { accessToken }),
                    ...(deviceId && { device_id: deviceId }),
                },
            }).then((response) => {
                commit('SET_DATA', { config: response.data });
            }).catch((error) => {
                console.error('fetchConfigService: ', error);
            });
        },
        closeCnsConnection: ({ state }) => {
            if (!state.config) return;

            state.config.evtSource.close();
            delete state.config;
        },
        // TODO moves to cns-payment-update.js to isolate code
        cleanOrderPaymentStatus: ({ state }) => {
            if (!state.messages) return;

            delete state.messages.orderPaymentUpdate;
        },
        refreshCnsConnection: ({ state, dispatch }) => {
            if (!state.config) return;

            dispatch('closeCnsConnection');
            dispatch('openCnsConnection');
        },
        openCnsConnectionIfNotActive: ({ state, dispatch }) => {
            if (state.config) return;

            dispatch('openCnsConnection');
        },
    },
};
