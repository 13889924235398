export default (dispatch, notificationType, body, autoHide = 10000, title) => {
    dispatch('ui/notifications/push', {
        type: 'toast',
        variant: notificationType,
        id: `notification-${new Date().getTime()}`,
        autoHide,
        content: {
            body,
            ...(title && { title }),
        },
    }, { root: true });
};
