export default ({
    FILTER_TYPE_ATTRIBUTE: 'Attribute',
    FILTER_TYPE_ATTRIBUTE_GROUP: 'AttributeGroup',

    VIEW_TYPE_RANGE: 'Range',
    VIEW_TYPE_CHECKBOX: 'Checkbox',

    API_ALLOWED_FILTER_KEY_PREFIX: 'attr_',
    API_ALLOWED_FILTER_KEYS: [
        'delivery', 'availability', 'category', 'price', 'discount',
        'style', 'brand', 'color',
        'vendor', 'width', 'height', 'length', 'is_new', 'in_stock', 'limited_stock',
        'best_seller', 'best_rating',
    ],
});
