import badgeStatusStyle from './badge-status-style';

export default (payload) => ({
    id: payload.id,
    date: payload.date,
    total: payload.total_order,
    currency: payload.currency,
    paymentLabel: payload.payment.label,
    paymentStatusLabel: payload.payment_status.label,
    status: badgeStatusStyle(payload.status),
    sellers: payload.sellers.map((seller) => ({
        label: seller.label,
        deliveries: seller.deliveries.map((item) => ({
            id: item.id,
            deliveryDates: {
                before: item.delivery_dates.before,
                after: item.delivery_dates.after,
            },
            products: item.products.map((product) => ({
                id: product.id,
                slug: product.slug,
                imageSrc: product.image.src,
            })),
        })),
    })),
});
