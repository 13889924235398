import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { fetchTranslateService } from '../../services';
import { storage } from '..';

Vue.use(VueI18n);

const i18nDataKey = 'i18n';
let i18nDataCache = {};

if (typeof window !== 'undefined') {
    const i18nData = storage.read(i18nDataKey);

    if (i18nData) {
        i18nDataCache = JSON.parse(i18nData);
    }
}

export function createI18n(locale) {
    return new VueI18n({
        locale,
        fallbackLocale: 'en',
        messages: i18nDataCache,
        pluralizationRules: {
            cz(choice) {
                if (choice === 1) return 0;
                if (choice > 1 && choice < 5) return 1;

                return 2;
            },
        },
    });
}

export async function loadLanguageAsync({ i18nInstance, versionWanted, $http }) {
    try {
        const lang = i18nInstance.locale;

        const isVersionWantedNew = (() => {
            if (!versionWanted) return null;

            const isVersionDifferent = i18nDataCache.version !== versionWanted;
            const isVersionValid = () => Number(versionWanted) > Number(i18nDataCache.version);

            return isVersionDifferent && isVersionValid() && versionWanted;
        })();

        if (isVersionWantedNew) {
            i18nDataCache = {};
            i18nDataCache.version = isVersionWantedNew;
        }

        if (!i18nDataCache[lang]) {
            const paramCacheForce = isVersionWantedNew || false;
            const labels = await fetchTranslateService({
                lang,
                cacheForce: paramCacheForce,
                $http,
            });

            i18nDataCache.version = isVersionWantedNew || labels.translateVersion;
            delete labels.translateVersion;
            i18nDataCache[lang] = labels;

            if (typeof window !== 'undefined') {
                const serializedMessages = JSON.stringify(i18nDataCache);
                const monthInSeconds = 1000 * 60 * 60 * 24 * 30; // TODO check if this is used

                storage.create(i18nDataKey, serializedMessages, monthInSeconds);
            }
        }

        const isLocaleMessageSet = Object.keys(i18nInstance.getLocaleMessage(lang)).length;

        if (!isLocaleMessageSet) {
            i18nInstance.setLocaleMessage(lang, i18nDataCache[lang]);
        }
    } catch (error) {
        console.error('plugin loadLanguageAsync: ', error);
    }
}
