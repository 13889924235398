import { getLastChars } from '../filters';

const formatItems = (paymentMethods) => {
    if (paymentMethods.storedPaymentMethods !== undefined) {
        return {
            ...paymentMethods,
            storedPaymentMethods:
                paymentMethods.storedPaymentMethods.map((item) => ({
                    ...item,
                    expiryYear: getLastChars(item.expiryYear, 2),
                })),
        };
    }
    return paymentMethods;
};

export const adyenSummary = (payloadSummary, payloadExtra) => ({
    title: 'controllers_api_translation_cart_0003',
    total: payloadSummary.total_order,
    totalBaseUnits: payloadSummary.total_order_base_unit,
    currencyCode: payloadSummary.currency,
    countryCode: payloadExtra.countryCode,
});

export const adyenSession = (payload) => ({
    ...(payload.summary && { adyenSummary: adyenSummary(payload.summary, payload.extra) }),
    summary: payload.summary,
    paymentMethods: formatItems(payload.paymentMethods),
    extra: payload.extra,
    isExpired: payload.isExpired,
    isPaid: payload.isPaid,
    dataLayer: payload.dataLayer,
});
