import { HTTP_METHOD_TYPE } from '../utils/constants';
import { itemProductReview } from '../utils/adaptors';

let perPage = 10;
let page = 1;

export async function fetchUserProductsReviewService({ $http, params }) {
    const response = await $http({
        method: HTTP_METHOD_TYPE.GET,
        url: `${$http.API.V3}user/reviews/available-products`,
        params,
    });

    return {
        items: response.data.data.items
            .map((item) => itemProductReview(item)),
    };
}

export async function fetchReviewsService({ $http, parameters }) {
    perPage = parameters.perPage || perPage;
    page = parameters.page || page;

    const params = {
        'per-page': perPage,
        page,
        ...parameters,
    };

    const response = await $http({
        method: HTTP_METHOD_TYPE.GET,
        url: `${$http.API.V3}user/reviews`,
        params,
    });

    const { items, pagination } = response.data.data;

    return {
        items,
        pagination,
    };
}
