import { HTTP_METHOD_TYPE } from '../utils/constants';
import itemProduct from '../utils/adaptors/item-product';

export default async function fetchPageVariantsService({ $http, params, queryIds }) {
    try {
        const response = await $http({
            method: HTTP_METHOD_TYPE.GET,
            url: `${$http.API.V3}products/search-by-ids/${queryIds}`,
            params,
        });

        const items = response.data.data.items.map((item) => {
            const args = [item, params.queryParams, response.data.data.badges];

            return itemProduct(...args);
        });

        return items;
    } catch (error) {
        console.error('fetchPageVariantsService', error);

        return [];
    }
}
