<template>
    <div
        v-show="show"
        class="loader-container"
        :class="{ 'loader-container-full-page': fullPage }"
    >
        <div
            role="status"
            class="spinner-border"
        />
    </div>
</template>

<script>
export default {
    name: 'TheLoader',

    props: {
        fullPage: {
            type: Boolean,
            default: false,
        },
    },

    data: () => ({
        show: false,
        timeoutID: null,
    }),

    mounted() {
        this.timeoutID = setTimeout(() => {
            this.show = true;
        }, 250);
    },

    beforeDestroy() {
        clearTimeout(this.timeoutID);
    },
};
</script>

<style lang="scss" scoped>
@import "./../scss/required";

.loader-container {
    z-index: $zindex-modal-backdrop;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba($white, .75);

    &.loader-container-full-page {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
    }
}
</style>
