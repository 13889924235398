import FetchError from './errors/FetchError';

const editUserCollectionService = async ({
    $http,
    id,
    params,
    data,
}) => {
    try {
        const response = await $http({
            method: 'patch',
            url: `${$http.API.V3}user/collections/${id}`,
            params,
            data,
        });

        return response.data;
    } catch (error) {
        throw new FetchError(error.response.data.message);
    }
};

const deleteUserCollectionService = async ({
    $http,
    id,
    params,
}) => {
    try {
        const response = await $http({
            method: 'delete',
            url: `${$http.API.V3}user/collections/${id}`,
            params,
        });

        return response.data;
    } catch (error) {
        throw new FetchError(error.response.data.message);
    }
};

const addProductToUserCollectionService = async ({
    $http,
    params,
    collectionId,
    productId,
}) => {
    try {
        const response = await $http({
            method: 'post',
            url: `${$http.API.V3}user/collections/add-product`,
            params,
            data: {
                collectionId,
                productId,
            },
        });

        return response.data;
    } catch (error) {
        throw new FetchError(error.response.data.message);
    }
};

const removeProductFromUserCollectionService = async ({
    $http,
    params,
    collectionId,
    productId,
}) => {
    try {
        const response = await $http({
            method: 'delete',
            url: `${$http.API.V3}user/collections/${collectionId}/product/${productId}`,
            params,
        });

        return response.data;
    } catch (error) {
        throw new FetchError(error.response.data.message);
    }
};

const getUserCollectionsByProductService = async ({ $http, productId, params }) => {
    const response = await $http({
        method: 'get',
        url: `${$http.API.V3}user/collections/product/${productId}`,
        params,
    });

    return { items: response.data.data.items };
};

const addUserCollectionService = async ({ $http, params, data }) => {
    try {
        return await $http({
            method: 'post',
            url: `${$http.API.V3}user/collections`,
            params,
            data,
        });
    } catch (error) {
        throw new FetchError(error.response.data.message);
    }
};

export {
    editUserCollectionService,
    deleteUserCollectionService,
    addProductToUserCollectionService,
    removeProductFromUserCollectionService,
    getUserCollectionsByProductService,
    addUserCollectionService,
};
