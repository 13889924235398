import { setupParamUser } from '../utils/adaptors';
import { HTTP_METHOD_TYPE } from '../utils/constants';

export default function postSearchDataService({
    $http, user, params, data,
}) {
    try {
        const userParams = setupParamUser(user);

        $http({
            method: HTTP_METHOD_TYPE.POST,
            url: `${$http.API.V2}search/insert-search-log`,
            params: {
                ...userParams,
                ...params,
            },
            data: {
                keyword: data.keyword,
                results_no: data.resultsNo,
            },
        });
    } catch (error) {
        console.error('service postSearchDataService Http: ', error);
    }
}
