import { ADDRESSES } from '../constants';

export default {
    data: () => ({
        loading: false,
        checkout: {
            loading: false,
        },
        voucher: {
            value: '',
            loading: false,
            error: '',
        },
    }),

    computed: {
        cart() {
            return this.$store.state.cart;
        },
        extra() {
            return this.cart.extra || {};
        },
        params() {
            return this.$store.state.params;
        },
        paymentSession() {
            return {
                paymentMethods: this.cart.adyenPaymentMethods,
                adyenSummary: this.cart.adyenSummary,
                locale: this.extra.locale,
                context: this.extra.context,
            };
        },
        isPageMounted() {
            return this.pageMounted;
        },
        showNormalAddressInput() {
            return !this.hasPickupPoints || (this.hasPickupPoints && !this.pickupPointsEnabled);
        },
        showPickupPointsInput() {
            return this.hasPickupPoints && this.pickupPointsEnabled;
        },
        isUserLoggedIn() {
            return this.$store.getters['user/isLoggedin'];
        },
        isCartActive() {
            return !!(this.cart.sections?.length);
        },
        isCompanyBillingAllowed() {
            return !(this.cart && this.cart.b2cOnly);
        },
        isCartEmpty() {
            return this.cart.sections?.length === 0;
        },
        hasPickupPoints() {
            return this.$store.state.ui.switch.web.pageCartCheckout.pickupPoints
                && this.countryID === this.$COUNTRY.BG.ID
                && !this.hasLargeProducts;
        },
        paymentType() {
            return this.cart.paymentType;
        },
        hasLargeProducts() {
            return this.cart && this.cart.hasLargeProducts;
        },
        expiredProducts() {
            return this.cart.expiredProducts;
        },
        expiredProductsInStock() {
            return this.expiredProducts.filter((product) => product.available_stock > 0);
        },
        productsFee() {
            return this.cart.totalProductsFee;
        },
        shippingFee() {
            return this.cart.totalShippingFee;
        },
        handlingFee() {
            return this.cart.handlingFee;
        },
        fullShippingFee() {
            return this.cart.fullShippingFee;
        },
        fullHandlingFee() {
            return this.cart.fullHandlingFee;
        },
        discountShipping() {
            return this.cart.discountShipping;
        },
        podFee() {
            return this.cart.totalPodFee;
        },
        creditAmount() {
            return this.cart.creditAmount;
        },
        userPoints() {
            return this.cart.userPoints;
        },
        voucherObj() {
            return this.cart.vouchers ? this.cart.vouchers[0] : {};
        },
        voucherAmount() {
            return this.cart.voucherAmount;
        },
        totalAmount() {
            return this.cart.totalOrder;
        },
        isCheckoutLoading() {
            return !!this.checkout.loading;
        },
        countryID() {
            return this.$store.state.params.country.id;
        },
        productsRecommended() {
            return this.cart.productsRecommended;
        },
    },

    watch: {
        isCompany(newVal) {
            try {
                if (newVal) {
                    this.fetchBilling();
                } else {
                    this.resetBillingVat();
                }
            } catch (error) {
                console.error('checkout billing error: ', error);
            }
        },
        showPickupPointsInput(newVal) {
            if (newVal) {
                this.$store.dispatch('account/addresses/setSelectedAddress', null);
            }
        },
    },

    methods: {
        updateProductQuantity(productId, quantity) {
            this.updateCart({
                cart_product_id: productId,
                quantity,
            });
        },

        fetchBilling() {
            return this.$store.dispatch('account/addresses/fetchBilling')
                .catch((error) => {
                    if (error.code === ADDRESSES.BILLING_ERRORS.INVOICE_NOT_ALLOWED) {
                        this.$store.dispatch('cart/fetchData');
                    }
                    console.error('fetchBilling: ', error);
                });
        },
        resetBillingVat() {
            return this.$store.dispatch('account/addresses/resetBillingVat');
        },
        removeFromCart(product) {
            this.updateProductQuantity(product.id, 0);
            this.$store.dispatch('datalayer/removeFromCart', {
                product,
            });
        },
        removeUnavailableFromCart(product) {
            this.updateCart({
                cartProductUnavailableId: product.id,
                quantity: 0,
            });
        },
        addRecommendedProduct(product, unavailableProduct, queryParams) {
            this.updateCart({
                replaceProductId: product.id,
                cartProductUnavailableId: unavailableProduct.id,
                ...queryParams,
            });
        },
        togglePickupPoints(state) {
            this.pickupPointsEnabled = state;
        },

        async isCartValid() {
            this.$store.dispatch('ui/setLoading', { showAppLoader: true }).then();
            const response = await this.$store.dispatch('cart/fetchData');
            this.$store.dispatch('ui/setLoading', { showAppLoader: false }).then();
            return !response.warning;
        },

        updateCart(payload) {
            this.$store.dispatch('ui/setLoading', { showAppLoader: true });
            return this.$store.dispatch('cart/updateData', payload)
                .then(() => this.$store.dispatch(
                    'shared/components/fetchInlineControl',
                    { topic: this.$route.name },
                ))
                .then(() => this.refreshMiniCart()
                    .then(() => {
                        this.$store.dispatch('ui/setLoading', { showAppLoader: false });
                    }))
                .catch((e) => {
                    this.$store.dispatch('ui/setLoading', { showAppLoader: false });
                });
        },

        updatePoints(points) {
            this.updateCart({ points_to_use: points });
        },

        refreshMiniCart(forceFetch = true) {
            return this.$store.dispatch('header/cart/fetchData', { forceFetch });
        },
        resetCart() {
            return Promise.all([
                this.$store.dispatch('cart/reset'),
                this.$store.dispatch('header/cart/reset'),
            ]);
        },
        formatCartPayload(payload) {
            const cartPayload = {};

            cartPayload.paymentType = payload.paymentType;

            if (payload.address.id) {
                cartPayload.address = { id: payload.address.id };
            } else if (payload.address) {
                cartPayload.address = payload.address;
            }

            if (cartPayload.address.email) {
                cartPayload.email = cartPayload.address.email;
            }

            cartPayload.isCompany = payload.isCompany;

            if (this.isCompany) {
                cartPayload.billing = {
                    ...payload.billingAddress,
                    ...(payload.vatCheck && payload.vatCheck.vat_status && { vat_payer: 1 }),
                };
            }

            return cartPayload;
        },
        isUserUsingSavedAddresses() {
            return this.addresses.length > 0 && !this.pickupPointsEnabled;
        },
        setAddressesInStateFromFormComponents(payload) {
            if (!payload.address) {
                this.$store.dispatch('account/addresses/setGetAddressFromFormTrigger', true);
            }

            if (payload.isCompany && !payload.billingAddress) {
                this.$store.dispatch('account/addresses/setGetBillingAddressFromFormTrigger', true);
            }
        },
        datalayerTrackOrderSuccess(payload) {
            const payloadToMerge = {
                ...(payload.vatCheck && payload.vatCheck.vat_status && {
                    tax: 0,
                    taxEur: 0,
                }),
            };

            this.datalayerPlaceOrder({
                orderId: payload.orderId,
                event: 'order',
                datalayer: payload.dataLayer,
                products: payload.products,
                payloadToMerge,
            });
        },
        setApiErrorsInAddressState(error) {
            const errorData = error.data && error.data[0];
            const addressType = errorData && errorData.field && errorData.field.indexOf('InvoiceInfo') > -1 ? 'billingAddressError' : 'selectedAddressError';

            this.$store.dispatch('account/addresses/setAddressErrorResponse', {
                addressType,
                data: error,
            });
        },
        showDeliveryErrorsInModal() {
            const isUsingSavedAddresses = this.addresses.length > 0;

            if (isUsingSavedAddresses && this.showNormalAddressInput) {
                // setting selectedAddress to primaryAddress, because at modal close selected selectedAddress gets deleted
                this.$store.dispatch('account/addresses/setSelectedAddress', this.accountAddresses.primaryAddress);
                this.openModal('address');
            }
        },
        resetStateAddresses() {
            this.$store.dispatch('account/addresses/setSelectedAddress', null);
            this.$store.dispatch('account/addresses/setBillingAddress', null);
        },
    },
};
