import { HTTP_METHOD_TYPE } from '../utils/constants';
import {
    itemProduct, itemProductCard,
} from '../utils/adaptors';

export default async function fetchProductHistoryService({
    $http,
    params,
    items,
    isProductPreview,
}) {
    const response = await $http({
        method: HTTP_METHOD_TYPE.GET,
        url: `${$http.API.V2}recently-viewed`,
        params,
    });

    let output = {};

    if (isProductPreview) {
        output = {
            sectionTitleKey: 'views_product_alternatives_recent_view_header',
            sectionPath: '/product-history',
            items: response.data.items.map((item) => itemProductCard({
                ...item,
                queryParams: {
                    // TODO remove the default value of 24 after 3 months
                    ch_type: items[item.id].chType || 24,
                    ch_id: items[item.id].chId,
                },
            })),
        };

        return output;
    }

    output = {
        sectionTitleKey: 'views_product_alternatives_recent_view_header',
        sectionPath: '/product-history',
        items: response.data.items
            .map((item) => {
                // TODO move this `ch_type` and `ch_id` in adaptor
                Object.assign(item, {
                    slug: `${item.slug}?ch_type=${items[item.id].chType}&ch_id=${items[item.id].chId}`,
                });

                return itemProduct(
                    item,
                    {
                        // TODO remove the default value of 24 after 3 months
                        ch_type: items[item.id].chType || 24,
                        ch_id: items[item.id].chId,
                    },
                    response.data.badges,
                );
            }),
    };

    return output;
}
