import {
    addToFavoritesService,
    removeFromFavoritesService,
} from '../../services/add-to-favorites';

export default {
    namespaced: true,

    state: () => ({}),

    mutations: {},

    actions: {
        async addToFavorites({ dispatch, rootState }, { data }) {
            await addToFavoritesService({
                $http: this.$http,
                params: {
                    source: rootState.params.source,
                    lang: rootState.params.country.lang,
                    accessToken: rootState.storage.api_v2_token,
                },
                data,
            }).then((response) => {
                const { wishlistNumber } = rootState.user;

                if (response[0] !== 'duplicate') {
                    dispatch('user/updateWishlistNumber', {
                        wishlistNumber: wishlistNumber + 1,
                    }, { root: true });
                }
                dispatch('shared/toggleFavoriteProduct', data.productId, { root: true });

                return response;
            }).catch((error) => {
                console.error('addToFavoritesService: ', error);
                throw error;
            });
        },
        async removeFromFavorites({ dispatch, rootState }, { data }) {
            await removeFromFavoritesService({
                $http: this.$http,
                params: {
                    source: rootState.params.source,
                    lang: rootState.params.country.lang,
                    accessToken: rootState.storage.api_v2_token,
                },
                data,
            }).then((response) => {
                const { wishlistNumber } = rootState.user;

                if (response[0] === true) {
                    dispatch('user/updateWishlistNumber', {
                        wishlistNumber: wishlistNumber - 1,
                    }, { root: true });
                }
                dispatch('shared/toggleFavoriteProduct', data.productId, { root: true });

                return response;
            }).catch((error) => {
                console.error('removeFromFavoritesService: ', error);
                throw error;
            });
        },
    },
};
