const expired = ({
    timestamp,
    timestampNow = new Date().getTime(),
    offsetMinutes = 0,
}) => {
    if (!timestamp) return null;

    const timestampDifference = timestampNow - timestamp;
    const hasExpired = timestampDifference > offsetMinutes * 60 * 1000;

    if (hasExpired) return true;

    return false;
};

export default { expired };
