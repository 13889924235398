const statusCode = {
    PAID: 'CC_PAID',
    FAILED: 'CC_FAILED',
    PROCESSING: 'CC_PROCESSING',
};

const type = {
    BT: 'bank transfer',
    CC: 'credit card',
    POD: 'payment on delivery',
};

export default ({
    STATUS_CODE: statusCode,
    TYPE: type,
});
