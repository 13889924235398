<template>
    <div class="notification-gdpr">
        <div class="container-fluid">
            <div class="d-flex flex-row align-items-center">
                <span class="me-auto">{{ $t('cookies_notification_message') }}</span>
                <base-btn
                    variant="outline-secondary"
                    size="sm"
                    class="me-2"
                    @click.native="$emitter.emit('modal', { modal: 'gdpr' })"
                >
                    <span v-html="$t('banner_gdpr_edit')" />
                </base-btn>
                <base-btn
                    variant="primary"
                    size="sm"
                    @click.native="handleGdprForm({ prefs: storeGdprIds , closeModal: false})"
                >
                    <span v-html="$t('landing_yes')" />
                </base-btn>
            </div>
        </div>
    </div>
</template>

<script>
import { gdprMixin } from '../utils/mixins';

export default {
    name: 'NotificationGdpr',

    mixins: [
        gdprMixin,
    ],
};
</script>

<style lang="scss" scoped>
@use "sass:map";

@import "./../scss/required";

.notification-gdpr {
    position: sticky;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: $zindex-fixed;
    order: 5;
    padding: $spacer * .75 0;
    background-color: $white;
    border-top: 1px solid $border-color;

    span {
        font-size: $small-font-size;
    }
}
</style>
