import { getLastChars } from '../filters';

const getFormConfigData = (payload) => {
    const formFields = {};

    Object.keys(payload).forEach((key) => {
        formFields[key] = {
            visible: payload[key].visible,
        };
    });

    return formFields;
};

const formatItems = (items) => items.map((item) => ({
    ...item,
    expiryYear: getLastChars(item.expiryYear, 2),
}));

export default (payload) => ({
    items: formatItems(payload.items),
    config: getFormConfigData(payload),
});
