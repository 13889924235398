import badgesStyle from './badges-style';

const itemProductBadges = (product, badges) => {
    if (!product.badges || !badges) {
        return [];
    }

    const productBadges = badges.filter((badge) => product.badges.includes(badge.id));
    const discountBadge = product.badges.find((badge) => badge.type === 'discount');
    if (discountBadge) {
        productBadges.unshift({
            ...discountBadge,
            ...badges.find((badge) => badge.type === 'discount'),
        });
    }

    return badgesStyle(productBadges);
};

export { itemProductBadges as default };
