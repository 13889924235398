const ACCOUNT = [
    { label: 'views_myaccount_menu_0001', to: '/account/orders' },
    { label: 'views_myaccount_menu_0005', to: '/account/addresses' },
    { label: 'views_myaccount_menu_0008', to: '/account/cards' },
    { label: 'views_myaccount_menu_profiles', to: '/account/home-vibes' },
    { label: 'views_myaccount_tabs_0002', to: '/account/me' },
    { label: 'views_myaccount_menu_wallet', to: '/account/wallet' },
    { label: 'views_myaccount_menu_0009', to: '/account/reviews' },
    { label: 'views_myaccount_menu_0011', to: '/account/collections' },
    { label: 'views_myaccount_menu_bookmarks', to: '/account/bookmarks' },
    { label: 'layouts_main_0013', to: '/account/returns/add' },
];

const HEADER = [
    ...ACCOUNT,
    { label: 'layouts_main_0010', to: '/contact' },
    { label: 'views_myaccount_menu_0004', action: 'logout' },
];

export default {
    ACCOUNT,
    HEADER,
};
