import { HTTP_METHOD_TYPE } from '../utils/constants';

export async function validatePhoneNumberService({ $http, params, data }) {
    const response = await $http({
        method: HTTP_METHOD_TYPE.POST,
        url: `${$http.API.V3}user/validation/phone/validate`,
        params,
        data,
    });

    return response.data;
}

export async function confirmCodeService({ $http, params, data }) {
    const response = await $http({
        method: HTTP_METHOD_TYPE.POST,
        url: `${$http.API.V3}user/validation/phone/code/validate`,
        params,
        data,
    });

    return response.data;
}
