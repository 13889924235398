import { Timestamp } from '../utils';
import { HTTP_METHOD_TYPE } from '../utils/constants';

import { params as adaptorParams } from '../utils/adaptors';

const cache = {};
const cacheExpireMinutes = 1;

const http = async ({ $http, source }) => {
    const response = await $http({
        method: HTTP_METHOD_TYPE.GET,
        url: `${$http.API.V2}params`,
    });

    return adaptorParams(response.data, source);
};

export default async function fetchParamsService({ $http }) {
    try {
        const { lang, source } = $http.defaults.params;

        // bypass cache if client-side, this is covered by store
        if (typeof window !== 'undefined') {
            return http({ $http, source });
        }

        const keyContext = `${lang}-${source}`;

        const isCacheExpired = () => Timestamp.expired({
            timestamp: cache[keyContext].timestamp,
            offsetMinutes: cacheExpireMinutes,
        });

        const isCacheInvalid = !cache[keyContext] || isCacheExpired();

        if (isCacheInvalid) {
            const responseData = await http({ $http, source });
            const responseTimestamp = new Date().getTime();

            cache[keyContext] = {
                data: responseData,
                timestamp: responseTimestamp,
            };
        }

        return cache[keyContext].data;
    } catch (error) {
        console.error('fetchParamsService: ', error);

        return false;
    }
}
