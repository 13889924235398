export default {
    create(name, value, seconds) {
        if (typeof window === 'undefined') return;

        const date = new Date();
        let expires = '';
        let storage = window.localStorage;

        if (seconds) {
            const miliseconds = seconds * 1000;
            date.setTime(date.getTime() + miliseconds);
            expires = date.getTime();
            const storageObject = { value, expires };
            storage.setItem(name, JSON.stringify(storageObject));
        } else {
            storage = window.sessionStorage;
            storage.setItem(name, value);
        }
    },

    read(name) {
        if (typeof window === 'undefined') return null;

        const currentTime = Date.now();
        let storage = window.localStorage;
        const storageObject = storage.getItem(name);

        if (storageObject) {
            const parsedObject = JSON.parse(storageObject);

            if (parsedObject.expires < currentTime) {
                this.delete(name);
                return false;
            }

            return parsedObject.value;
        }

        storage = window.sessionStorage;
        return storage.getItem(name);
    },

    delete(name) {
        if (typeof window === 'undefined') return;

        window.localStorage.removeItem(name);
        window.sessionStorage.removeItem(name);
    },

    list() {
        if (typeof window === 'undefined') return null;

        const getStorage = Object.keys({
            ...window.localStorage,
            ...window.sessionStorage,
        }).map((key) => ({
            [key]: this.read(key),
        }));
        const storage = Object.assign({}, ...getStorage);

        return storage;
    },
};
