import datalayerCategories from './datalayer-categories-firebase';
import datalayerStockDistribution from './datalayer-stock-distribution';

export default (payload, payloadToMerge) => ({
    ...datalayerCategories(payload.datalayer.categoryTreeEn),
    ...{
        item_id: String(payload.id),
        item_name: payload.datalayer.nameEn,
        affiliation: payload.marketplace ? 'Marketplace' : 'Store',
        item_brand: (payload.datalayer.brand && payload.datalayer.brand.name) || '',
        item_variant: (payload.datalayer && payload.datalayer.stockInfo && payload.datalayer.stockInfo.join(',')) || 'No label',
        price: payload.datalayer.priceNewEur,
        discount: payload.datalayer.discountValue || 0,
        item_stock_fast: datalayerStockDistribution(payload.datalayer.stockFast),
        item_stock_supplier: datalayerStockDistribution(payload.datalayer.stockSupplier),
        item_discount_range: payload.datalayer.discountDistribution,
        item_list_name: payload.item_list_name,
    },
    item_list_id: payload.queryParams?.ch_id
        || payload.datalayer?.queryParams?.ch_id
        || payload.ch_id
        || 'false',
    ...payloadToMerge,
});
