<template>
    <span
        role="img"
        class="icon"
        :class="[name && 'icon-' + name, classesPrefixed]"
    >
        <slot v-if="!!$slots.default" />
        <svg v-else>
            <use
                :xlink:href="`#${name}--sprite`"
                xmlns:xlink="http://www.w3.org/1999/xlink"
            />
        </svg>
    </span>
</template>

<script>
import '../assets/icons/sprite';

export default {
    name: 'BaseIcon',

    props: {
        name: {
            type: String,
            default: '',
        },
        classes: {
            type: String,
            default: '',
        },
    },
    computed: {
        classesPrefixed() {
            if (!this.classes) return false;

            return this.classes
                .split(' ')
                .map((className) => `icon-${className}`);
        },
    },
};
</script>

<style lang="scss">
@import "./../scss/05-components/base-icon";
</style>
