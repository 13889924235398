import { fetchInlineControl } from '../../services';

export default {
    namespaced: true,

    state: () => ({
        data: {},
    }),

    actions: {
        fetchInlineControl({ commit, rootState, rootGetters }, payload) {
            const isUserLoggedIn = rootGetters['user/isLoggedin'];

            if (!isUserLoggedIn) return false;

            return fetchInlineControl({
                $http: this.$http,
                params: {
                    topic: payload.topic,
                    source: rootState.params.source,
                    lang: rootState.params.country.lang,
                    accessToken: rootState.storage.api_v2_token,
                },
            }).then((response) => {
                const { components } = response;
                commit('shared/SET_COMPONENT_DATA', {
                    components,
                }, { root: true });
            }).catch((error) => {
                console.error('fetchInlineControl: ', error);
            });
        },
    },
};
