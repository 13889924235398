const COOKIE_NAME = 'is_cookie_verified';
const AUTH_COOKIE_NAME = 'api_v2_token';
const UUID_NAME = 'ucm-uuid';

const DOMAIN_MAP = {
    localhost: 'http://dev.vivre.ro',
    'ro.vivre.eu': 'https://www.vivre.ro',
};

const isEligibleForMigration = (storage, domain) => (
    Object.prototype.hasOwnProperty.call(DOMAIN_MAP, domain)
    && !(
        Boolean(storage[COOKIE_NAME]?.length)
        || Boolean(storage[AUTH_COOKIE_NAME]?.length)
    )
);

const requestForUserCookies = async (domain, uuid) => {
    const response = await this.$http({
        method: 'get',
        url: `${DOMAIN_MAP[domain]}/cookies/`,
        params: {
            uuid,
            token: 'GkHzA>!*^X=~]f}',
        },
        withCredentials: true,
    });

    return response.data;
};

const writeCookie = (dispatch, httpResponse, key, value, options = {}) => {
    const expires = (
        options.expires
            ? new Date((Date.now() + options.expires * 1000))
            : null
    );

    httpResponse.cookie(key, value, {
        ...options, expires,
    });

    dispatch('storage/add', {
        key,
        value,
        ...options,
    }, { root: true });
};

const importCookies = (dispatch, cookies, res) => {
    Object.keys(cookies).forEach((key) => {
        writeCookie(dispatch, res, key, cookies[key].value, {
            expires: cookies[key]?.expires,
        });
    });
};

const markMigrationAsDone = (dispatch, res) => {
    writeCookie(dispatch, res, COOKIE_NAME, 'true', {
        expires: (60 * 60 * 24 * 365),
    });
};

export default {
    namespaced: true,

    state: () => ({}),

    actions: {
        migrate: async ({ dispatch, rootState }, { res, route }) => {
            if (
                !rootState.params.app.ssr
                || route === undefined
                || Boolean(!route.query[UUID_NAME]?.length)
            ) return null;

            const uuid = route.query[UUID_NAME];
            const { domain } = rootState.params.country;
            const { storage } = rootState;

            if (!isEligibleForMigration(storage, domain)) return null;

            const request = requestForUserCookies(domain, uuid);

            return request.then((response) => {
                importCookies(dispatch, response.data, res);
                markMigrationAsDone(dispatch, res);
            }).catch((error) => {
                console.error('user-cookie-migrate action: ', error);
            });
        },
    },
};
