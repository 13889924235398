import { HTTP_METHOD_TYPE } from '../utils/constants';
import { productReviewsAdaptor } from '../utils/adaptors';

export default async function fetchProductReviewsService({ $http, params }) {
    const response = await $http({
        method: HTTP_METHOD_TYPE.GET,
        url: `${$http.API.V3}reviews/product`,
        params,
    });

    return productReviewsAdaptor(response.data.data);
}
