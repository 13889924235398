const badgesStyle = (payload) => payload.map((badge) => {
    const output = {
        ...(badge.id && { id: badge.id }),
        ...(badge.text && { text: badge.text }),
        ...(badge.type && { type: badge.type }),
        // style props will be used as-is in view as css
        style: {
            ...(badge.style && {
                'background-color': badge.style.backgroundColor,
                color: badge.style.color,
                opacity: badge.style.opacity,
            }),
        },
    };

    // TODO format this data from api, and remove this block
    if (badge.name) {
        Object.assign(output, {
            text: badge.name,
        });
    }

    // TODO format this data from api, and remove this block
    if (badge.style && badge.style.type) {
        Object.assign(output, {
            type: badge.style.type,
        });
    }

    // TODO format this data from api, and remove this block
    if (badge.background_color) {
        Object.assign(output.style, {
            'background-color': badge.background_color,
        });
    }

    // TODO format this data from api, and remove this block
    if (badge.text_color) {
        Object.assign(output.style, {
            color: badge.text_color,
        });
    }

    // TODO format this data from api, and remove this block
    if (badge.background_alpha) {
        Object.assign(output.style, {
            opacity: badge.background_alpha,
        });
    }

    return output;
});

export { badgesStyle as default };
