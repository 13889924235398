const TYPE = {
    USER_SOCIAL_LOGIN: 'user_social_login',
    USER_SOCIAL_TOKEN: 'user_social_token',
    USER_LOGGED_IN: 'user_logged_in',
    STATE: 'state',
    APP_SHARE: 'share',
    APP_DOWNLOAD_DOCUMENT: 'download_document',
};

export default ({
    TYPE,
});
