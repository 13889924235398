import sharedComponents from './shared-components';
import sharedCheckFavorites from './shared-check-favorites';
import sharedProductsReview from './shared-products-review';
import { set } from '../../utils/store';

export default {
    namespaced: true,

    modules: {
        components: sharedComponents,
        globals: {
            modules: {
                checkFavorites: sharedCheckFavorites,
                productsReview: sharedProductsReview,
            },
        },
    },

    state: () => ({
        pages: {},

        // TODO finish this
        layouts: {
            default: {
                header: {},
                footer: {},
            },
            me: {
                siderbar: {},
            },
        },
    }),

    getters: {
        page: (state) => (path) => state.pages[path] || false,
        component: (state, payload) => (name = payload) => state.components.data[name] || false,
        globals: (state, payload) => (key = payload) => state.globals[key] || false,
    },

    mutations: {
        SET_PAGE_DATA(state, { routePath: path, data }) {
            set(state.pages, [path], data, this.$set);
        },
        SET_COMPONENT_DATA(state, { components }) {
            this.$set(state.components, 'data', {
                ...state.components.data,
                ...components,
            });
        },
        SET_GLOBALS_DATA(state, { key, data }) {
            set(state.globals, [key], data, this.$set);
        },
    },
};
