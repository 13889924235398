import { HTTP_METHOD_TYPE } from '../utils/constants';

import { formatData, JSONToURLEncode, storageHandler } from '../utils';
import { userToken } from '../utils/adaptors';

const headers = {
    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
};

export async function fetchUserTokenService({ $http, params, formData }) {
    const postedData = {
        ...(formData.token && { token: formData.token }),
        ...(formData.type && { type: formData.type }),
        ...(formData.username && { username: formData.username }),
        ...(formData.password && { password: formData.password }),
        ...(formData.referrerId && { referrer_id: formData.referrerId }),
        ...(formData.cookieMkt && { v_mkt: formData.cookieMkt }),
    };

    const formatedCredentials = JSONToURLEncode(postedData);

    const response = await $http({
        method: HTTP_METHOD_TYPE.POST,
        url: `${$http.API.BASEURL}login`,
        headers,
        data: formatedCredentials,
        params,
    });

    return userToken(response.data);
}

export async function generateAutoLoginEmailService({ $http, params, data }) {
    const response = await $http({
        method: HTTP_METHOD_TYPE.POST,
        url: `${$http.API.V3}user/autologin-transactional`,
        params,
        data,
    });

    return response.data;
}

export async function registerService({ $http, params, formData }) {
    const renamedKeys = {
        'User[email]': formData.email,
        'User[password]': formData.password,
        'User[new_user_nl_signup]': formData.newsletterSubscription,
        referrer_id: 'direct',
    };

    const formatedData = formatData(renamedKeys);

    const cookieMkt = await storageHandler.read('v_mkt');

    const response = await $http({
        method: HTTP_METHOD_TYPE.POST,
        url: `${$http.API.V1}user`,
        headers,
        params: {
            ...(cookieMkt && { v_mkt: cookieMkt }),
            ...params,
        },
        data: formatedData,
    });

    return response.data;
}

export async function resetPasswordService({ $http, params, email }) {
    const renamedKeys = {
        'User[email]': email,
        'User[forgot_password]': 2,
    };

    const formatedData = formatData(renamedKeys);

    const response = await $http({
        method: HTTP_METHOD_TYPE.POST,
        url: `${$http.API.V1}user`,
        headers,
        data: formatedData,
        params,
    });

    return response.data;
}
