import subtotalView from './subtotal-view';
import { CART_NOTIFICATIONS, CART_WARNINGS } from '../constants';

const sections = (payload) => {
    const output = [];

    const filtered = payload.map(({
        title,
        products,
        delivery_info_data: sectionInfo,
        freeDelivery,
        costAlert,
        seller,
        /* eslint-disable camelcase */
        // groups price prop
        total_products,
        full_shipping_fee,
        full_handling_fee,
        discount_shipping,
        base_shipping_fee,
        handling_fee,
        vat_deducted,
        pod_fee,
        voucher_amount,
        credit_amount,
        total_order,

        priceConversion,
    }) => {
        const info = Object.keys(sectionInfo).map((key) => {
            const outputInfo = {
                text: sectionInfo[key],
            };

            if (key === 'loyalty_points') {
                outputInfo.class = 'text-strong-success';
                outputInfo.icon = {
                    name: 'star-full',
                    color: 'text-warning',
                };
            }
            if (key === 'period') {
                outputInfo.icon = {
                    name: 'calendar',
                    color: 'text-secondary',
                };
            }

            return outputInfo;
        });

        const price = subtotalView({
            total_products,
            full_shipping_fee,
            full_handling_fee,
            discount_shipping,
            base_shipping_fee,
            handling_fee,
            vat_deducted,
            pod_fee,
            voucher_amount,
            credit_amount,
            total_order,
        }, {
            ...priceConversion,
        });

        return {
            title,
            products,
            displaySectionInfo: !!products.find((product) => !product.unavailable),
            info,
            price,
            freeDelivery,
            costAlert,
            seller,
        };
    });

    output.push(...filtered);

    return output;
};

const freeDeliveryData = ({
    canonical, name, amount,
}) => ({
    text: name,
    url: canonical,
    amount,
});

const sellerData = (id, payloadMkpSuppliers) => {
    const supplier = payloadMkpSuppliers.find((seller) => seller.id === id);

    return {
        name: supplier.name,
        ...(supplier.canonical && { url: supplier.canonical }),
    };
};

const deliveries = (payload = [], payloadMkpSuppliers = []) => {
    const output = [];
    const sectionsLength = payload.length;

    for (let sectionIndex = 0; sectionIndex < sectionsLength; sectionIndex += 1) {
        const section = payload[sectionIndex];
        const {
            deliveries: sectionDeliveries,
            voucher_code: sectionVoucherCode,
            free_delivery_threshold: sectionFreeDelivery,
            costAlert: sectionCostAlert,
            sellerId: sectionSellerId,
        } = section;
        const deliveriesLength = sectionDeliveries.length;

        for (let deliveryIndex = 0; deliveryIndex < deliveriesLength; deliveryIndex += 1) {
            const delivery = sectionDeliveries[deliveryIndex];

            output.push({
                ...delivery,
                ...(sectionVoucherCode && { voucherCode: sectionVoucherCode }),
                ...(sectionFreeDelivery && { freeDelivery: freeDeliveryData(sectionFreeDelivery) }),
                ...(sectionCostAlert && { costAlert: sectionCostAlert }),
                ...(sectionSellerId && {
                    seller: sellerData(sectionSellerId, payloadMkpSuppliers),
                }),
            });
        }
    }

    return output;
};

const priceConversion = ({
    total_products,
    full_shipping_fee,
    full_handling_fee,
    discount_shipping,
    voucher_amount,
    credit_amount,
    total_order,
} = {}) => ({
    totalProductsFee: total_products,
    fullShippingFee: full_shipping_fee,
    fullHandlingFee: full_handling_fee,
    discountShipping: discount_shipping,
    voucherAmount: voucher_amount,
    creditAmount: credit_amount,
    totalOrder: total_order,
});

export const warning = (payload) => payload && payload
    .find((item) => CART_WARNINGS.includes(item.type));

export const notification = (payload, notifications) => {
    const notificationItem = payload && payload
        .find((item) => notifications.includes(item.type ? item.type : item.code));
    return notificationItem && { body: notificationItem.message };
};

export default (payload) => ({
    // cart mixin
    sections: sections(deliveries(payload.split_cart_data, payload.mkp_suppliers)),
    hasExpiredCart: payload.has_expired_cart,
    error: payload.error,
    paymentMethods: payload.payment_methods, // TODO filter this and return all the iterables info
    banner: payload.banner,
    paymentType: payload.payment_type, // TODO delete this
    hasLargeProducts: payload.has_large_products,
    b2cOnly: payload.b2c_only,
    expiredProducts: payload.expired_products,
    warning: warning(payload.warnings),
    notification: notification(payload.warnings, CART_NOTIFICATIONS),
    hasProducts: payload.products.length > 0,
    // CartSummary
    totalProductsFee: payload.total_products,
    totalShippingFee: payload.base_shipping_fee,
    handlingFee: payload.handling_fee,
    fullShippingFee: payload.full_shipping_fee,
    fullHandlingFee: payload.full_handling_fee,
    discountShipping: payload.discount_shipping,
    totalPodFee: payload.pod_fee,
    vouchers: payload.vouchers,
    voucherAmount: payload.voucher_amount,
    creditAmount: payload.credit_amount,
    userPoints: payload.user_points,
    userPointsMessage: payload.user_points_message,
    totalOrder: payload.total_order,
    priceConversion: priceConversion(payload.priceConversion),
    // CartInfo, TheHeaderNavQuickBtnCart
    costAlert: payload.cost_alert,
    // dataLayer
    dataLayer: payload.dataLayer,
});
