const datalayerCategories = (categoryTree) => {
    if (categoryTree == null || categoryTree === '') {
        return {
            item_category: '',
        };
    }
    const categoriesArr = categoryTree.split('/');
    const categoriesObject = {
        item_category: categoriesArr[0].toLowerCase(),
    };
    const categoriesLength = categoriesArr.length < 5 ? categoriesArr.length : 5;
    for (let i = 1; i < categoriesLength; i += 1) {
        categoriesObject[`item_category${i + 1}`] = categoriesArr[i].toLowerCase();
    }
    return categoriesObject;
};

export { datalayerCategories as default };
