export default ({
    payment_details: paymentDetails,
    payment_type: paymentType,
    total_order: totalOrder,
    thanks_loyalty: thanksLoyalty,
    show_feedback: showFeedback,
    payment_status: { label: paymentStatus, code: paymentStatusCode },
    delivery,
    currency,
    previousOrdersCount,
    dataLayer,
}) => ({
    paymentDetails,
    paymentType,
    totalOrder,
    thanksLoyalty,
    showFeedback,
    paymentStatus,
    paymentStatusCode,
    delivery,
    currency,
    previousOrdersCount,
    dataLayer,
});
