export default {
    getters: {
        getOrderPaymentStatus: (state) => (orderId) => (state && state[orderId]) || false,
    },
    actions: {
        saveOrderPaymentStatus: ({ commit }, payload) => {
            const { orderId, status, statusCode } = JSON.parse(payload.data);

            if (!orderId || !status || !statusCode) return;

            commit('cns/SET_MESSAGES', {
                key: 'orderPaymentUpdate',
                value: {
                    [orderId]: {
                        status,
                        statusCode,
                    },
                },
            }, { root: true });
        },
    },
};
