let perPage = 12;
let page = 1;

export default async function fetchCollectionsService({
    $http,
    params,
}) {
    perPage = params.perPage || perPage;
    page = params.page || page;

    const response = await $http({
        method: 'get',
        url: `${$http.API.V3}collections`,
        params: {
            'per-page': perPage,
            page,
            ...params,
        },
    });

    return response.data;
}
