import { BREAKPOINTS } from '../constants';

const breakpointsKeys = Object.keys(BREAKPOINTS);
const isBreakpoint = ({ wantedKey, currentKey, direction = null }) => {
    const wantedIndex = breakpointsKeys
        .findIndex((entryKey) => entryKey === wantedKey);
    const currentIndex = breakpointsKeys
        .findIndex((entryKey) => entryKey === currentKey);

    if (direction === 'up') return currentIndex <= wantedIndex;
    if (direction === 'down') return currentIndex > wantedIndex;
    return currentIndex === wantedIndex;
};

export default {
    install(app) {
        const [vueVersion] = app.version.split('.');
        // TODO remove app.prototype when vue 2 is no longer supported
        const vuePrototype = vueVersion === '2' ? app.prototype : app.config.globalProperties;

        const onBreakpoint = (key, direction) => {
            if (!key) return false;

            const { $store } = vuePrototype;
            const { breakpoint } = $store.state.ui.client;

            return isBreakpoint({
                wantedKey: key,
                currentKey: breakpoint,
                direction,
            });
        };

        Object.assign(vuePrototype, {
            $mediaBreakpointUp: (key) => onBreakpoint(key, 'up'),
            $mediaBreakpointDown: (key) => onBreakpoint(key, 'down'),
        });
    },
};
