class Timer {
    constructor(callback, delay) {
        this.callback = callback;
        this.timeRemaining = delay;

        this.timerId = null;
        this.timeStart = null;
        this.stopped = null;

        this.start();
    }

    start() {
        this.stopped = false;

        this.timeStart = new Date();
        this.timerId = window.setTimeout(this.callback, this.timeRemaining);
    }

    stop() {
        this.stopped = true;

        window.clearTimeout(this.timerId);
    }

    pause() {
        if (this.stopped || this.timerId === null) return;

        window.clearTimeout(this.timerId);
        this.timeRemaining -= new Date() - this.timeStart;
    }

    resume() {
        if (this.stopped || this.timerId === null) return;

        this.timeStart = new Date();
        window.clearTimeout(this.timerId);
        this.timerId = window.setTimeout(this.callback, this.timeRemaining);
    }
}

export { Timer as default };
