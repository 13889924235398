var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("img", {
    attrs: {
      loading: _vm.lazy ? "lazy" : "eager",
      src: _vm.src,
      srcset: _vm.srcset,
      sizes: _vm.sizes,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }