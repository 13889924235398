import { price as formatPrice } from '../filters';

const subtotalView = (
    price,
    priceConversion = {},
) => {
    const section = [{
        labelKey: 'views_cart_split_cart_extra_products',
        valueConversion: priceConversion.total_products,
        value: formatPrice(price.total_products),
    }];

    if (!price.full_shipping_fee && !price.full_handling_fee && !price.discount_shipping) {
        section.push({
            labelKey: 'views_cart_split_cart_extra_delivery',
            valueConversion: priceConversion.base_shipping_fee,
            value: formatPrice(price.base_shipping_fee),
        });

        if (price.handling_fee) {
            section.push({
                labelKey: 'views_cart_labels_handling',
                valueConversion: priceConversion.handling_fee,
                value: formatPrice(price.handling_fee),
            });
        }
    }

    if (price.vat_deducted) {
        section.push({
            labelKey: 'views_cart_labels_vat_deducted',
            valueConversion: priceConversion.vat_deducted,
            value: `-${formatPrice(price.vat_deducted)}`,
        });
    }

    if (price.pod_fee) {
        section.push({
            labelKey: 'views_cart_split_cart_extra_pod',
            valueConversion: priceConversion.pod_fee,
            value: formatPrice(price.pod_fee),
        });
    }

    if (price.voucher_amount) {
        section.push({
            labelKey: 'views_cart_loadcart_0009',
            valueConversion: priceConversion.voucher_amount,
            value: `-${formatPrice(price.voucher_amount)}`,
        });
    }

    // vivre points
    if (price.credit_amount) {
        section.push({
            labelKey: 'cart_points_title',
            valueConversion: priceConversion.credit_amount,
            value: `-${formatPrice(price.credit_amount)}`,
        });
    }

    if (price.full_shipping_fee) {
        section.push({
            labelKey: 'views_cart_split_cart_extra_delivery',
            valueConversion: priceConversion.full_shipping_fee,
            value: `${formatPrice(price.full_shipping_fee)}`,
        });
    }

    if (price.full_handling_fee) {
        section.push({
            labelKey: 'views_cart_labels_handling',
            valueConversion: priceConversion.full_handling_fee,
            value: `${formatPrice(price.full_handling_fee)}`,
        });
    }

    if (price.discount_shipping) {
        section.push({
            labelKey: 'views_cart_labels_fees_discount',
            valueConversion: priceConversion.discount_shipping,
            value: `${formatPrice(price.discount_shipping)}`,
        });
    }

    section.push({
        labelKey: 'views_cart_loadcart_0005',
        valueConversion: priceConversion.total_order,
        value: formatPrice(price.total_order),
    });

    return section;
};

export { subtotalView as default };
