import { HTTP_METHOD_TYPE } from '../utils/constants';

export async function fetchUserProfileTagsService({ $http }) {
    const response = await $http({
        method: HTTP_METHOD_TYPE.GET,
        url: `${$http.API.V3}user/home-profile-tags/grouped`,
    });

    return response.data;
}

export async function fetchUserProfileService({ $http, params, id }) {
    const response = await $http({
        method: HTTP_METHOD_TYPE.GET,
        url: `${$http.API.V3}user/home-profiles/${id}`,
        params,
    });

    return response.data;
}

export async function fetchUserProfilesService({ $http, params }) {
    const response = await $http({
        method: HTTP_METHOD_TYPE.GET,
        url: `${$http.API.V3}user/home-profiles`,
        params,
    });

    return response.data;
}

export async function saveUserProfileService({ $http, params, data }) {
    const response = await $http({
        method: HTTP_METHOD_TYPE.POST,
        url: `${$http.API.V3}user/home-profiles`,
        params,
        data,
    });

    return response.data;
}

export async function updateUserProfileService({
    $http, params, data, id,
}) {
    const response = await $http({
        method: HTTP_METHOD_TYPE.PUT,
        url: `${$http.API.V3}user/home-profiles/${id}`,
        params,
        data,
    });

    return response.data;
}

export async function deleteUserProfileService({ $http, params, id }) {
    return $http({
        method: HTTP_METHOD_TYPE.DELETE,
        url: `${$http.API.V3}user/home-profiles/${id}`,
        params,
    });
}

export async function uploadUserAccountProfileMediaSerice({ $http, params, file }) {
    const formData = new FormData();
    formData.append('media', file);

    return $http({
        method: HTTP_METHOD_TYPE.POST,
        url: `${$http.API.V3}user/home-profile-media`,
        headers: {
            'Content-Type': 'multipart/form-data',
        },
        params,
        data: formData,
        timeout: 3 * 60 * 1000,
    });
}

export async function deleteUserAccountProfileMediaService({ $http, params, id }) {
    const response = await $http({
        method: HTTP_METHOD_TYPE.DELETE,
        url: `${$http.API.V3}user/home-profile-media/${id}`,
        params,
    });

    return response.data;
}
