const getSharedSchema = (url) => {
    const script = [{
        key: 'sharedSchemaOrg',
        innerHTML: `{
            "@context": "https://schema.org/",
            "@type": "WebSite",
            "name": "Vivre",
            "url": "${url}",
            "potentialAction": {
                "@type": "SearchAction",
                "target": "${url}search?searchItem={search_term_string}",
                "query-input": "required name=search_term_string"
            }
        }`,
        type: 'application/ld+json',
    }];
    const sanitizers = {
        sharedSchemaOrg: ['innerHTML'],
    };

    return { script, sanitizers };
};

const getHomeSchema = (payload) => {
    const script = [{
        key: 'homeSchemaOrg',
        innerHTML: `{
            "@context": "https://schema.org",
            "@type": "Organization",
            "name": "${payload.companyName}",
            "alternateName": "Vivre",
            "url": "${payload.url}",
            "logo": "${payload.assetsUrl}/vivre-logo.jpg",
            "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "${payload.phone}",
                "contactType": "customer service",
                "areaServed": "${payload.lang.toUpperCase()}",
                "availableLanguage": "${payload.lang}"
            },
            "sameAs": [${payload.socialProfiles}]
        }`,
        type: 'application/ld+json',
    }];
    const sanitizers = {
        homeSchemaOrg: ['innerHTML'],
    };

    return { script, sanitizers };
};

const getProductSchema = (payload) => {
    let reviews = [];
    if (payload.reviews) {
        reviews = payload.reviews.map((item) => ({
            '@type': 'Review',
            datePublished: item.date,
            reviewBody: item.comment,
            reviewRating: {
                '@type': 'Rating',
                bestRating: 5,
                ratingValue: item.score,
                worstRating: 1,
            },
            author: {
                '@type': 'Person',
                name: item.signature,
            },
        }));
    }

    let aggregateRatingObject;
    if (payload.rating) {
        aggregateRatingObject = {
            aggregateRating: {
                '@type': 'AggregateRating',
                ratingValue: payload.rating.score,
                reviewCount: payload.rating.count,
            },
        };
    }

    const script = [{
        key: 'productSchemaOrg',
        innerHTML: JSON.stringify({
            '@context': 'https://schema.org/',
            '@type': 'Product',
            ...(payload.rating ? aggregateRatingObject : {}),
            name: payload.name,
            image: payload.image,
            description: payload.description,
            brand: {
                '@type': 'Brand',
                name: payload.brand,
            },
            sku: payload.sku,
            mpn: payload.mpn,
            offers: {
                '@type': 'Offer',
                url: payload.url,
                priceCurrency: payload.currency,
                price: payload.price,
                priceValidUntil: '2029-12-31',
                availability: 'https://schema.org/InStock',
                itemCondition: 'https://schema.org/NewCondition',
            },
            review: reviews,
        }),
        type: 'application/ld+json',
    }];
    const sanitizers = {
        productSchemaOrg: ['innerHTML'],
    };

    return { script, sanitizers };
};

const getBreadcrumbSchemaV2 = (payload) => {
    const listItems = payload.breadcrumb.map((item, index) => ({
        '@type': 'ListItem',
        position: (index + 1),
        name: item.text,
        item: `${payload.url}${item.urlPath}`,
    }));

    const script = [{
        key: 'breadcrumbSchemaOrg',
        innerHTML: JSON.stringify({
            '@context': 'https://schema.org/',
            '@type': 'BreadcrumbList',
            itemListElement: listItems,
        }),
        type: 'application/ld+json',
    }];
    const sanitizers = {
        breadcrumbSchemaOrg: ['innerHTML'],
    };

    return { script, sanitizers };
};

const getProductMetaDescription = (payload) => {
    if (!payload.meta) return '';

    return payload.meta.find((meta) => meta.name === 'description').content;
};

export default {
    computed: {
        schemaOrg() {
            const routeName = this.$route.name;
            const {
                country,
                api: paramsApi,
            } = this.$store.state.params;

            if (!paramsApi) return null;

            const url = `https://${country.domain}/`;
            const sharedSchema = getSharedSchema(url);

            const output = {
                script: [
                    ...sharedSchema.script,
                ],
                sanitizers: {
                    ...sharedSchema.sanitizers,
                },
            };

            if (routeName === 'Homepage') {
                const companyName = this.$t('views_site_contact_0002');
                const payload = {
                    companyName,
                    url,
                    assetsUrl: this.$getAssetsUrl(),
                    lang: country.lang,
                    phone: paramsApi.contactPhoneList[0],
                };

                const { socialProfilesLinks } = this.$store.state.footer || false;
                if (socialProfilesLinks) {
                    const socialProfilesObj = socialProfilesLinks[country.id];
                    payload.socialProfiles = Object.values(socialProfilesObj).map((element) => `"${element}"`);
                }

                const homeSchema = getHomeSchema(payload);

                output.script = [
                    ...output.script,
                    ...homeSchema.script,
                ];
                output.sanitizers = {
                    ...output.sanitizers,
                    ...homeSchema.sanitizers,
                };
            }

            if (routeName === 'Product') {
                const pageData = this.$store.getters['pages/currentPage'](this.$route.fullPath);
                const productMeta = (pageData && pageData.meta) || false;
                const dataReviews = (pageData && pageData.dataReviews) || false;
                const dataReviewsPage1 = (dataReviews && dataReviews['page-1']) || false;
                const dataReviewsRating = (dataReviews && dataReviews.summary) || false;
                const product = (Object.prototype.hasOwnProperty.call(pageData, 'data') && pageData.data) || false;

                if (!product) return null;

                const { components } = product;

                const payload = {
                    description: getProductMetaDescription(productMeta),
                    brand: components.links.data[0].urlText,
                    name: components.title.data.text,
                    image: components.gallery.data[0].thumb,
                    sku: product.id,
                    mpn: product.id,
                    currency: this.$store.state.params.api.country.currency[0].code,
                    url: `${url}${this.$route.path}`,
                    price: parseFloat(`${components.price.data.format.whole}.${components.price.data.format.decimal}`),
                    ...(dataReviewsPage1 && { reviews: dataReviewsPage1.items }),
                    ...(dataReviewsRating && { rating: dataReviewsRating }),
                };

                const productSchema = getProductSchema(payload);

                if (components.breadcrumbs && components.breadcrumbs.data.length > 1) {
                    const breadCrumbSchema = getBreadcrumbSchemaV2({
                        url,
                        breadcrumb: components.breadcrumbs.data,
                    });

                    output.script = [
                        ...output.script,
                        ...breadCrumbSchema.script,
                    ];
                    output.sanitizers = {
                        ...output.sanitizers,
                        ...breadCrumbSchema.sanitizers,
                    };
                }

                output.script = [
                    ...output.script,
                    ...productSchema.script,
                ];
                output.sanitizers = {
                    ...output.sanitizers,
                    ...productSchema.sanitizers,
                };
            }

            const pageSlug = this.$route.path.charAt(1);
            if (routeName === 'Listing' && pageSlug === 's') {
                let page = this.$store.getters['pages/currentPage'](this.$route.fullPath);
                page = (Object.prototype.hasOwnProperty.call(page, 'data') && page.data) || false;

                if (!page) return null;

                const { categoryBreadcrumbs } = page;

                // TODO Aggregate getBreadcrumbSchemaV2 in listing & product
                if (categoryBreadcrumbs && categoryBreadcrumbs.length > 0) {
                    const breadCrumbSchema = getBreadcrumbSchemaV2({
                        url,
                        breadcrumb: categoryBreadcrumbs,
                    });

                    output.script = [
                        ...output.script,
                        ...breadCrumbSchema.script,
                    ];
                    output.sanitizers = {
                        ...output.sanitizers,
                        ...breadCrumbSchema.sanitizers,
                    };
                }
            }

            return output;
        },
    },
};
