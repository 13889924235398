export default {
    props: {
        id: {
            required: true,
            type: Number,
        },
        stock: {
            required: true,
            type: Object,
        },
        saleEventId: {
            required: true,
            type: Number,
        },
        datalayerProduct: {
            type: Object,
        },
        maxQuantity: {
            type: String,
        },
    },

    computed: {
        isProductActive() {
            const stock = [this.stock.info, this.stock.status];
            const status = ['AVAILABLE', 'LIMITED'];

            return !!stock.filter((value) => status.includes(value)).length;
        },
    },
};
