export default (fileInputName) => ({
    computed: {
        params() {
            return this.$store.state.params;
        },
    },

    methods: {
        handleFileInput(e, index) {
            const files = e;
            const { maxFileSize } = this.params.api.contactForm.upload;
            const { allowedExtensions } = this.params.api.contactForm.upload;
            let fileNames = '';

            for (let i = 0; i < files.length; i += 1) {
                const fileSize = files[i].size / 1000;
                const fileName = files[i].name;

                fileNames += `${fileName} `;

                let extension = fileName.replace(/^.*\./, '');

                if (extension === fileName) {
                    extension = '';
                } else {
                    extension = extension.toLowerCase();
                }
                this.form.errors[fileInputName][index] = {};
                if (allowedExtensions.some((ext) => ext === extension)) {
                    this.form.errors[fileInputName][index] = {
                        message: '',
                        hasError: false,
                    };
                } else {
                    // adding class on element
                    // adding file type error
                    this.form.errors[fileInputName][index] = {
                        message: this.$t('views_site_contactform_dragndrop_filetype'),
                        hasError: true,
                    };
                    this.form = {
                        ...this.form,
                    };
                    return;
                }

                // note, using unary operator to convert string to number
                if (fileSize < +maxFileSize) {
                    this.form.errors[fileInputName][index] = {
                        message: '',
                        hasError: false,
                    };
                } else {
                    // adding class on element
                    // adding file size error
                    this.form.errors[fileInputName][index] = {
                        message: this.$t('views_site_contactform_dragndrop_filesize'),
                        hasError: true,
                    };
                    this.form = {
                        ...this.form,
                    };
                    return;
                }
            }

            // if everything passes, assign to the data variable
            this.form.data[fileInputName] = [...this.form.data[fileInputName], ...files];
            this.form.fileLabel = fileNames;
            // reinitializing form for reactivity in vue
            this.form = {
                ...this.form,
            };
        },
        handleFileRemove(value) {
            this.form.data[fileInputName] = this.form.data[fileInputName]
                .filter((item) => item !== value);
        },
    },
});
