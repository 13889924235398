import datalayerPageProduct from './datalayer-page-product';

export default (payload, payloadToMerge) => ({
    transactionId: (payload.orderId && payload.orderId.split(',')[0]) || '',
    affiliation: payload.datalayer.transactionAffiliation || 'Vivre',
    revenue: payload.datalayer.revenue,
    revenueEur: payload.datalayer.revenue_eur,
    revenueRon: payload.datalayer.revenue_ron,
    tax: payload.datalayer.tax,
    taxEur: payload.datalayer.tax_eur,
    shipping: payload.datalayer.shipping,
    shippingEur: payload.datalayer.shipping_eur,
    coupon: payload.datalayer.coupon,
    couponEur: payload.datalayer.coupon_eur,
    products: (payload.products && payload.products.map((productItem) => datalayerPageProduct(
        productItem,
        {
            quantity: productItem.quantity,
        },
    ))) || [],
    ...payloadToMerge,
});
