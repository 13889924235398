import { BREAKPOINTS } from '../utils/constants';

export default {
    namespaced: true,

    state: () => ({
        breakpoint: null,
        activityStarted: null,
        scrollDirection: null,
    }),

    mutations: {
        SET: (state, { key, value }) => {
            Object.assign(state, {
                [key]: value,
            });
        },
    },

    actions: {
        attachActivityStarted({ commit }) {
            const events = [
                { name: 'touchstart', options: { once: true } },
                { name: 'mousemove', options: { once: true } },
                { name: 'keydown', options: { once: true } },
            ];
            const onActivityStarted = () => {
                commit('SET', {
                    key: 'activityStarted',
                    value: true,
                });

                events.forEach((event) => {
                    window.removeEventListener(event.name, onActivityStarted, event.options);
                });
            };

            events.forEach((event) => {
                window.addEventListener(event.name, onActivityStarted, event.options);
            });
        },
        attachWindowResizeListener({ commit, state }) {
            const setBreakpoint = (width) => {
                const [breakpoint] = Object.entries(BREAKPOINTS)
                    .find(([, value]) => width >= value);

                if (state.breakpoint === breakpoint) return;

                commit('SET', { key: 'breakpoint', value: breakpoint });
            };

            const setPropertyVh = (height) => {
                const propKey = '--vh';
                const propValue = `${height * 0.01}px`;
                const elStyle = document.documentElement.style;
                const getPropValue = elStyle.getPropertyValue(propKey);

                if (getPropValue === propValue) return;

                elStyle.setProperty(propKey, propValue);
            };

            setBreakpoint(window.innerWidth);
            setPropertyVh(window.innerHeight);

            window.addEventListener('resize', ({ target }) => {
                setBreakpoint(target.innerWidth);
                setPropertyVh(target.innerHeight);
            });
        },
        attachScroll({ commit, state }) {
            let scrollYLatest = 0;
            let ticking = false;

            const update = () => {
                ticking = false;

                const { scrollY } = window;
                const scrollYInTop = scrollY < 100;

                let scrollDirectionValue = null;

                if (scrollYInTop) {
                    scrollDirectionValue = false;
                } else if (scrollYLatest > scrollY) {
                    scrollDirectionValue = 'up';
                } else if (scrollYLatest < scrollY) {
                    scrollDirectionValue = 'down';
                }

                if (state.scrollDirection !== scrollDirectionValue) {
                    commit('SET', {
                        key: 'scrollDirection',
                        value: scrollDirectionValue,
                    });
                }

                scrollYLatest = scrollY;
            };

            const requestTick = () => {
                if (!ticking) requestAnimationFrame(update);

                ticking = true;
            };

            window.addEventListener('scroll', () => {
                requestTick();
            }, false);
        },
    },
};
