export default {
    namespaced: true,

    state: () => ({
        data: [],
    }),

    getters: {
        currentIndex: (state) => (payload) => state.data.findIndex((item, index) => index === payload.index || item.id === payload.id),
    },

    mutations: {
        PUSH: (state, payload) => {
            state.data.push(payload);
        },
        DELETE: (state, payloadIndex) => {
            state.data.splice(payloadIndex, 1);
        },
        SHOW: (state, index) => {
            state.data[index].visible = true;
        },
        HIDE: (state, index) => {
            state.data[index].visible = false;
        },
    },

    actions: {
        push: ({ commit, getters }, payload) => {
            const index = getters.currentIndex(payload);

            if (index !== -1) return;

            commit('PUSH', payload);
        },
        delete: ({ commit, getters }, payload) => {
            const index = getters.currentIndex(payload);

            if (index === -1) return;

            commit('DELETE', index);
        },
        show: ({ state, commit, getters }, payload) => {
            const index = getters.currentIndex(payload);

            if (index === -1) return;

            const isShown = state.data[index].visible === true;

            if (isShown) return;

            commit('SHOW', index);
        },
        hide: ({ state, commit, getters }, payload) => {
            const index = getters.currentIndex(payload);

            if (index === -1) return;

            const isHidden = state.data[index].visible === false;

            if (isHidden) return;

            commit('HIDE', index);
        },
        visible: ({ state, commit, getters }, payload) => {
            const index = getters.currentIndex(payload);

            if (index === -1) return;

            const commitVerb = (payload.visible) ? 'SHOW' : 'HIDE';
            const isSameStatus = state.data[index].visible === payload.visible;

            if (isSameStatus) return;

            commit(commitVerb, index);
        },
    },
};
