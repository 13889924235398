import historyModule from '../../store/modules/history';

export default {
    computed: {
        dataStoreHistory() {
            return {
                items: [],
                ...this.$store.state.shared.components.data.history,
            };
        },
        dataStoreHistoryPreview() {
            return {
                items: [],
                ...this.$store.state.shared.components.data.historyPreview,
            };
        },
    },

    methods: {
        registerModuleHistory({ store = this.$store } = {}) {
            if (store.hasModule(['shared', 'components', 'history'])) return;

            store.registerModule(['shared', 'components', 'history'], historyModule, { preserveState: store.state.shared.components.history });
        },
        historyFetch({
            pageSize = 6,
            isProductPreview,
        } = {}) {
            return this.$store.dispatch('shared/components/history/fetchHistory', {
                pageSize,
                isProductPreview,
            });
        },
    },
};
