export default function formatLabels(payload) {
    if (!payload) return false;

    const LABEL_VARIANTS = {
        'limited-quantity': 'danger',
        'in-stock': 'success',
        'fast-delivery': 'muted',
        'free-delivery': 'success',
        'fast-free-delivery': 'success',
        'maximum-quantity-per-cart': 'warning',
        'sold-out': 'danger',
    };

    return payload.map((item) => ({
        text: item.text || item.message,
        variant: LABEL_VARIANTS[item.variant || item.type] || 'muted',
        ...(item.icon && { icon: item.icon }),
    }));
}
