import { HTTP_METHOD_TYPE } from '../utils/constants';
import { itemProductSimilarEan, queryParams } from '../utils/adaptors';

export default async function fetchProductSimilarEanService({
    $http,
    productId,
    limit,
}) {
    const response = await $http({
        method: HTTP_METHOD_TYPE.GET,
        url: `${$http.API.V3}products/${productId}/similar/ean`,
        params: {
            'per-page': limit,
        },
    });

    if (!response.data.data.items.length) {
        return false;
    }

    const filteredProducts = response.data.data.items
        .map((item) => itemProductSimilarEan(
            item,
            {
                ch_type: (response.data.data.queryParams
                    && response.data.data.queryParams.ch_type),
                ch_id: productId,
            },
        ));

    return {
        products: filteredProducts,
        queryParams: queryParams(response.data.data.queryParams),
    };
}
