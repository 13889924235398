const datalayerProductListIdType = (payload) => {
    const itemListType = String((payload.queryParams && payload.queryParams.ch_type) || (payload.datalayer && payload.datalayer.queryParams && payload.datalayer.queryParams.ch_type) || payload.ch_type || '');
    let itemListId = String((payload.queryParams && payload.queryParams.ch_id) || (payload.datalayer && payload.datalayer.queryParams && payload.datalayer.queryParams.ch_id) || payload.ch_id || '');
    const chTypeAccountLists = ['22', '23', '24'];
    if ((itemListId === '' || itemListId === 'null' || itemListId === 'NaN') && itemListType && chTypeAccountLists.indexOf(itemListType)) {
        itemListId = 'false';
    }
    return {
        item_list_type: itemListType,
        item_list_id: itemListId,
    };
};

export { datalayerProductListIdType as default };
