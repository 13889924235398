import { FetchError } from './errors';
import { HTTP_METHOD_TYPE } from '../utils/constants';
import { pagePromoEventsAdaptor } from '../utils/adaptors';

export default async function fetchPagePromoEventsService({ $http, params, route }) {
    const response = await $http({
        method: HTTP_METHOD_TYPE.GET,
        url: `${$http.API.V3}promo-events`,
        params,
    });

    const { error } = response.data;
    if (error && error.redirect && error.redirect.path) {
        const Error = new FetchError();
        Error.payload = {
            url: error.redirect.path,
            code: error.statusCode,
        };
        throw Error;
    }

    if (response.data.slug && `/${response.data.slug}` !== route.path) {
        const Error = new FetchError();
        Error.payload = {
            url: `/${response.data.slug}`,
            code: 301,
        };
        throw Error;
    }

    return pagePromoEventsAdaptor(response);
}
