var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "the-app", attrs: { id: "app" } },
    [
      _vm.loading.appHasData
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.loading.pageShow,
                  expression: "loading.pageShow",
                },
              ],
              staticClass: "the-wrapper",
            },
            [
              _vm.showOpenInApp ? _c(_setup.OpenInApp) : _vm._e(),
              _vm._v(" "),
              _c("router-view", {
                staticClass: "the-header",
                attrs: { name: "header" },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "the-content" }, [_c("router-view")], 1),
              _vm._v(" "),
              _c("router-view", {
                staticClass: "the-footer",
                attrs: { name: "footer" },
              }),
              _vm._v(" "),
              _vm.isGdprVisible ? _c(_setup.NotificationGdpr) : _vm._e(),
              _vm._v(" "),
              _c(_setup.TheNotifications),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _setup.$store.state.ui.isVisible.web.appBtnBackToTop
        ? _c(_setup.AppBtnBackToTop)
        : _vm._e(),
      _vm._v(" "),
      _c(_setup.TheModals),
      _vm._v(" "),
      _vm.loading.showAppLoader
        ? _c(_setup.TheLoader, { attrs: { "full-page": true } })
        : _vm._e(),
      _vm._v(" "),
      _vm.loading.showPageLoader ? _c(_setup.TheLoaderPage) : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }