import { checkFavoritesService } from '../../services';

export default {
    actions: {
        async fetchFavoriteProducts({ commit, rootState, rootGetters }, payload) {
            const favoriteProducts = rootGetters['shared/globals']('checkFavorites') || {};
            const filteredIds = payload
                .map((product) => product.id)
                .filter((id) => !favoriteProducts[id]);
            const accessToken = rootState.storage.api_v2_token;

            if (!accessToken || !filteredIds) {
                return;
            }
            await checkFavoritesService({
                $http: this.$http,
                params: {
                    source: rootState.params.source,
                    lang: rootState.params.country.lang,
                    accessToken,
                },
                identifiers: filteredIds,
            }).then((favorites) => {
                favorites.forEach((item) => {
                    favoriteProducts[item] = true;
                });
                commit('shared/SET_GLOBALS_DATA', { key: 'checkFavorites', data: favoriteProducts }, { root: true });
            });
        },
        toggleFavoriteProduct: async ({ commit, rootGetters }, payload) => {
            const favoriteProducts = rootGetters['shared/globals']('checkFavorites') || {};
            if (favoriteProducts[payload]) {
                delete favoriteProducts[payload];
            } else {
                favoriteProducts[payload] = true;
            }
            commit('shared/SET_GLOBALS_DATA', { key: 'checkFavorites', data: favoriteProducts }, { root: true });
        },
        resetFavoriteProducts: ({ commit }) => {
            commit('shared/SET_GLOBALS_DATA', { key: 'checkFavorites', data: {} }, { root: true });
        },
    },
};
