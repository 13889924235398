const ADDRESS_FORM_FIELDS = () => ({
    first_name: '',
    last_name: '',
    street_name: '',
    street_no: '',
    street_address_1: '',
    state_id: '',
    city: '',
    city_id: '',
    phone: '',
    email: '',
    postal_code: '',
});

const ADDRESS_FORM_INITIAL_STATE = () => ({
    data: ADDRESS_FORM_FIELDS(),
    error: null,
    submitted: false,
    isLoading: false,
});

const BILLING_FORM_FIELDS = () => ({
    city: '',
    company: '',
    reg_com: '',
    cui: '',
    state: '',
    state_id: '',
    postal_code: '',
    street_address_1: '',
});

const ADDRESS_PICKUP_FORM_FIELDS = () => ({
    first_name: '',
    last_name: '',
    phone: '',
    email: '',
    city: '',
    city_id: '',
    pickup_point_id: '',
    street_address_1: '',
});

const ADDRESS_PICKUP_FORM_INITIAL_STATE = () => ({
    data: ADDRESS_FORM_FIELDS(),
    error: null,
    submitted: false,
    isLoading: false,
});

const BILLING_FORM_INITIAL_STATE = () => ({
    data: BILLING_FORM_FIELDS(),
    error: null,
    submitted: false,
    isLoading: false,
});

const BILLING_ERRORS = {
    INVOICE_NOT_ALLOWED: 'INVOICE_NOT_ALLOWED',
};

export default ({
    ADDRESS_FORM_FIELDS,
    ADDRESS_FORM_INITIAL_STATE,
    BILLING_FORM_FIELDS,
    ADDRESS_PICKUP_FORM_FIELDS,
    ADDRESS_PICKUP_FORM_INITIAL_STATE,
    BILLING_FORM_INITIAL_STATE,
    BILLING_ERRORS,
});
