import constants from './constants/filters';
import * as collectionUtil from './collection';
import FilterEvent from '../models/FilterEvent';

export default class FilterUrlBuilder {
    /**
     *
     * @param {Filter[]} payload AKA filters
     * @param route
     *
     * @constructor
     */
    constructor(payload, route) {
        this.searchQueryParam = 'searchItem';
        this.excludeQueryParams = ['page'];

        this.route = route;
        this.normalized = this.normalize(payload);
        this.indexed = collectionUtil.reindexBy(this.normalized, 'key');
        this.filterKeys = this.normalized.map((filter) => filter.key);
        this.requested = this.normalized.filter((val) => val.isRequested);

        this.queryParts = {
            base: this.getBasicQueryParams(),
            others: this.getExternalQueryParams(),
            defaultFilterParts: {},
            template: {},
        };

        this.filterKeys.forEach((e) => {
            this.queryParts.defaultFilterParts[e] = [];
        });

        // This part it will be used as a start template for getUrl.
        this.queryParts.template = {
            ...this.queryParts.base,
            ...this.queryParts.defaultFilterParts,
            ...this.queryParts.others,
        };
    }

    /**
     * Bring all filters to the same level and map to internal schema.
     *
     * @param {Filter[]} filters
     *
     * @return {Array}
     */
    normalize(filters) {
        let list = [];

        for (let i = 0, { length } = filters; i < length; i += 1) {
            const filter = filters[i];
            let obj = null;

            // Nested filters, make them flat (append in root).
            if (filter.type === constants.FILTER_TYPE_ATTRIBUTE_GROUP) {
                obj = this.normalize(filter.options);
            } else if (filter.type === constants.FILTER_TYPE_ATTRIBUTE) {
                // Check if the given filter is present in request query-params.
                const isRequested = Object.prototype
                    .hasOwnProperty.call(this.route.query, filter.key);
                const { isDynamic } = filter;

                const internalFilterOptions = (isDynamic
                    ? []
                    : filter.options.map((el) => el.value)
                );
                const requestFilterOptions = (isRequested ? this.route.query[filter.key].split(',') : []);

                obj = {
                    id: filter.id,
                    type: filter.type,
                    key: filter.key,
                    viewStyle: filter.viewStyle,
                    metas: filter.metas || {},
                    filter,

                    isRequested,
                    isDynamic,

                    options: {
                        internal: internalFilterOptions,
                        request: (
                            isDynamic
                                ? requestFilterOptions
                                : collectionUtil
                                    .intersect(internalFilterOptions, requestFilterOptions)
                        ),
                    },
                };
            }

            if (obj) {
                // Merge with the given flat list.
                list = list.concat(obj);
            }
        }

        return list;
    }

    /**
     * Get the basic QueryParam with `search term` if exists.
     *
     * @return {Object}
     */
    getBasicQueryParams() {
        const params = {};
        const searchKey = this.searchQueryParam;

        if (Object.prototype.hasOwnProperty.call(this.route.query, searchKey)) {
            params[searchKey] = this.route.query[searchKey];
        }

        return params;
    }

    /**
     * Get a new QueryParam without `known-filter-keys`
     * and other params from `EXCLUDE_QUERY_PARAMS`;
     */
    getExternalQueryParams() {
        const filtersQuery = this.normalized.map((filter) => filter.key);

        const excludeFromQuery = [
            ...this.excludeQueryParams,
            this.searchQueryParam,
        ].concat(filtersQuery);

        return collectionUtil.removePropertiesFromObject({ ...this.route.query }, excludeFromQuery);
    }

    /**
     * @return {Object}
     */
    getRequestedAsFilterValue() {
        const params = {};

        for (let i = 0, { length } = this.requested; i < length; i += 1) {
            const normalized = this.requested[i];

            params[normalized.key] = new FilterEvent(
                normalized.filter,
                (
                    normalized.isDynamic
                        ? normalized.options.request
                        : collectionUtil
                            .intersect(normalized.options.internal, normalized.options.request)
                ),
            );
        }

        return params;
    }

    /**
     * @param {FilterEvent[]} collection[]
     * @param {Boolean} autoWireWithRequested
     *
     * @return {String}
     */
    getUrl(collection, autoWireWithRequested = true) {
        let parts = [];
        const params = { ...this.queryParts.template };

        if (autoWireWithRequested) {
            for (let i = 0, { length } = this.requested; i < length; i += 1) {
                const filter = this.requested[i];

                params[filter.key] = filter.options.request;
            }
        }

        const otherParams = { ...this.queryParts.others };

        for (let i = 0, { length } = collection; i < length; i += 1) {
            const element = collection[i];

            const {
                key, isDynamic, value, metas,
            } = element;

            if (isDynamic) {
                params[key] = value;
            } else if (
                autoWireWithRequested === true
                && collectionUtil.intersect(params[key], value).length
            ) {
                // If the selected value already exists in params, we remove it.
                params[key] = collectionUtil.difference(params[key], value);
            } else {
                // Else we add the value to the list and also sort base on internal info.
                params[key] = collectionUtil.intersect(
                    this.indexed[key].options.internal,
                    [...params[key], ...value],
                );
            }

            const paramToDelete = Object.keys(otherParams)
                .find((param) => param === metas.oldVersion);
            if (paramToDelete) delete params[paramToDelete];
        }

        // eslint-disable-next-line no-restricted-syntax
        for (const k in params) {
            if (params[k].length) {
                const val = Array.isArray(params[k]) ? params[k].join(',') : params[k];

                parts.push(`${k}=${val}`);
            }
        }

        parts = parts.join('&');

        return (this.route.path + ((parts.length > 0) ? (`?${parts}`) : ''));
    }
}
