import { HTTP_METHOD_TYPE } from '../utils/constants';

import { user, utilsPreferences } from '../utils/adaptors';

export async function fetchUserService({ $http, params }) {
    const response = await $http({
        method: HTTP_METHOD_TYPE.GET,
        url: `${$http.API.V1}user`,
        params,
    });

    return user(response.data);
}

export async function modifyUserService({ $http, params, data }) {
    const response = await $http({
        method: HTTP_METHOD_TYPE.PATCH,
        url: `${$http.API.V1}user`,
        data,
        params,
    });

    return response.data;
}

export async function changePasswordService({ $http, params, data }) {
    const response = await $http({
        method: HTTP_METHOD_TYPE.PATCH,
        url: `${$http.API.V1}user`,
        data,
        params,
    });

    return response.data;
}

export async function setAllPreferencesService({ $http, params }) {
    const response = await $http({
        method: HTTP_METHOD_TYPE.GET,
        url: `${$http.API.V2}utils/preferences`,
        params,
    });

    return utilsPreferences(response.data);
}

export async function changeSubscriptionOptionsService({ $http, params, data }) {
    const response = await $http({
        method: HTTP_METHOD_TYPE.PATCH,
        url: `${$http.API.V1}user`,
        data,
        params,
    });

    return response.data;
}

export async function newsletterSubscribeService({ $http, params, data }) {
    const response = await $http({
        method: HTTP_METHOD_TYPE.POST,
        url: `${$http.API.V3}user/newsletter-subscribe`,
        params,
        data,
    });

    return response.data;
}

export async function deleteUserAccountService({ $http, params }) {
    const response = await $http({
        method: 'post',
        url: `${$http.API.V3}user/delete-account`,
        params,
    });

    return response.data;
}

export async function fetchDeleteUserAccountStatusService({ $http, params }) {
    const response = await $http({
        method: 'get',
        url: `${$http.API.V3}user/delete-account`,
        params,
    });

    return response.data;
}
