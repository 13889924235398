import { HTTP_METHOD_TYPE } from '../utils/constants';
import itemSaleEvent from '../utils/adaptors/item-sale-event';
import { itemProduct, queryParams } from '../utils/adaptors';
import itemProfile from '../utils/adaptors/item-profile';

export async function fetchHomepageSectionsService({
    $http,
}) {
    try {
        const response = await $http({
            method: HTTP_METHOD_TYPE.GET,
            url: `${$http.API.BASEURL_NODE_API}homepage-sections`,
        });

        const filteredResponse = [];

        response.data.forEach((section) => {
            if (section.type === 'productSlider' && !section.lazy) {
                const filteredProducts = section.data.items
                    .map((item) => itemProduct(
                        item,
                        {
                            ch_type: (
                                response.data.query_params
                                && response.data.query_params.ch_type
                            ),
                        },
                        response.data.badges,
                    ));
                filteredResponse.push({
                    ...section,
                    data: filteredProducts,
                    queryParams: queryParams(section.data.queryParams),
                });
            } else if (section.type === 'collectionSlider' && !section.lazy) {
                const events = section.data.items;

                filteredResponse.push({
                    ...section,
                    data: events,
                });
            } else if (section.type === 'eventSlider' && !section.lazy) {
                const events = section.data.items
                    ? section.data.items.map((item) => itemSaleEvent(item))
                    : [];

                filteredResponse.push({
                    ...section,
                    data: events,
                });
            } else if (section.type === 'profileSlider' && !section.lazy) {
                const profiles = section.data.items
                    ? section.data.items.map((item) => itemProfile(item))
                    : [];

                filteredResponse.push({
                    ...section,
                    data: [
                        { cta: true },
                        ...profiles,
                    ],
                });
            } else {
                filteredResponse.push(section);
            }
        });

        return filteredResponse;
    } catch (error) {
        console.error('fetchHomepageSectionsService ', error);

        return null;
    }
}

export async function fetchHomepageFeedService({ $http, params }) {
    const response = await $http({
        method: HTTP_METHOD_TYPE.GET,
        url: `${$http.API.V3}feeds/products`,
        params,
    });

    const { items, page_info: pagination } = response.data.data;

    const filteredProducts = items
        .map((item) => itemProduct(
            item,
            {
                ch_type: (response.data.data.queryParams
                    && response.data.data.queryParams.ch_type),
                ch_id: params.id,
            },
            response.data.data.badges,
        ));

    return {
        items: filteredProducts,
        queryParams: queryParams(response.data.data.queryParams),
        pagination,
    };
}

export default null;
