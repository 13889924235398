export default {
    data: () => ({
        citiesFilter: '',
    }),

    computed: {
        account() {
            return this.$store.state.account;
        },
        accountAddresses() {
            return this.account.addresses || {};
        },
        cities() {
            return this.accountAddresses.cities || [];
        },
        countryID() {
            return this.$store.state.params.country.id;
        },
        isCityValid() {
            return !(this.form.data.city.length && !this.form.data.city_id);
        },
        isFormValid() {
            return !(this.$v.form.data.$error) && !Object.keys({ ...this.form.error }).length;
        },
        isFieldVisible() {
            return (field) => !!(this.config[field] && this.config[field].visible);
        },
        fieldErrorMessage() {
            return (field) => (this.form.error && this.form.error[field] && this.form.error[field].message) || this.$t('views_cart_ckeckout_0027');
        },
        mainError() {
            return this.form.error ? (this.form.error.main || '') : '';
        },
        hasMainError() {
            return !!(this.form.error && this.form.error.main);
        },
        fieldLabel() {
            return (field) => (this.config[field] ? this.config[field].label : '');
        },
        isRequired() {
            return (field) => (this.config[field] ? this.config[field].required : false);
        },
        isCityInvalid() {
            return (this.form.submitted && !this.$v.form.data.city.hasId);
        },
    },

    watch: {
        apiErrors() {
            this.showApiErrors();
        },
        formHandlerTrigger(newVal) {
            if (newVal) {
                this.submitForm();
            }
        },
    },

    methods: {
        fetchCities(filterBy, filterValue, hasPickupPoints = false) {
            let key = ''; let courier = ''; let payload = {};

            switch (filterBy) {
            case 'state':
                key = 'state_id';
                courier = 'local';
                break;
            case 'postal_code':
                key = 'zip_code';
                courier = 'local';
                break;
            case 'speedy':
                key = 'site_name';
                courier = 'speedy';
                break;
            default:
                key = 'state_id';
                courier = 'local';
                break;
            }
            payload = {
                params: {
                    query: { [key]: filterValue },
                },
                courier,
            };

            if (hasPickupPoints) payload.params.query.box_delivery = 1;

            return this.$store.dispatch('account/addresses/fetchCities', payload);
        },
        handleFormKeyUp() {
            this.resetSelectedAddress();
            this.resetApiErrors();
        },
        setCitiesFilter(filter) {
            this.citiesFilter = filter;
        },
        resetFormValidation() {
            this.$v.$reset();
        },
        validateForm() {
            this.form.submitted = true;
            this.$v.form.data.$touch();
        },
        isCitySelected() {
            return () => {
                if (this.$isCountry(['SK', 'CZ'])) return true;
                return !(this.form.data.city.length && !this.form.data.city_id);
            };
        },
    },
};
