import { pushNotification } from '../../utils';
import { NOTIFICATION_TYPE } from '../../utils/constants';
import { fetchCartService, mergeCartService } from '../../services/cart-mini';

export default {
    namespaced: true,

    state: () => ({}),

    mutations: {
        SET_DATA(state, payload) {
            Object.keys(payload).forEach((key) => {
                this.$set(state, key, payload[key]);
            });
        },
        RESET_PRODUCTS: (state) => { Object.assign(state, { products: [] }); },
        RESET_EXPIRE: (state) => { Object.assign(state, { hasExpiredCart: null }); },
    },

    actions: {
        async fetchData({ state, commit, rootState }, payload) {
            const payloadData = {
                forceFetch: false,
                ...payload,
            };

            const stateHasData = Object.entries(state).length;
            const keepCached = !payloadData.forceFetch && stateHasData;

            if (keepCached) return;

            await fetchCartService({
                $http: this.$http,
                user: {
                    token: rootState.storage.api_v2_token,
                    userSession: rootState.user.session,
                },
                params: {
                    source: rootState.params.source,
                    lang: rootState.params.country.lang,
                },
            }).then((response) => {
                commit('SET_DATA', response);
            }).catch((error) => {
                console.error('fetchCartService: ', error);
            });
        },
        mergeData({ rootState, dispatch }) {
            mergeCartService({
                $http: this.$http,
                params: {
                    source: rootState.params.source,
                    lang: rootState.params.country.lang,
                    accessToken: rootState.storage.api_v2_token,
                    device_id: rootState.user.session,
                },
            }).then((response) => {
                if (response.notification) {
                    pushNotification(
                        dispatch,
                        NOTIFICATION_TYPE.WARNING,
                        response.notification.body,
                    );
                }
            }).catch((error) => {
                console.error('mergeCartService: ', error);
            });
        },
        reset: ({ commit }) => {
            commit('RESET_PRODUCTS');
            commit('RESET_EXPIRE');
        },
    },
};
