export default {
    methods: {
        async trackImpressionsAndClicks(options) {
            if (!options.length) return;

            const { default: TrackingImpressionsAndClicks } = await import('../TrackingImpressionsAndClicks');

            const ti = new TrackingImpressionsAndClicks(options);

            const getPayloadItem = (parent, child) => {
                const chType = options[parent].chType
                    || options[parent].data[child].datalayer.queryParams.ch_type;

                return {
                    eventName: options[parent].eventName,
                    payloadItem: {
                        ...options[parent].data[child],
                        listName: chType,
                        position: child + 1,
                    },
                    type: options[parent].type,
                };
            };

            ti.onChildImpression(({ index: [parent, child] }) => {
                this.$store.dispatch(
                    'datalayer/pushListsItemsImpressions',
                    getPayloadItem(parent, child),
                );
            });

            ti.onChildClick(({ index: [parent, child] }) => {
                this.$store.dispatch(
                    'datalayer/pushListItemClick',
                    getPayloadItem(parent, child),
                );
            });
        },
    },
};
