const itemProfile = (payload) => {
    let cover = null;

    if (payload.media
        && payload.media.length > 0
        && payload.media[0].mimeType.split('/')[0] === 'image'
    ) {
        cover = payload.media[0].src;
    }

    return {
        id: payload.id,
        userId: payload.userId,
        userAlias: payload.userAlias,
        nameInitials: payload.nameInitials,
        cover,
        country: payload.country,
    };
};

export { itemProfile as default };
