var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    {
      staticClass: "icon",
      class: [_vm.name && "icon-" + _vm.name, _vm.classesPrefixed],
      attrs: { role: "img" },
    },
    [
      !!_vm.$slots.default
        ? _vm._t("default")
        : _c("svg", [
            _c("use", {
              attrs: {
                "xlink:href": `#${_vm.name}--sprite`,
                "xmlns:xlink": "http://www.w3.org/1999/xlink",
              },
            }),
          ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }