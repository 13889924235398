import { HTTP_METHOD_TYPE } from '../utils/constants';
import { queryParams, productResealedAdaptor } from '../utils/adaptors';

export default async function fetchProductResealedService({ $http, params }) {
    const response = await $http({
        method: HTTP_METHOD_TYPE.GET,
        url: `${$http.API.V3}resealed-products`,
        params,
    });

    const items = response.data.items && response.data.items
        .map((item) => productResealedAdaptor(item,
            queryParams(response.data && response.data.query_params)));

    return {
        dataLayer: response.data.dataLayer,
        ...(response.data && response.data.type
            && { type: response.data.type }),
        ...(items && { items }),
    };
}
