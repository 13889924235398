import { storageHandler } from '../index';

const gdprName = {
    1: 'cookies',
    5: 'cookies_measurement',
    6: 'cookies_advanced',
};

export default ({ gdpr, trackingOff = false }) => {
    const consentCookie = storageHandler.read('cookies_consent') || '';
    const consentCookieIds = consentCookie.split(',').filter((key) => key.indexOf('~', 0) === -1 && key) || [];
    const cookiePreferences = [];
    const trackingOffCookiesSettings = ['1'];

    Object.keys(gdprName).forEach((key) => {
        const getCookieValue = consentCookieIds.indexOf(key) !== -1;
        const trueForNoCookie = consentCookieIds.length > 0 ? getCookieValue : true;

        const trackingOffCookieValue = trackingOffCookiesSettings.indexOf(key) !== -1;
        const trackingOnCookieValue = gdpr[key].enabled !== null
            ? gdpr[key].enabled : trueForNoCookie;

        const isEnabled = trackingOff ? trackingOffCookieValue : trackingOnCookieValue;

        cookiePreferences.push({
            id: key,
            name: gdprName[key],
            enabled: isEnabled,
        });
    });

    return cookiePreferences;
};
