import { HTTP_METHOD_TYPE } from '../utils/constants';
import { pageRewards } from '../utils/adaptors';

export default async function fetchPageRewardsService({ $http, token, params }) {
    const response = await $http({
        method: HTTP_METHOD_TYPE.GET,
        url: `${$http.API.V3}rewards/validate/${token}`,
        params,
    });

    return pageRewards(response.data);
}
