const RATING_5 = [
    'user_rating_r0',
    'user_rating_r1',
    'user_rating_r2',
    'user_rating_r3',
    'user_rating_r4',
    'user_rating_r5',
];

export default ({
    RATING_5,
});
