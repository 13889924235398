<template>
    <img
        :loading="lazy ? 'lazy' : 'eager'"
        :src="src"
        :srcset="srcset"
        :sizes="sizes"
    >
</template>

<script>
export default {
    name: 'BaseImg',

    props: {
        lazy: {
            type: Boolean,
            default: true,
        },
        src: {
            type: String,
            required: true,
        },
        srcset: {
            type: [Boolean, String],
            default: undefined,
        },
        sizes: {
            type: [Boolean, String],
            default: undefined,
        },
    },
};
</script>
