import { HTTP_METHOD_TYPE } from '../utils/constants';

export default async function fetchPageLandingService({ $http, params, countryId }) {
    const response = await $http({
        method: HTTP_METHOD_TYPE.GET,
        url: `${$http.API.V2}landing`,
        params,
    });

    if (response.data.landing.country !== countryId) throw { redirect: true };

    return response.data;
}
