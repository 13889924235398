import { buildFormData } from '../utils';
import { HTTP_METHOD_TYPE } from '../utils/constants';
import { pageAccountReturns } from '../utils/adaptors';

export async function fetchReturnOrdersService({ $http, params }) {
    const response = await $http({
        method: HTTP_METHOD_TYPE.GET,
        url: `${$http.API.BASEURL}mobile/v2/returns/orders`,
        params,
    });
    return pageAccountReturns(response.data);
}

export async function fetchReturnSummaryService({ $http, params, data }) {
    const response = await $http({
        method: HTTP_METHOD_TYPE.POST,
        url: `${$http.API.BASEURL}mobile/v2/returns/fees`,
        params,
        data,
    });

    return response.data;
}

export function saveReturnService({ $http, params, data }) {
    const formData = new FormData();
    buildFormData(formData, data);

    return $http({
        method: HTTP_METHOD_TYPE.POST,
        url: `${$http.API.BASEURL}mobile/v2/return`,
        headers: { 'Content-Type': 'multipart/form-data' },
        params,
        data: formData,
    });
}
