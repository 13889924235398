export default ({
    // romania
    RO: {
        ID: 1,
        minPhoneDigits: 10,
        email: 'contact@vivre.eu',
    },
    // bulgaria
    BG: {
        ID: 2,
        minPhoneDigits: 7,
        email: 'contact@vivre.bg',
    },
    // hungary
    HU: {
        ID: 3,
        minPhoneDigits: 8,
        email: 'info@vivre.hu',
    },
    // croatia
    HR: {
        ID: 4,
        minPhoneDigits: 8,
        email: 'kontakt@vivre.hr',
    },
    // poland
    PL: {
        ID: 5,
        minPhoneDigits: 9,
        email: 'contact@vivre.eu',
    },
    // slovenia
    SI: {
        ID: 7,
        minPhoneDigits: 8,
        email: 'kontakt@vivre.si',
    },
    // slovakia
    SK: {
        ID: 8,
        minPhoneDigits: 9,
        email: 'contact@vivre.eu',
    },
    // czechia
    CZ: {
        ID: 9,
        minPhoneDigits: 9,
        email: 'contact@vivre.eu',
    },
    // greece
    GR: {
        ID: 10,
        minPhoneDigits: 10,
        email: 'contact@vivre.eu',
    },
    // moldova
    MD: {
        ID: 11,
        minPhoneDigits: 10,
        email: 'contact@vivre.eu',
    },
});
