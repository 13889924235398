export default {
    model: {
        prop: 'visible',
        event: 'hidden',
    },

    props: {
        visible: Boolean,
    },

    computed: {
        computedVisible: {
            get() { return this.visible; },
            set(value) { return this.$emit('hidden', value); },
        },
    },

    methods: {
        closeModal() {
            this.computedVisible = false;
        },
    },
};
