export default ({
    VALUES: [
        'views_myaccount_retur_months_0001',
        'views_myaccount_retur_months_0002',
        'views_myaccount_retur_months_0003',
        'views_myaccount_retur_months_0004',
        'views_myaccount_retur_months_0005',
        'views_myaccount_retur_months_0006',
        'views_myaccount_retur_months_0007',
        'views_myaccount_retur_months_0008',
        'views_myaccount_retur_months_0009',
        'views_myaccount_retur_months_0010',
        'views_myaccount_retur_months_0011',
        'views_myaccount_retur_months_0012',
    ],
});
