import { HTTP_METHOD_TYPE } from '../utils/constants';
import { itemProduct, queryParams } from '../utils/adaptors';

export default async function fetchSearchByImgService({ $http, params }) {
    const response = await $http({
        method: HTTP_METHOD_TYPE.GET,
        url: `${$http.API.BASEURL}mobile/v2/search/image`,
        params,
    });

    const filteredSearchResults = response.data.items
        .map((item) => itemProduct(item, undefined, response.data.badges));

    return {
        searchResults: filteredSearchResults,
        queryParams: queryParams(response.data.query_params),
    };
}
