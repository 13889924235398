import { HTTP_METHOD_TYPE } from '../utils/constants';

export default async function checkFavoritesService({ $http, params, identifiers }) {
    const data = { ids: identifiers };

    const response = await $http({
        method: HTTP_METHOD_TYPE.POST,
        url: `${$http.API.V2}user/favorites/check`,
        params,
        data,
    });

    return response.data.data.favorites;
}
