export default {
    computed: {
        isProductFavorite() {
            const favorites = this.$store.getters['shared/globals']('checkFavorites');
            return favorites && favorites[this.id];
        },
        btnFavoritesLabel() {
            if (!this.isProductFavorite) return this.$t('views_product_add_to_favorites_label');

            return this.$t('views_product_added_to_favorites_label');
        },
    },
};
