import {
    stopActionFetchPageData,
    get, set, del,
} from '../../utils/store';
import { fetchPageMetaService } from '../../services';

const pagesToKeep = 20;

export default {
    namespaced: true,

    state: () => ({}),

    getters: {
        currentPage: (state) => (path) => {
            const [pathClean] = path.split('#');
            const pages = state;
            const page = pages[pathClean];

            return page || false;
        },
    },

    mutations: {
        SET_PAGE_DATA(state, payload) {
            // TODO delete payload.currentPage from all commits
            const path = payload.routePath.split('#')[0];
            const dataKeys = [path, ...payload.prop.split('.')];
            const dataKeyPage = dataKeys[0];
            const dataKeyFirst = dataKeys[1];
            const dataCurrent = get(state, dataKeys);
            let data = null;

            if (dataCurrent instanceof Array || payload.type === 'array') {
                const payloadData = (payload.data instanceof Array) ? payload.data : [payload.data];

                if (dataCurrent) {
                    if (payload.unshift) data = [...payloadData, ...dataCurrent];
                    else if (payload.replace) data = payloadData;
                    else data = [...dataCurrent, ...payloadData];
                } else {
                    data = payloadData;
                }
            } else if (dataCurrent instanceof Object || payload.type === 'object') {
                data = { ...dataCurrent, ...payload.data };
            } else {
                data = payload.data;
            }

            set(state, dataKeys, data, this.$set);

            set(state, [dataKeyPage, 'timestamp', dataKeyFirst], new Date().getTime(), this.$set);

            const stateKeysPages = Object.keys(state)
                .filter((key) => key[0] === '/' && state[key].timestamp);
            if (stateKeysPages.length > pagesToKeep) {
                const keyToDelete = stateKeysPages
                    .filter((key) => key !== dataKeyPage)
                    .reduce((prev, curr) => {
                        if (state[prev].timestamp.loading < state[curr].timestamp.loading) {
                            return prev;
                        }

                        return curr;
                    });

                del(state, [keyToDelete], this.$delete);
            }
        },
        REMOVE_PAGES_STARTS_WITH(state, routePath) {
            const keysToDelete = Object.keys(state)
                .filter((key) => key.indexOf(routePath) === 0);

            keysToDelete.forEach((key) => {
                del(state, [key], this.$delete);
            });
        },
    },

    actions: {
        pageLoading: ({ dispatch, commit, rootState }, {
            route,
            loading,
            usePath,
        }) => {
            const pageKey = (typeof usePath === 'string' && usePath)
                || (usePath && route.path)
                || route.fullPath;

            if (rootState.ui.loading.web.showAppLoader !== false && loading === 'DONE') {
                dispatch('ui/setLoading', {
                    showAppLoader: false,
                    pageShow: true,
                }, { root: true });
            }

            commit('pages/SET_PAGE_DATA', {
                prop: 'loading',
                data: loading,
                routePath: pageKey,
            }, { root: true });
        },
        fetchPageMeta({ commit, getters, rootState }, {
            route,
            type,
            data,
            usePath,
            forceFetch = false,
        }) {
            const pageKey = (typeof usePath === 'string' && usePath)
                || (usePath && route.path)
                || route.fullPath;
            const currentPage = getters.currentPage(pageKey);
            const prop = 'meta';

            if (stopActionFetchPageData(currentPage, prop, forceFetch)) return null;

            return fetchPageMetaService({
                $http: this.$http,
                params: {
                    source: rootState.params.source,
                    lang: rootState.params.country.lang,
                    payload: {
                        type,
                        ...(data && { data }),
                        query: route.query,
                    },
                },
            }).then((response) => {
                commit('pages/SET_PAGE_DATA', {
                    prop,
                    data: response,
                    routePath: pageKey,
                    replace: forceFetch,
                }, { root: true });
            }).catch((error) => {
                console.error('fetchPageMetaService: ', error);
            });
        },
    },
};
