<template>
    <div
        v-show="show"
        class="progress progress-page"
    >
        <div
            role="progressbar"
            class="progress-bar progress-bar-indeterminate"
        />
    </div>
</template>

<script>
export default {
    name: 'TheLoaderPage',

    data: () => ({
        show: false,
        timeoutID: null,
    }),

    mounted() {
        this.timeoutID = setTimeout(() => {
            this.show = true;
        }, 250);
    },

    beforeDestroy() {
        clearTimeout(this.timeoutID);
    },
};
</script>

<style lang="scss">
@import "./../scss/05-components/progress";

.progress-page {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: $zindex-fixed;
    height: 4px;
    border-radius: 0;
    opacity: .6;
}
</style>
