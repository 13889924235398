import { HTTP_METHOD_TYPE } from '../utils/constants';
import { adyenSession, cartAdyenResult, pageAccountCards } from '../utils/adaptors';

const setupParamUser = (payload) => {
    const params = {};

    if (payload.token) {
        params.accessToken = payload.token;
    } else {
        params.device_id = payload.userSession;
    }

    return params;
};

export async function fetchPaymentSessionService({ $http, params, user }) {
    const paramUser = setupParamUser(user);
    const response = await $http({
        method: HTTP_METHOD_TYPE.GET,
        url: `${$http.API.V3}adyen/initiate`,
        params: {
            ...params,
            ...paramUser,
        },
    });

    return adyenSession(response.data);
}

export async function fetchCardsService({ $http, params, user }) {
    const paramUser = setupParamUser(user);

    const response = await $http({
        method: HTTP_METHOD_TYPE.GET,
        url: `${$http.API.V3}user/cards`,
        params: {
            ...params,
            ...paramUser,
        },
    });

    return pageAccountCards(response.data);
}

export async function deleteCardService({
    $http,
    params, user, data,
}) {
    const paramUser = setupParamUser(user);

    return $http({
        method: HTTP_METHOD_TYPE.DELETE,
        url: `${$http.API.V3}user/card`,
        params: {
            ...params,
            ...paramUser,
        },
        data,
    });
}

export async function addCardService({
    $http,
    params, user, data,
}) {
    const paramUser = setupParamUser(user);

    const response = await $http({
        method: HTTP_METHOD_TYPE.POST,
        url: `${$http.API.V3}user/card`,
        params: {
            ...params,
            ...paramUser,
        },
        data,
    });

    return cartAdyenResult(response.data);
}
