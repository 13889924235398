function flagTemplate(stock) {
    const templateData = [];

    if (stock.includes('LIMITED')) {
        templateData.push({
            translateKey: 'views_saleevent_stock_label_warn',
            variant: 'danger',
        });
    }

    if (stock.includes('FAST_DELIVERY')) {
        templateData.push({
            translateKey: 'views_saleevent_stock_label_fast_delivery',
            variant: 'success',
        });
    }

    if (stock.includes('FAST_DELIVERY_UNAVAILABLE')) {
        templateData.push({
            translateKey: 'views_saleevent_no_stock_delivery',
            variant: 'success',
        });
    }

    if (stock.includes('SOLD_OUT')) {
        templateData.push({
            translateKey: 'views_saleevent_stock_label_oos',
            variant: 'muted',
        });
    }

    if (stock.includes('ENDED_CAMPAIGN')) {
        templateData.push({
            translateKey: 'views_saleevent_stock_label_closed',
            variant: 'muted',
        });
    }

    return templateData;
}

const itemProductFlags = (payload) => ({
    stock: payload.stock,
    template: flagTemplate(payload.stock),
});

export { itemProductFlags as default };
