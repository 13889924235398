import { Timestamp } from '../utils';
import { HTTP_METHOD_TYPE } from '../utils/constants';
import { AWS } from '../configs';

const cache = {};
const cacheExpireMinutes = 1;

const http = async (params, $http) => {
    const response = await $http({
        method: HTTP_METHOD_TYPE.GET,
        url: `${AWS.ASSETS}vivre.json`,
        params,
        custom: {
            config: {
                ssr: {
                    timeout: 1000,
                },
                csr: {
                    timeout: 2000,
                },
            },
        },
    });

    return response.data;
};

export default async function fetchSwitchesService({ $http, params }) {
    try {
        // bypass cache if client-side, this is covered by store
        if (typeof window !== 'undefined') {
            return http(params, $http);
        }

        const isCacheExpired = Timestamp.expired({
            timestamp: cache.timestamp,
            offsetMinutes: cacheExpireMinutes,
        });
        const isCacheInvalid = !cache.data || isCacheExpired;

        if (isCacheInvalid) {
            const responseData = await http(params, $http);
            const responseTimestamp = new Date().getTime();

            cache.data = responseData;
            cache.timestamp = responseTimestamp;
        }

        return cache.data;
    } catch (error) {
        console.error('fetchSwitchesService: ', error);

        return false;
    }
}
