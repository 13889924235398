import { HTTP_METHOD_TYPE } from '../utils/constants';
import { returnFeedbackInputs } from '../utils/adaptors';

export async function fetchReturnFeedbackInputsService({ $http, params }) {
    const response = await $http({
        method: HTTP_METHOD_TYPE.GET,
        url: `${$http.API.V3}user/get-nps-form`,
        params,
    });

    return returnFeedbackInputs(response.data);
}

export async function saveReturnFeedbackService({ $http, params, data }) {
    const response = $http({
        method: HTTP_METHOD_TYPE.POST,
        url: `${$http.API.V3}user/store-nps-form`,
        params,
        data: {
            npsFormResponse: data,
        },
    });

    return response.data;
}
