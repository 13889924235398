import { HTTP_METHOD_TYPE } from '../utils/constants';

export default async function fetchInlineControl({ $http, params }) {
    const response = await $http({
        method: HTTP_METHOD_TYPE.GET,
        url: `${$http.API.V3}page-inline-control`,
        params,
        timeout: 3000,
    });

    return response.data;
}
