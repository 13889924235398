const country = (payload) => {
    if (!payload) { return {}; }

    const iban = {
        accountLen: payload.formats.iban.account_len,
        backCodeLen: payload.formats.iban.back_code_len,
        checksumLen: payload.formats.iban.checksum_len,
        countryLen: payload.formats.iban.country_len,
        country: payload.formats.iban.country,
    };

    return {
        ...payload,
        formats: {
            ...payload.formats,
            iban,
        },
    };
};

export default (payload) => {
    const {
        gtm: { id: GTM_ID },
        optimize: { id: OPTIMIZE_ID },
        analytics: { id: ANALYTICS_ID },
    } = payload.google;

    const output = {
        GOOGLE: {
            GTM: {
                ID: GTM_ID,
            },
            OPTIMIZE: {
                ID: OPTIMIZE_ID,
            },
            ANALYTICS: {
                ID: ANALYTICS_ID,
            },
            SOCIAL_LOGIN: {
                ID: payload.google.social_login.client_id,
                IosID: payload.google.social_login.ios_client_id,
            },
        },
        GTM: payload.GTM,
        cdn: payload.cdn,
        apple: payload.apple,
        facebook: {
            ...payload.FaceBook,
            version: payload.FaceBook.version,
        },
        googleRecaptcha: {
            siteKey: payload.google_recaptcha.site_key,
        },
        contactForm: {
            upload: payload.contact_form.upload,
            userType: {
                client: payload.contact_form.user_type.client,
            },
        },
        ...(payload.contact_phone_list && { contactPhoneList: payload.contact_phone_list }),
        ...(payload.public_contact_phone && { publicContactPhone: payload.public_contact_phone }),
        lang: payload.lang,
        timestamp: payload.timestamp,
        translateVersion: payload.translateVersion,
        country: country(payload.country),
        countries: payload.countries,
        webDomain: payload.web_domain,
        splashScreen: payload.splash_screen,
    };

    return output;
};
