export default (userId) => {
    const userColors = [
        'red',
        'orange',
        'yellow',
        'green',
        'blue',
        'purple',
    ];

    const color = userId % userColors.length;

    return userColors[color];
};
