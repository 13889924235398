export const BREAKPOINTS_DEVICES = {
    motoG4: 360,
    motoGPower: 412, // 2023 PSI emulation device
};

export default {
    xl: 1200,
    lg: 992,
    md: 768,
    sm: 576,
    xs: 0,
};
