import toBuilder from '../toBuilder';

const itemProductMini = (payload, queryParams) => ({
    id: payload.productId,
    hotspot: payload.hotspot,
    mainImage: payload.mainImage,
    price: payload.price,
    name: payload.name,
    to: toBuilder(payload.slug, queryParams),
});

export default itemProductMini;
