const datalayerProductListName = (itemPayload) => {
    const listNameMap = {
        '': '',
        0: 'direct',
        2: 'search result',
        10: 'carousel - recently viewed',
        18: itemPayload.item_brand,
        19: 'carousel - recommended products',
        22: 'product best sellers',
        23: 'products recommended',
        24: 'product history',
        26: 'carousel - frequently bought together',
    };
    if (listNameMap[(itemPayload.item_list_type || '')] !== undefined) {
        Object.assign(itemPayload, {
            item_list_name: listNameMap[(itemPayload.item_list_type || '')],
        });
    }
    if (['1', '17'].indexOf(itemPayload.item_list_type) > -1) {
        Object.assign(itemPayload, {
            promotion_name: itemPayload.item_list_name,
        });
    }
    return itemPayload;
};

export { datalayerProductListName as default };
