import { HTTP_METHOD_TYPE } from '../utils/constants';

export default async function fetchPageJobsService({ $http, params }) {
    const response = await $http({
        method: HTTP_METHOD_TYPE.GET,
        url: `${$http.API.V1}careers`,
        params,
    });

    return response.data;
}
