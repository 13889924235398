import { fetchUserProductsReviewService } from '../../services/page-account-reviews';

export default {
    actions: {
        async fetchUserProductsReview({ commit, rootState, rootGetters }, {
            forceFetch = false,
        }) {
            const accessToken = rootState.storage.api_v2_token;
            const data = rootGetters['shared/globals']('productsReview');
            const keepCached = (data && data.length) && !forceFetch;

            if (!accessToken || keepCached) return;

            await fetchUserProductsReviewService({
                $http: this.$http,
                params: {
                    source: rootState.params.source,
                    lang: rootState.params.country.lang,
                    accessToken,
                },
            }).then((response) => {
                commit('shared/SET_GLOBALS_DATA', { key: 'productsReview', data: response.items }, { root: true });
            }).catch((error) => {
                console.error('fetchUserProductsReviewService:', error);
            });
        },
    },
};
