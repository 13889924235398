import { HTTP_METHOD_TYPE } from '../utils/constants';

const fetchLikesService = async ({ $http, entity, params }) => {
    try {
        const context = Object.keys(entity)[0];
        const id = entity[context];

        const response = await $http({
            method: HTTP_METHOD_TYPE.GET,
            url: `${$http.API.V3}like/${context}/${id}`,
            params,
        });

        return response.data;
    } catch {
        return false;
    }
};

const likeService = async ({ $http, entity, params }) => {
    const context = Object.keys(entity)[0];
    const id = entity[context];

    const response = await $http({
        method: HTTP_METHOD_TYPE.POST,
        url: `${$http.API.V3}like/${context}/${id}`,
        params,
    });

    return response.data;
};

const unlikeService = async ({ $http, entity, params }) => {
    const context = Object.keys(entity)[0];
    const id = entity[context];

    const response = await $http({
        method: HTTP_METHOD_TYPE.DELETE,
        url: `${$http.API.V3}like/${context}/${id}`,
        params,
    });

    return response.data;
};

export {
    fetchLikesService,
    likeService,
    unlikeService,
};
