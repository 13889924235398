import itemProductFlags from './item-product-flags';
import itemProductBadges from './item-product-badges';
import formatLabels from './format-labels';

const itemProduct = (payload, queryParams = {}, badges = []) => ({
    id: payload.id,
    name: payload.name,
    // TODO - remove after new app release
    shortName: payload.short_name,
    // TODO remove this after closing CK-349
    slug: payload.slug,
    path: payload.slug,
    imageSrc: payload.image.original,
    imageSrcAmbient: payload.image_ambient && payload.image_ambient.src,
    price: payload.price,
    priceDecimal: payload.price_decimal,
    ...(payload.lowest_price_30d && { priceStrikethrough: payload.lowest_price_30d }),
    originalPrice: payload.original_price,
    completePrice: payload.completePrice,
    priceConversion: payload.priceConversion,
    flags: itemProductFlags(payload.flags),
    currency: payload.currency,
    badges: itemProductBadges(payload, badges),
    arObjects: payload.ar_objects,
    marketplace: payload.marketplace,
    ...(payload.review
        && { rating: payload.review }),
    variants: payload.variants,
    ...(payload.labels
        && { labels: formatLabels(payload.labels) }),
    ...(payload.notification
        && { notification: formatLabels([payload.notification])[0] }),
    ...(payload.activity
        && { activity: payload.activity }),
    datalayer: {
        brand: payload.brand,
        categoryTree: payload.category_tree,
        categoryTreeEn: payload.category_tree_en,
        nameEn: payload.name_en,
        discountDistribution: payload.discountDistribution,
        discountValue: payload.discountValue,
        originalPrice: payload.original_price || payload.price,
        priceNewEur: payload.euro_selling_price,
        stockFast: payload.stock.fast_available,
        stockSupplier: payload.stock.supplier_available,
        stockInfo: payload.flags.stock,
        queryParams: payload.query_params && Object.keys(payload.query_params).length > 0
            ? { ...queryParams, ...payload.query_params }
            : queryParams,
    },
});

export { itemProduct as default };
