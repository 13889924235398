export default (targetId, margin = 20, behavior = 'auto') => {
    if (targetId.startsWith('#')) targetId = targetId.substring(1);
    const target = document.getElementById(targetId);

    if (!target) return;

    const appHeader = document.querySelector('.the-header');
    const appHeaderOffset = appHeader && appHeader.offsetHeight + margin || 0;

    setTimeout(() => {
        const targetDistanceToTop = window.pageYOffset + target.getBoundingClientRect().top;
        const offsetX = 0;
        const offsetY = targetDistanceToTop - appHeaderOffset;

        window.scrollTo({
            top: offsetY,
            left: offsetX,
            behavior,
        });
    }, 1);
};
