const SOCIAL_TYPES = {
    GOOGLE: 'google',
    FACEBOOK: 'facebook',
    APPLE: 'apple',
};

export const SOCIAL_SCOPES = {
    [SOCIAL_TYPES.APPLE]: 'name email',
    [SOCIAL_TYPES.FACEBOOK]: 'public_profile,email',
};

export default ({
    SOCIAL_TYPES,
    SOCIAL_SCOPES,
});
