import { HTTP_METHOD_TYPE } from '../utils/constants';
import itemProfile from '../utils/adaptors/item-profile';

export default async function fetchPageListingProfilesService({ $http, params }) {
    const response = await $http({
        method: HTTP_METHOD_TYPE.GET,
        url: `${$http.API.V3}profiles`,
        params,
    });
    const { items, pagination } = response.data.data;

    const itemsProfile = items.map((item) => itemProfile(item));

    return {
        items: itemsProfile,
        pagination,
    };
}
