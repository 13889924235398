import constants from '../constants/filters';
import Filter from '../../models/Filter';
import FilterOption from '../../models/FilterOption';
import FilterEvent from '../../models/FilterEvent';
import * as collectionUtil from '../collection';
import FilterUrlBuilder from '../FilterUrlBuilder';

const FILTER_WITH_DYNAMIC_VALUES = [constants.VIEW_TYPE_RANGE];

const routeRemoveQuery = (route, queryRemove) => {
    const query = { ...route.query };
    delete query[queryRemove];

    return {
        to: route.path,
        query,
    };
};

/**
 * Handle filter options.
 *
 * @param {Object} filterRaw
 *
 * @return {FilterOption[]}
 */
const mapOptions = (filterRaw) => (
    filterRaw.options
        .map((option) => (new FilterOption({
            id: (option.id || null),
            type: option.type,
            name: option.title,
            value: option.value,
            selected: option.selected,
            noFollow: !option.doFollow,
            url: null,
        })))
);

/**
 * Handle filter.
 *
 * @param {Array} filters
 *
 * @return {Filter[]}
 */
const mapFilter = (filters, route) => (
    filters
        .filter((filter) => (filter.options && filter.options.length))
        .map((filterRaw) => {
            let options = mapOptions(filterRaw);

            if (filterRaw.type === constants.FILTER_TYPE_ATTRIBUTE_GROUP) {
                options = mapFilter(filterRaw.options, route);
            }

            const filterKey = (filterRaw.request_key_name || '');

            return new Filter({
                id: filterRaw.id,
                type: filterRaw.type,
                viewStyle: filterRaw.view_style,
                isDynamic: (
                    collectionUtil.intersect(
                        FILTER_WITH_DYNAMIC_VALUES,
                        [filterRaw.view_style],
                    ).length > 0
                ),
                name: filterRaw.name,
                key: filterKey,
                options,
                noFollow: !filterRaw.doFollow,
                metas: filterRaw.metas || {},
                routeReset: routeRemoveQuery(route, filterKey),
            });
        })
);

/**
 * Attention: We need to keep the query-params in the same order provided by API for SEO reasons.
 *
 * @param {Filter[]} filters
 * @param {FilterUrlBuilder} builder
 *
 * @return {Array}
 */
const enrichWithUrls = (filters, builder) => (
    filters.map((filter) => {
        if (filter.type === constants.FILTER_TYPE_ATTRIBUTE_GROUP) {
            Object.assign(filter, {
                options: enrichWithUrls(filter.options, builder),
            });

            return filter;
        }

        Object.assign(filter, {
            options: filter.options.map((option) => {
                Object.assign(option, {
                    url: builder.getUrl(
                        ([
                            new FilterEvent(filter, option.value),
                        ]),
                        true,
                    ),
                });

                return option;
            }),
        });

        return filter;
    })
);

/**
 *
 * @param {Array} payload
 * @param route
 *
 * @returns {Array}
 */
export default (payload, route) => {
    const filters = mapFilter([...payload], route);
    const builder = new FilterUrlBuilder(filters, route);

    return enrichWithUrls(filters, builder);
};
