export default {
    create(name, value, seconds) {
        if (typeof window === 'undefined') return null;

        let expires = '';

        if (seconds) {
            const miliseconds = seconds * 1000;
            const date = new Date();
            date.setTime(date.getTime() + miliseconds);
            expires = `; expires=${date.toUTCString()}`;
        }

        document.cookie = `${name}=${value}${expires}; path=/`;

        return true;
    },

    read(name) {
        if (typeof window === 'undefined') return null;

        const cookies = document.cookie.split(';').map((cookie) => cookie.trim()).map((cookie) => cookie.split('='));
        const cookie = cookies.filter((item) => item.includes(name))[0];

        if (cookie && cookie.length && cookie[0] === String(name)) {
            return cookie[1];
        }

        return false;
    },

    delete(name) {
        if (typeof window === 'undefined') return null;

        return this.create(name, '', -1);
    },

    list() {
        if (typeof window === 'undefined') return null;

        const cookies = {};

        if (document.cookie && document.cookie !== '') {
            const split = document.cookie.split(';');
            for (let i = 0; i < split.length; i += 1) {
                const nameValue = split[i].split('=');
                nameValue[0] = nameValue[0].replace(/^ /, '');
                cookies[decodeURIComponent(nameValue[0])] = decodeURIComponent(nameValue[1]);
            }
        }

        return cookies;
    },
};
