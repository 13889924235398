import { buildFormData } from '../utils';
import { HTTP_METHOD_TYPE } from '../utils/constants';

export async function fetchReviewTemplateService({ $http, params }) {
    const response = await $http({
        method: HTTP_METHOD_TYPE.GET,
        url: `${$http.API.V3}user/reviews/template`,
        params,
    });

    return response.data;
}

export async function saveReviewService({ $http, params, data }) {
    const formData = new FormData();
    buildFormData(formData, data);

    const response = await $http({
        method: HTTP_METHOD_TYPE.POST,
        url: `${$http.API.V3}user/reviews/product`,
        headers: { 'Content-Type': 'multipart/form-data' },
        params,
        data: formData,
    });

    return response.data;
}

export async function editReviewService({ $http, params, data }) {
    const formData = new FormData();
    buildFormData(formData, data);

    const response = await $http({
        method: HTTP_METHOD_TYPE.POST,
        url: `${$http.API.V3}user/reviews/${data.reviewId}`,
        headers: { 'Content-Type': 'multipart/form-data' },
        params,
        data: formData,
    });

    return response.data;
}

export async function saveReviewMediaService({ $http, params, data }) {
    const formData = new FormData();
    buildFormData(formData, data);

    const response = await $http({
        method: HTTP_METHOD_TYPE.POST,
        url: `${$http.API.V3}user/reviews/${data.reviewId}/media`,
        headers: { 'Content-Type': 'multipart/form-data' },
        params,
        data: formData,
    });

    return response.data;
}

export async function deleteReviewMediaService({ $http, params, data }) {
    const response = await $http({
        method: HTTP_METHOD_TYPE.DELETE,
        url: `${$http.API.V3}user/reviews/${data.reviewId}/media/${data.mediaId}`,
        params,
    });

    return response.data;
}
