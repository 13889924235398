export default {
    install(app) {
        const [vueVersion] = app.version.split('.');
        // TODO remove app.prototype when vue 2 is no longer supported
        const vuePrototype = vueVersion === '2' ? app.prototype : app.config.globalProperties;

        vuePrototype.$isCountry = function isCountry(countriesCode) {
            const currentCountryId = this.$store.state.params.country.id;
            const currentCountryName = Object.keys(this.$COUNTRY)
                .find((country) => this.$COUNTRY[country].ID === currentCountryId);

            return countriesCode.includes(currentCountryName);
        };
    },
};
