import Vue from 'vue';
import Vuex from 'vuex';
import client from './client';

// store modules
import params from './params';
import header from './modules/header';
import pages from './modules/pages';
import shared from './modules/shared';
import user from './modules/user';
import userCookieMigrate from './modules/user-cookie-migrate';
import redirectLogin from './modules/redirect-login';
import storage from './modules/storage';
import ui from './modules/ui';
import addToFavorites from './modules/add-to-favorites';
import cns from './modules/cns';

Vue.use(Vuex);

export default function createStore() {
    return new Vuex.Store({
        modules: {
            params,
            header,
            pages,
            shared,
            user,
            userCookieMigrate,
            redirectLogin,
            storage,
            ui: {
                ...ui,
                modules: {
                    ...ui.modules,
                    client,
                },
            },
            addToFavorites,
            cns,
        },

        state: () => ({}),
    });
}
