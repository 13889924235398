export default (payload) => {
    const params = {};
    if (payload.token) {
        params.accessToken = payload.token;
    } else {
        params.device_id = payload.userSession;
    }

    return params;
};
