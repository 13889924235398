import Vue from 'vue';
import Router from 'vue-router';
import qs from 'qs';
import { FILTERS } from './utils/constants';

Vue.use(Router);

const TheHeader = () => import('./components/TheHeader.vue');
const TheFooter = () => import('./components/TheFooter.vue');

function csrProcessComponent(component) {
    const { mixins } = component;
    // page mixim must be first
    const hasMixinPage = mixins && mixins.length && mixins[0].beforeRouteEnter;

    if (!hasMixinPage) return;

    mixins[0].beforeRouteEnter = mixins[0].beforeRouteEnter.bind({
        $methods: {
            ...component.methods,
            ...mixins[0].methods,
        },
    });
}

export default function createRouter() {
    const router = new Router({
        mode: 'history',
        stringifyQuery: (query) => {
            const result = qs.stringify(query, { format: 'RFC1738' });
            return result ? (`?${result}`) : '';
        },
        scrollBehavior(to, from, savedPosition) {
            let output = { x: 0, y: 0 };

            const getOffsetY = (offset) => {
                const elAppHeader = document.querySelector('.the-header');
                const appHeaderHeight = (elAppHeader && elAppHeader.offsetHeight) || 0;

                return appHeaderHeight + offset;
            };

            if (from.hash) output = null;
            else if (savedPosition) {
                const { custom: { classBeforeScroll } = {} } = to.matched[0].components.default;

                if (classBeforeScroll) {
                    const el = to.matched[0].instances.default.$el;

                    el.classList.add(classBeforeScroll);

                    output = new Promise((resolve) => {
                        setTimeout(() => {
                            setTimeout(() => {
                                el.classList.remove(classBeforeScroll);
                            }, 250);

                            resolve({
                                ...savedPosition,
                                behavior: 'smooth',
                            });
                        }, 250);
                    });

                    return output;
                }

                output = savedPosition;
            } else if (to.hash) {
                const isHashBlacklisted = to.hash.indexOf('=') > -1;
                let selector = to.hash;

                if (isHashBlacklisted) selector = null;

                output = {
                    selector,
                    offset: { x: 0, y: getOffsetY(20) },
                };
            } else if (['Listing', 'ListingVendor', 'Search'].includes(to.name) && to.query) {
                const hasPageFilter = Object.keys(to.query).findIndex((filter) => {
                    const filterPrefix = filter
                        .indexOf(FILTERS.API_ALLOWED_FILTER_KEY_PREFIX) !== -1;
                    const filterMatch = FILTERS.API_ALLOWED_FILTER_KEYS.includes(filter);

                    return filterPrefix || filterMatch;
                }) !== -1;

                if (hasPageFilter) {
                    output = {
                        selector: '#header-filters',
                        offset: { x: 0, y: getOffsetY(10) },
                    };
                }
            }

            return output;
        },
        routes: [
            {
                path: '/',
                alias: '/homepage',
                name: 'Homepage',
                components: {
                    default: () => import('./pages/Homepage.vue'),
                    header: TheHeader,
                    footer: TheFooter,
                },
            },

            // Listing
            {
                path: '/c-:id(\\d+)',
                alias: [
                    '/c-:id(\\d+)/*',
                    '/s-:id(\\d+)',
                    '/s-:id(\\d+)/*',
                    '/b-:id(\\d+)',
                    '/b-:id(\\d+)/*',
                ],
                name: 'Listing',
                components: {
                    default: () => import('./pages/Listing.vue'),
                    header: TheHeader,
                    footer: TheFooter,
                },
            },
            {
                path: '/v-:id(\\d+)',
                alias: [
                    '/v-:id(\\d+)/*',
                ],
                name: 'ListingVendor',
                components: {
                    default: () => import('./pages/ListingVendor.vue'),
                    header: TheHeader,
                    footer: TheFooter,
                },
            },
            {
                path: '/products',
                name: 'ListingProducts',
                components: {
                    default: () => import('./pages/ListingProducts.vue'),
                    header: TheHeader,
                    footer: TheFooter,
                },
            },
            {
                path: '/collections',
                name: 'ListingCollections',
                components: {
                    default: () => import('./pages/ListingCollections.vue'),
                    header: TheHeader,
                    footer: TheFooter,
                },
            },
            {
                path: '/home-vibes',
                name: 'ListingProfiles',
                components: {
                    default: () => import('./pages/ListingProfiles.vue'),
                    header: TheHeader,
                    footer: TheFooter,
                },
            },
            {
                path: '/home-vibes/:id',
                name: 'Profile',
                components: {
                    default: () => import('./pages/Profile.vue'),
                    header: TheHeader,
                    footer: TheFooter,
                },
            },

            // Search
            {
                path: '/search',
                name: 'Search',
                components: {
                    default: () => import('./pages/Search.vue'),
                    header: TheHeader,
                    footer: TheFooter,
                },
            },
            {
                path: '/search-by-image',
                name: 'SearchByImage',
                components: {
                    default: () => import('./pages/SearchByImage.vue'),
                    header: TheHeader,
                    footer: TheFooter,
                },
            },

            // Product
            {
                path: '/p-:id(\\d+)',
                alias: '/p-:id(\\d+)/*',
                name: 'Product',
                components: {
                    default: () => import('./pages/Product.vue'),
                    header: TheHeader,
                    footer: TheFooter,
                },
            },

            // Shop the look
            {
                path: '/shop-the-look',
                name: 'ShopTheLook',
                components: {
                    default: () => import('./pages/ShopTheLook.vue'),
                    header: TheHeader,
                    footer: TheFooter,
                },
            },

            // Promo events
            {
                path: '/pe-:id(\\d+)',
                alias: '/pe-:id(\\d+)/*',
                name: 'PromoEvents',
                components: {
                    default: () => import('./pages/PromoEvents.vue'),
                    header: TheHeader,
                    footer: TheFooter,
                },
            },

            // Cart
            {
                path: '/cart',
                redirect: { name: 'CartIndex' },
                name: 'Cart',
                components: {
                    default: () => import('./pages/Cart.vue'),
                    header: TheHeader,
                    footer: TheFooter,
                },
                children: [
                    {
                        path: '/cart',
                        name: 'CartIndex',
                        component: () => import('./pages/CartIndex.vue'),
                    },
                    {
                        path: '/cart/checkout',
                        name: 'CartCheckout',
                        component: () => import('./pages/CartCheckout.vue'),
                    },
                    {
                        path: '/cart/payment',
                        name: 'CartPayment',
                        component: () => import('./pages/CartPayment.vue'),
                    },
                    {
                        path: '/cart/payment-return',
                        name: 'CartPaymentReturn',
                        component: () => import('./pages/CartPaymentReturn.vue'),
                    },
                    {
                        path: '/cart/retry-payment',
                        name: 'CartRetryPayment',
                        component: () => import('./pages/CartRetryPayment.vue'),
                    },
                    {
                        path: '/cart/thankyou',
                        name: 'CartThankYou',
                        component: () => import('./pages/CartThankYou.vue'),
                    },
                ],
            },
            {
                path: '/cart/index',
                redirect: '/cart',
            },
            // TODO this is legacy route, remove if is not needed
            {
                path: '/cart/reinstatecart',
                redirect: '/cart',
            },

            // Account
            {
                path: '/account',
                redirect: { name: 'AccountMe' },
                name: 'Account',
                components: {
                    default: () => import('./pages/Account.vue'),
                    header: TheHeader,
                    footer: TheFooter,
                },
                children: [
                    {
                        path: '/account/orders',
                        name: 'AccountOrders',
                        component: () => import('./pages/AccountOrders.vue'),
                    },
                    {
                        path: '/account/orders/:id(\\d+)',
                        name: 'AccountOrder',
                        component: () => import('./pages/AccountOrder.vue'),
                    },
                    {
                        path: '/account/me',
                        name: 'AccountMe',
                        component: () => import('./pages/AccountMe.vue'),
                    },
                    {
                        path: '/account/wallet',
                        name: 'AccountWallet',
                        component: () => import('./pages/AccountWallet.vue'),
                    },
                    {
                        path: '/account/reviews',
                        name: 'AccountReviews',
                        component: () => import('./pages/AccountReviews.vue'),
                    },
                    {
                        path: '/account/favorites',
                        name: 'AccountFavorites',
                        component: () => import('./pages/AccountFavorites.vue'),
                    },
                    {
                        path: '/account/collections',
                        name: 'AccountCollections',
                        component: () => import('./pages/AccountCollections.vue'),
                    },
                    {
                        path: '/account/bookmarks',
                        name: 'AccountBookmarks',
                        component: () => import('./pages/AccountBookmarks.vue'),
                    },
                    {
                        path: '/account/addresses',
                        name: 'AccountAddresses',
                        component: () => import('./pages/AccountAddresses.vue'),
                    },
                    {
                        path: '/account/returns',
                        name: 'AccountReturns',
                        redirect: { name: 'AccountReturnsList' },
                        component: () => import('./pages/AccountReturns.vue'),
                        children: [
                            {
                                path: '/account/returns/list',
                                name: 'AccountReturnsList',
                                component: () => import('./pages/AccountReturnsList.vue'),
                            },
                            {
                                path: '/account/returns/add',
                                name: 'AccountReturnsAdd',
                                component: () => import('./pages/AccountReturnsAdd.vue'),
                            },
                        ],
                    },
                    {
                        path: '/account/cards',
                        name: 'AccountCards',
                        component: () => import('./pages/AccountCards.vue'),
                    },
                    {
                        path: '/account/home-vibes',
                        name: 'AccountProfiles',
                        component: () => import('./pages/AccountProfiles.vue'),
                    },
                    {
                        path: '/account/home-vibes/add',
                        name: 'AccountProfileAdd',
                        component: () => import('./pages/AccountProfileAdd.vue'),
                    },
                    {
                        path: '/account/home-vibes/edit/:id',
                        name: 'AccountProfileEdit',
                        component: () => import('./pages/AccountProfileEdit.vue'),
                    },
                ],
            },

            // Account products
            {
                path: '/product-history',
                name: 'AccountProducts',
                components: {
                    default: () => import('./pages/Listing.vue'),
                    header: TheHeader,
                    footer: TheFooter,
                },
            },

            // Account login
            {
                path: '/login',
                name: 'Login',
                components: {
                    default: () => import('./pages/Login.vue'),
                    header: TheHeader,
                    footer: TheFooter,
                },
            },

            // Dynamic
            {
                path: '/re-:id(\\d+)',
                alias: '/re-:id(\\d+)/*',
                name: 'Regulation',
                components: {
                    default: () => import('./pages/Regulation.vue'),
                    header: TheHeader,
                    footer: TheFooter,
                },
            },
            {
                path: '/la-:id(\\d+)',
                alias: '/la-:id(\\d+)/*',
                name: 'Landing',
                component: () => import('./pages/Landing.vue'),
            },
            {
                path: '/rewards/:token',
                name: 'Rewards',
                components: {
                    default: () => import('./pages/Rewards.vue'),
                    header: TheHeader,
                    footer: TheFooter,
                },
            },
            {
                path: '/subscribed/:token',
                name: 'Subscribed',
                components: {
                    default: () => import('./pages/Subscribed.vue'),
                    header: TheHeader,
                    footer: TheFooter,
                },
            },

            // Static
            {
                path: '/jobs',
                name: 'Jobs',
                components: {
                    default: () => import('./pages/Jobs.vue'),
                    header: TheHeader,
                    footer: TheFooter,
                },
            },
            {
                path: '/about',
                name: 'About',
                components: {
                    default: () => import('./pages/About.vue'),
                    header: TheHeader,
                    footer: TheFooter,
                },
            },
            {
                path: '/legal',
                name: 'Legal',
                components: {
                    default: () => import('./pages/Legal.vue'),
                    header: TheHeader,
                    footer: TheFooter,
                },
            },
            {
                path: '/contact',
                name: 'Contact',
                components: {
                    default: () => import('./pages/Contact.vue'),
                    header: TheHeader,
                    footer: TheFooter,
                },
            },
            {
                path: '/black-friday',
                name: 'BlackFriday',
                component: () => import('./pages/BlackFriday.vue'),
            },
            {
                path: '/reset-password',
                alias: '/user/reset-password', // TODO remove this when backend ready
                name: 'ResetPassword',
                components: {
                    default: () => import('./pages/ResetPassword.vue'),
                    header: TheHeader,
                    footer: TheFooter,
                },
            },
            {
                path: '/register-set-password',
                name: 'RegisterSetPassword',
                components: {
                    default: () => import('./pages/RegisterSetPassword.vue'),
                    header: TheHeader,
                    footer: TheFooter,
                },
            },
            {
                path: '/return-feedback',
                name: 'ReturnFeedback',
                components: {
                    default: () => import('./pages/ReturnFeedback.vue'),
                    header: TheHeader,
                    footer: TheFooter,
                },
            },
            // 404
            {
                path: '*',
                name: 'NotFound',
                component: () => import('./pages/NotFound.vue'),
            },
        ],
    });

    if (typeof window !== 'undefined') {
        router.beforeEach((to, from, next) => {
            to.matched.forEach((route) => {
                const componentDefault = route.components.default;

                if (typeof componentDefault === 'function') {
                    componentDefault().then((component) => csrProcessComponent(component.default));
                }
            });

            next();
        });
    }

    return router;
}
