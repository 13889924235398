export default (author) => {
    switch (author.type) {
    case 'user':
        return { name: 'Profile', params: { id: author.id } };
    case 'vendor':
        return author.urlPath || '/';
    default:
        return { name: 'Homepage' };
    }
};
