import itemProduct from './item-product';

export default (payload) => Object.entries(payload.data).reduce((acc, [productId, productData]) => {
    acc[productId] = {
        queryParams: productData.query_params,
        items: productData.items.map(
            (item) => itemProduct(item, productData.query_params, productData.badges),
        ),
    };
    return acc;
}, {});
