import subtotalView from './subtotal-view';
import badgeStatusStyle from './badge-status-style';

function formatProducts(payload) {
    if (!payload) return false;

    return payload.map((product) => ({
        id: product.id,
        name: product.name,
        slug: product.slug,
        image: {
            src: product.image.src,
        },
        quantity: product.quantity,
        subtotal: product.subtotal,
        unitMeasure: product.unit_measure,
        unitPrice: product.unit_price,
        status: product.status.label,
        sellerDocuments: product.seller_documents,
    }));
}

function formatDeliveries(payload) {
    return payload.map((delivery) => ({
        id: delivery.id,
        awb: delivery.awb,
        awbData: delivery.awb_tracking,
        isMarketplace: delivery.marketplace,
        courierName: delivery.courier_name,
        title: delivery.title,
        addressLabel: delivery.delivery.address_label,
        status: badgeStatusStyle(delivery.status),
        edit: {
            cancelOrder: delivery.edit_props.cancelable_order,
            deliveryAddress: delivery.edit_props.editable_delivery_address,
            products: delivery.edit_props.editable_products,
        },
        documents: delivery.documents,
        products: formatProducts(delivery.products),
        summaryView: subtotalView(delivery),
    }));
}

function formatSellers(payload) {
    return payload.map((seller) => ({
        label: seller.label,
        deliveries: formatDeliveries(seller.deliveries),
        orderId: seller.deliveries[0].id,
    }));
}

function formatPayment(payload) {
    let bankTransfer = null;

    if (payload.bank_transfer_details) {
        bankTransfer = {
            sectionTitle: payload.bank_transfer_details.section_title,
            rows: payload.bank_transfer_details.rows.map((row) => ({
                label: row.row_label,
                data: row.row_data,
            })),
            footer: {
                label: payload.bank_transfer_details.footer.footer_label,
                data: payload.bank_transfer_details.footer.footer_data,
            },
        };
    }

    return {
        bankTransfer,
        label: payload.label,
        method: payload.method,
    };
}

function formatCancelReasons(payload) {
    return payload.map((reason) => ({
        id: reason.i18n_id,
        name: reason.name,
    }));
}

export default (payload) => ({
    id: payload.id,
    date: payload.date,
    title: payload.order_label,
    status: badgeStatusStyle(payload.status),
    currency: payload.currency,
    totalOrder: payload.total_order,
    totalOrderOld: payload.total_order_old,
    sellers: formatSellers(payload.sellers),
    retryPayment: payload.retryPayment || false,
    payment: formatPayment(payload.payment),
    cancelReasons: formatCancelReasons(payload.cancel_reasons),
    paymentStatus: payload.payment_status,
});
