import { HTTP_METHOD_TYPE } from '../utils/constants';

export default async function fetchItemsInternalLinkService({ $http, params }) {
    const response = await $http({
        method: HTTP_METHOD_TYPE.GET,
        url: `${$http.API.V3}internal-links-seo`,
        params,
    });

    const { link, navigation } = response.data.data;

    return {
        ...(link && { links: link }),
        ...(navigation && { navigation }),
    };
}
