export default class Filter {
    /**
     * @param {Object} config
     */
    constructor(config) {
        this.id = config.id;
        this.type = config.type;
        this.viewStyle = config.viewStyle;
        this.isDynamic = config.isDynamic;
        this.name = config.name;
        this.key = config.key;
        this.options = config.options;
        this.noFollow = config.noFollow;
        this.metas = config.metas;
        this.routeReset = config.routeReset;
    }
}
