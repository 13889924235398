export default {
    computed: {
        account() {
            return this.$store.state.account;
        },
        accountAddresses() {
            return this.account.addresses || {};
        },
        addresses() {
            return this.accountAddresses.addresses || [];
        },
        primaryAddress() {
            return this.addresses.find((a) => a.primary) || {};
        },
        primaryAddressId() {
            return this.primaryAddress.id || 0;
        },
    },

    methods: {
        showNewAddressModal() {
            this.$store.dispatch('account/addresses/setSelectedAddress', null);
            this.openModal('address');
        },
    },
};
