import { HTTP_METHOD_TYPE, ERROR_TYPE } from '../utils/constants';
import { itemProduct, pageList, queryParams } from '../utils/adaptors';

export async function fetchPageListingService({
    $http,
    route,
    query,
    params,
    data,
    id,
    isPersonalizedCategory,
    isPersonalizedSaleEvent,
    isPersonalizedSearch,
    pageSlug,
}) {
    const page = 1;
    const custom = {};
    const method = ((isPersonalizedCategory || isPersonalizedSaleEvent || isPersonalizedSearch)
        && HTTP_METHOD_TYPE.POST) || HTTP_METHOD_TYPE.GET;
    let perPage = 60;
    let url = '';

    perPage = params.perPage || perPage;

    // rename props to fit api
    delete Object.assign(params, { 'per-page': perPage }).perPage;
    if (params.userToken) delete Object.assign(params, { accessToken: params.userToken }).userToken;

    if (!params.page) Object.assign(params, { page });
    Object.assign(params, { page: Number(params.page) });

    if (pageSlug === 'c') {
        const slug = (!isPersonalizedSaleEvent) ? 'sale_event' : 'personalized-sale-event';
        url = `${$http.API.V3}${slug}`;

        Object.assign(params, { id });
        custom.query = query;
    } else if (pageSlug === 's') {
        const slug = (!isPersonalizedCategory) ? 'category' : 'personalized-category';
        url = `${$http.API.V2}${slug}/${id}`;
    } else if (pageSlug === 'b') url = `${$http.API.V2}brands/${id}/products`;
    else if (pageSlug === 'v') url = `${$http.API.V2}vendors/${id}/products`;
    else if (pageSlug === 'search') {
        const slug = (!isPersonalizedSearch) ? 'search' : 'personalized-search';
        url = `${$http.API.V1}${slug}`;
    } else if (route.path === '/product-history') {
        url = `${$http.API.V2}recently-viewed`;
        // TODO rename prop in api, from `pageSize` to `per-page` to match
        delete Object.assign(params, { pageSize: params['per-page'] })['per-page'];
    } else if (pageSlug === 'products') url = `${$http.API.V3}products`;

    const response = await $http({
        method,
        url,
        params,
        custom,
        data,
    });

    let responseData = response.data.data;
    if (pageSlug !== 'co') responseData = response.data;

    const { redirect_category: redirectCategory } = responseData;
    const hasCategoryRedirect = redirectCategory && redirectCategory.constructor === Object;
    if (hasCategoryRedirect) {
        const errorCategoryRedirect = new Error(ERROR_TYPE.REDIRECT);
        errorCategoryRedirect.response = {
            url: '/',
            code: redirectCategory.code || 301,
        };

        if (redirectCategory.id && redirectCategory.slug) {
            Object.assign(errorCategoryRedirect.response, {
                url: `/s-${redirectCategory.id}/${redirectCategory.slug}`,
            });
        }

        throw errorCategoryRedirect;
    }

    const error = responseData.error || false;
    if (error.redirect) {
        const errorRedirect = new Error(ERROR_TYPE.REDIRECT);
        errorRedirect.response = {
            url: error.redirect.path,
            code: error.statusCode,
        };
        throw errorRedirect;
    }

    return pageList(
        responseData,
        {
            page: params.page,
            perPage,
            route,
            pageSlug,
        },
    );
}

export async function fetchListingPromotedService({
    $http,
    params,
}) {
    const response = await $http({
        method: HTTP_METHOD_TYPE.GET,
        url: `${$http.API.V3}products/promoted/frame`,
        params,
    });

    if (!response.data.data.items.length) {
        return false;
    }

    const filteredProducts = response.data.data.items
        .map((item) => itemProduct(
            item,
            {
                ch_type: (response.data.data.queryParams
                    && response.data.data.queryParams.ch_type),
                ch_id: params.id,
            },
            response.data.data.badges,
        ));

    return {
        items: filteredProducts,
        queryParams: queryParams(response.data.data.queryParams),
    };
}
