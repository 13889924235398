import { HTTP_METHOD_TYPE } from '../utils/constants';
import { pageAccountFavorites } from '../utils/adaptors';

let perPage = 60;
let page = 1;

export default async function fetchFavoritesService({ $http, params }) {
    perPage = params.perPage || perPage;
    page = params.page || page;

    const response = await $http({
        method: HTTP_METHOD_TYPE.GET,
        url: `${$http.API.V2}wishlist`,
        params: {
            'per-page': perPage,
            page,
            ...params,
        },
    });

    // if user does not have favorites
    if (response.data.no_messages) {
        return [];
    }

    const responseData = {
        ...response.data,
        page,
        perPage,
    };

    const filteredResponse = pageAccountFavorites(responseData);

    return filteredResponse;
}
