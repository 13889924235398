import { HTTP_METHOD_TYPE } from '../utils/constants';

import { datalayerUser } from '../utils/adaptors';

export default async function saveUserPreferencesService({ $http, params, data }) {
    const response = await $http({
        method: HTTP_METHOD_TYPE.POST,
        url: `${$http.API.V2}user/preferences`,
        params,
        data: {
            preferences: data,
        },
    });

    return {
        result: response.data.result,
        datalayer: datalayerUser(response.data.dataLayer),
    };
}
