import { HTTP_METHOD_TYPE, ERROR_TYPE } from '../utils/constants';

export default async function fetchPageRegulationService({ $http, params }) {
    try {
        const response = await $http({
            method: HTTP_METHOD_TYPE.GET,
            url: `${$http.API.V2}regulation`,
            params,
        });

        return response.data;
    } catch (error) {
        let output = error;
        const { code, url } = error.response && error.response.data
            && typeof error.response.data[0] === 'object'
            && error.response.data[0];

        if ([301, 302, 307].includes(code)) {
            output = new Error(ERROR_TYPE.REDIRECT);
            output.response = {
                url,
                code,
            };
        }

        throw output;
    }
}
