import { warning, notification } from './cart';
import { CART_ERRORS, CART_NOTIFICATIONS } from '../constants';

export const formatCartErrorForCode = (error, output, errorCode) => {
    const errorNotification = notification(error.response.data, [errorCode]);
    if (errorNotification) {
        return {
            data: {
                notification: errorNotification,
            },
            code: errorCode,
        };
    }
    return output;
};

export default (error) => {
    // TODO get only whats needed from error object
    let output = error;

    if (error.response) {
        const apiErrors = error.response.data
            .filter((item) => item.code === 'REQUIRED' || item.code === 'EMAIL_USED_IN_OTHER_COUNTRY');
        if (apiErrors.length) {
            output = {
                data: apiErrors,
                code: 'API_ERRORS_OCCUR',
            };
        }

        output = formatCartErrorForCode(error, output, CART_ERRORS.INCOMPATIBLE_PAYMENT_TYPE);
        output = formatCartErrorForCode(error, output, CART_ERRORS.INVOICE_NOT_ALLOWED);

        const warningItem = warning(error.response.data);
        const notificationItem = notification(error.response.data, CART_NOTIFICATIONS);
        if (warningItem || notificationItem) {
            output = {
                data: {
                    warning: warningItem,
                    notification: notificationItem,
                },
                code: CART_ERRORS.INVALID_CART,
            };
        }
    }

    return output;
};
