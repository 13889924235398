import { scrollTo } from '..';

const nextValidator = (next, routeTo, routeFrom) => {
    const getRouteTo = (typeof routeTo === 'object') ? routeTo.path : routeTo;

    if (getRouteTo === routeFrom.path && routeFrom.name) throw new Error('You are trying to go to the same url path.');

    return next(getRouteTo);
};

export default ({
    usePath = false,
    modulePath = 'pages',
    trackPageView = true,
    appBtnBackToTop = false,
} = {}) => ({
    async beforeRouteEnter(to, from, next) {
        if (typeof window === 'undefined') {
            next();
            return;
        }

        const windowVue = window.app.__vue__; // eslint-disable-line no-underscore-dangle
        const $windowStore = windowVue && windowVue.$store;

        if ($windowStore && this && this.$methods) {
            if (this.$methods.registerModule) {
                await this.$methods.registerModule({
                    store: $windowStore,
                });
            }

            await this.$methods.mixinPageFetchCsr({
                store: $windowStore,
                methods: this.$methods,
                to,
                from,
                next,
            });
        }

        next(async (vm) => {
            try {
                if (!windowVue) {
                    await vm.mixinPageFetchCsr({
                        store: vm.$store,
                        methods: vm,
                        to,
                        from,
                        next,
                    });
                }

                await vm.$store.dispatch(`${modulePath}/pageLoading`, {
                    route: to,
                    loading: 'DONE',
                    usePath,
                });

                await vm.mixinPageViewedCsr();
            } catch (error) {
                console.error('beforeRouteEnter: ', error);

                await vm.$store.dispatch(`${modulePath}/pageLoading`, {
                    route: to,
                    loading: 'FAILED',
                    usePath,
                });
            }
        });
    },

    async beforeRouteUpdate(to, from, next) {
        try {
            await this.mixinPageFetchCsr({
                store: this.$store,
                methods: this,
                to,
                from,
                next,
            });

            await this.$store.dispatch(`${modulePath}/pageLoading`, {
                route: to,
                loading: 'DONE',
                usePath,
            });

            this.$store.state.ui.isVisible.web.appBtnBackToTop = false;

            await next();

            await this.mixinPageViewedCsr();
        } catch (error) {
            console.error('beforeRouteUpdate: ', error);

            await this.$store.dispatch(`${modulePath}/pageLoading`, {
                route: to,
                loading: 'FAILED',
                usePath,
            });
        }
    },

    computed: {
        pageData() {
            const pageKey = (typeof usePath === 'string' && usePath)
                || (usePath && this.$route.path)
                || this.$route.fullPath;

            return this.$store.getters[`${modulePath}/currentPage`](pageKey);
        },
    },

    async serverPrefetch() {
        if (this.registerModule) {
            await this.registerModule({
                store: this.$store,
            });
        }

        await this.mixinPageFetchSsr({ res: this.$ssrContext.res });

        await this.$store.dispatch(`${modulePath}/pageLoading`, {
            route: this.$route,
            loading: 'DONE',
            usePath,
        });
    },

    async created() {
        if (typeof window !== 'undefined') {
            if (this.registerModule) {
                await this.registerModule({
                    store: this.$store,
                });
            }

            // TODO remove this hack
            // this is needed because if homepage and csr, `beforeRouteEnter` it's not triggered
            if (this.$route.path === '/') {
                await this.mixinPageFetchCsr({
                    store: this.$store,
                    methods: this,
                    to: this.$route,
                });

                await this.$store.dispatch(`${modulePath}/pageLoading`, {
                    route: this.$route,
                    loading: 'DONE',
                    usePath,
                });

                await this.mixinPageViewedCsr();
            }
        }
    },

    methods: {
        async mixinPageFetchSsr({ res }) {
            if (this.pageFetch) {
                await this.pageFetch({
                    ssr: true,
                    store: this.$store,
                    route: this.$route,
                    res,
                });
            }
        },
        async mixinPageFetchCsr(payload) {
            payload.store.dispatch('ui/setLoading', { showPageLoader: true });

            if (payload.methods.pageFetch) {
                const next = (payload.next && payload.from)
                    ? (route, from) => nextValidator(
                        payload.next,
                        route,
                        from || payload.from,
                    )
                    : false;

                await payload.methods.pageFetch({
                    csr: true,
                    next,
                    store: payload.store,
                    route: payload.to,
                }).catch((error) => {
                    console.error('pageFetchError: ', error);
                });
            }

            payload.store.dispatch('ui/setLoading', { showPageLoader: false });
        },
        async mixinPageViewedCsr() {
            if (this.pageData.loading !== 'DONE') return;

            if (trackPageView) {
                if (!this.$store.state.datalayer) {
                    await new Promise((resolve) => {
                        const unwatch = this.$watch(
                            () => !!this.$store.state.datalayer,
                            () => {
                                resolve();
                                unwatch();
                            },
                        );
                    });
                }

                this.$store.dispatch('datalayer/pageView', { route: this.$route });
            }

            if (this.pageViewed) this.pageViewed();
            this.mixinScrollTo(this.$route.hash);

            if (appBtnBackToTop) {
                this.mixinAttachPageTopObserver();
            }
        },
        mixinScrollTo(hash) {
            if (!hash) return;

            scrollTo(hash);
        },
        mixinAttachPageTopObserver() {
            const observer = new IntersectionObserver((entries) => {
                const isPageTopNotVisible = entries[0].isIntersecting;
                if (isPageTopNotVisible) this.$store.state.ui.isVisible.web.appBtnBackToTop = true;
                else this.$store.state.ui.isVisible.web.appBtnBackToTop = false;
            }, {
                rootMargin: '200% 0px -200% 0px',
            });
            observer.observe(this.$el);
        },
    },
});
