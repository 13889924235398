import CONFIG_PROD from './prod';
import CONFIG_TEST from './test';
import CONFIG_DEV from './dev';
import CONFIG_LOCAL from './local';

const getFile = (env) => {
    let file = null;

    switch (env) {
    case 'production':
        file = CONFIG_PROD;
        break;
    case 'test':
        file = CONFIG_TEST;
        break;
    case 'development':
        file = CONFIG_DEV;
        break;
    case 'local':
    default: {
        file = CONFIG_LOCAL;
    }
    }

    if (!file) {
        throw new Error('Env file not found.');
    }

    return file;
};

const getDomainInfo = (env, domain) => {
    const fileDomains = getFile(env).DOMAINS;

    return {
        domain,
        ...fileDomains[domain],
    };
};

const getDomainById = (env, id, platform) => {
    const fileDomains = getFile(env).DOMAINS;

    return Object.keys(fileDomains).find((domain) => {
        const domainInfo = getDomainInfo(env, domain);

        return domainInfo.id === id && domainInfo.platform === platform;
    });
};

const getConfig = (env, domain) => {
    const { API, PARAMS } = getFile(env);
    const domainInfo = getDomainInfo(env, domain);
    API.BASEURL_NODE_API = API.BASEURL_NODE_API.replace('{domain}', domainInfo.domain);

    return {
        API,
        PARAMS,
    };
};

const AWS = {
    BASEURL: 'https://aws.vivre.eu/',
    ASSETS: 'https://aws.vivre.eu/assets/',
};

export {
    getDomainInfo,
    getDomainById,
    getConfig,
    AWS,
};
