import { HTTP_METHOD_TYPE, ERROR_TYPE } from '../utils/constants';
import { pageProductAdaptor } from '../utils/adaptors';

export default async function fetchPageProductService({ $http, id }) {
    try {
        const response = await $http({
            method: HTTP_METHOD_TYPE.GET,
            url: `${$http.API.V3}products/${id}`,
        });

        const { data: responseData } = response;

        return pageProductAdaptor(responseData.data, responseData.dataLayer);
    } catch (error) {
        const { code } = error.response && error.response.data && typeof error.response.data[0] === 'object' && error.response.data[0];

        if ([301, 302, 307, 404].includes(code)) {
            throw new Error(ERROR_TYPE.REDIRECT);
        }

        throw error;
    }
}
