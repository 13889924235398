var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("router-link", {
    attrs: { custom: "", to: _vm.to },
    scopedSlots: _vm._u(
      [
        {
          key: "default",
          fn: function ({ navigate, href: routerHref }) {
            return [
              _c(
                _vm.tag,
                {
                  tag: "component",
                  class: [
                    { [`btn btn-${_vm.variant}`]: _vm.variant },
                    { [`btn-${_vm.size}`]: _vm.size },
                    { "btn-loading": _vm.loading },
                  ],
                  attrs: {
                    href: _vm.tag === "a" && (_vm.href || routerHref),
                    type: _vm.tag === "button" && _vm.toButtonType,
                    disabled: _vm.disabled || _vm.loading,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.onClick($event, navigate)
                    },
                  },
                },
                [
                  _vm.loading
                    ? [
                        _vm._t("default"),
                        _vm._v(" "),
                        _c("span", {
                          staticClass: "spinner-border spinner-border-sm",
                          class: [{ "ms-2": !!_vm.$slots.default }],
                          attrs: { role: "status", "aria-hidden": "true" },
                        }),
                      ]
                    : _vm._t("default"),
                ],
                2
              ),
            ]
          },
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }