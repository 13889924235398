import { HTTP_METHOD_TYPE } from '../utils/constants';
import { hero, banners } from '../utils/adaptors';

export default async function fetchBannersService({ $http, params }) {
    const response = await $http({
        method: HTTP_METHOD_TYPE.GET,
        url: `${$http.API.BASEURL}banner/v1/display-banner`,
        params,
    });

    if (Array.isArray(response.data.banners)) {
        return banners(response.data.banners, params.position);
    }

    return hero(response.data.banners);
}
