const UUID_NAME = 'redirect-uuid';

export default {
    namespaced: true,

    state: () => ({}),

    actions: {
        login: async ({ dispatch, rootState }, { res, route }) => {
            if (
                !rootState.params.app.ssr
                || route === undefined
                || Boolean(!route.query[UUID_NAME]?.length)
            ) return null;

            try {
                const { accessToken, expiresIn } = await dispatch(
                    'user/fetchUserToken',
                    { token: route.query[UUID_NAME], type: 'redirect' },
                    { root: true },
                );

                const cookie = { key: 'api_v2_token', value: accessToken, expires: expiresIn };
                await dispatch('storage/add', cookie, { root: true });

                res.cookie(
                    cookie.key,
                    cookie.value,
                    { expires: new Date((Date.now() + cookie.expires * 1000)) },
                );
            } catch (error) {
                console.error('redirect-login action: ', error?.response?.data || error);
            }

            return null;
        },
        removeQueryParams: (_, { route, router }) => {
            if (!route.query[UUID_NAME]?.length) return null;

            return router.push(route.fullPath.replace(new RegExp(`&?${UUID_NAME}=[^&]+`), ''));
        },
    },
};
