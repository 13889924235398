const datalayerStockDistribution = (stockValue) => {
    let stockDistribution = '';
    if (stockValue === 0) {
        stockDistribution = 'Out of Stock';
    } else if (stockValue <= 5) {
        stockDistribution = '1-5';
    } else if (stockValue <= 10) {
        stockDistribution = '5-10';
    } else if (stockValue <= 20) {
        stockDistribution = '10-20';
    } else if (stockValue <= 30) {
        stockDistribution = '20-30';
    } else if (stockValue <= 50) {
        stockDistribution = '30-50';
    } else if (stockValue > 50) {
        stockDistribution = 'Over 50';
    }
    return stockDistribution;
};

export { datalayerStockDistribution as default };
