import { HTTP_METHOD_TYPE } from '../utils/constants';

export default async function fetchSliderCollectionsService({
    $http,
    id,
    contextType,
    params,
}) {
    const response = await $http({
        method: HTTP_METHOD_TYPE.GET,
        url: `${$http.API.V3}collections/${contextType}/${id}`,
        params,
    });

    return response.data;
}
