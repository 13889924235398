import { HTTP_METHOD_TYPE } from '../utils/constants';

export default async function notifyUserAboutProductService({ $http, params, data }) {
    // rename props to fit api
    delete Object.assign(data, { data: data.productID }).productID;

    try {
        const response = await $http({
            method: HTTP_METHOD_TYPE.POST,
            url: `${$http.API.BASEURL}user_notification_v1/create`,
            params,
            data: {
                type: 'stock_notification',
                ...data,
            },
        });

        return response.data;
    } catch (error) {
        console.error('service notifyUserAboutProductService Http: ', error);

        throw error;
    }
}
