const pageProfile = (payload) => {
    let cover = null;
    let localMedia = [];

    if (payload.media && payload.media.length > 0) {
        cover = {
            id: payload.media[0].id,
            src: payload.media[0].src,
            type: payload.media[0].mimeType.split('/')[0],
            mimeType: payload.media[0].mimeType,
        };

        localMedia = payload.media.slice(1).map((item) => ({
            id: item.id,
            src: item.src,
            type: item.mimeType.split('/')[0],
            mimeType: item.mimeType,
        }));
    }

    return {
        id: payload.id,
        title: payload.title,
        country: payload.country,
        type: payload.type,
        color: payload.tags.filter((tag) => tag.group === 'color').map((tag) => tag.name).join(', '),
        style: payload.tags.filter((tag) => tag.group === 'style').map((tag) => tag.name).join(', '),
        cover,
        media: localMedia,
        userId: payload.userId,
        ...(payload.links && {
            links: {
                prevUserId: payload.links.prev?.filters.userId,
                nextUserId: payload.links.next?.filters.userId,
            },
        }),
    };
};

export { pageProfile as default };
