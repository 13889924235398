import datalayerUser from './datalayer-user';

const COOKIES_KEYS = ['1', '5', '6'];

const preferencesCookies = (payload) => Object.keys(payload)
    .filter((key) => COOKIES_KEYS.includes(key));

const preferences = (payload) => Object.keys(payload).filter((key) => !COOKIES_KEYS.includes(key));

export default (payload) => ({
    uid: payload.uid,
    name: payload.name,
    alias: payload.alias,
    email: payload.email,
    phone: payload.phone,
    gender: payload.gender,
    guest: payload.guest,
    wallet: payload.wallet,
    credit: payload.credit,
    cartCount: payload.cart_count,
    wishlistNumber: payload.wishlist_number,
    preferences: preferences(payload.preferences),
    subscriptions: payload.subscriptions,
    newsletter: payload.newsletter,
    datalayer: datalayerUser(payload.dataLayer),

    uiCookies: preferencesCookies(payload.preferences),
});
