import { HTTP_METHOD_TYPE } from '../utils/constants';
import { cartMerge, cartMini, setupParamUser } from '../utils/adaptors';

export async function fetchCartService({ $http, user, params }) {
    if (typeof user.token === 'undefined' && typeof user.userSession === 'undefined') {
        throw new TypeError('cart request has no credentials');
    }

    const paramUser = setupParamUser(user);

    const response = await $http({
        method: HTTP_METHOD_TYPE.GET,
        url: `${$http.API.V4}cart`,
        params: {
            ...params,
            ...paramUser,
            view: 'mini',
        },
    });

    const filteredResponse = cartMini(response.data);

    return filteredResponse;
}

export async function mergeCartService({ $http, params }) {
    if (typeof params.accessToken === 'undefined' && typeof params.device_id === 'undefined') {
        throw new TypeError('cart request has no credentials');
    }

    const response = await $http({
        method: HTTP_METHOD_TYPE.GET,
        url: `${$http.API.V4}cart_merge`,
        params,
    });

    return cartMerge(response.data);
}
