export default {
    methods: {
        datalayerPageviewProduct() {
            const payload = {
                ...this.data.datalayer['product-data'],
                datalayer: this.data.datalayer['product-data'],
                queryParams: {
                    ch_id: this.$route.query.ch_id,
                    ch_type: this.$route.query.ch_type,
                },
            };

            this.$store.dispatch('datalayer/pageviewProduct', payload);
        },
    },
};
