export default {
    computed: {
        storeGdprIds() {
            return this.$store.getters['ui/gdprDefaultKeys'];
        },
        selectedGdprIds() {
            return Object.keys(this.form.data).filter((key) => this.form.data[key].enabled);
        },
    },

    methods: {
        handleGdprForm({ prefs, closeModal = true }) {
            this.$store.dispatch('ui/saveGdprPreferences', prefs);
            this.$store.dispatch('ui/saveCookieConsent', prefs);
            this.$store.dispatch('ui/setGdpr', prefs);
            if (!prefs.includes('5')) {
                this.$store.dispatch('ui/deleteTrackingCookies', prefs);
            }

            if (closeModal) this.closeModal('gdpr');
            this.datalayerTrackGdprPreferences();
        },
        datalayerTrackGdprPreferences() {
            this.$store.dispatch('datalayer/cookiePreferences', this.$store.state.ui.gdpr);
        },
    },
};
