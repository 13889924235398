import formatLabels from './format-labels';

const itemProductSimilarEan = (payload, queryParams = {}) => ({
    id: payload.id,
    name: payload.name,
    path: payload.slug,
    imageSrc: payload.image.src,
    price: payload.price,
    priceDecimal: payload.price_decimal,
    ...(payload.lowest_price_30d && { priceStrikethrough: payload.lowest_price_30d }),
    originalPrice: payload.original_price,
    completePrice: payload.completePrice,
    priceConversion: payload.priceConversion,
    currency: payload.currency,
    vendor: payload.vendor,
    ...(payload.notification
        && { notification: formatLabels([payload.notification])[0] }),
    datalayer: {
        queryParams: payload.query_params && Object.keys(payload.query_params).length > 0
            ? { ...queryParams, ...payload.query_params }
            : queryParams,
    },
});

export { itemProductSimilarEan as default };
