import axios from 'axios';
import { AWS } from '../configs';
import cookies from './cookies';

const isSSR = typeof window === 'undefined' || false;
const removeSSL = (url) => url.replace(/^https:\/\//i, 'http://');
const log = (config) => console.info(`axios.${config.method}('${config.url}')`);

let axiosInstance;
let GLOBAL_QUERY_PARAMS = {};

const http = ({
    params,
    other: {
        authorizationBearer,
        debug,
        API,
        PARAMS,
        DOMAIN,
    },
}) => {
    axiosInstance = axios.create({
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            'Accept-Language': params.lang,
            'X-Platform': params.platform,
        },
        params,
        timeout: 10000,
    });

    axiosInstance.interceptors.response.use((response) => {
        if (response.config.url === `${AWS.ASSETS}vivre.json` && response.data.global && response.data.global.query) {
            GLOBAL_QUERY_PARAMS = response.data.global.query;
        }

        return response;
    });

    axiosInstance.interceptors.request.use((config) => {
        if (config.url.indexOf(API.BASEURL_CASTA) > -1) {
            const getAuthorizationBearer = authorizationBearer || cookies.read('api_v2_token');
            Object.assign(config, {
                headers: {
                    ...config.headers,
                    'X-Apikey': PARAMS.casta.apiKey,
                    'X-Appid': PARAMS.casta.appId,
                    ...(!!getAuthorizationBearer && {
                        Authorization: `Bearer ${getAuthorizationBearer}`,
                    }),
                },
            });
        }

        if (config.params.accessToken) {
            Object.assign(config, {
                headers: {
                    ...config.headers,
                    Authorization: `Bearer ${config.params.accessToken}`,
                },
            });

            const { accessToken, ...paramsCleaned } = config.params;
            Object.assign(config, {
                params: paramsCleaned,
            });
        }

        Object.assign(config, {
            params: {
                ...config.params,
                ...GLOBAL_QUERY_PARAMS,
            },
        });

        if (isSSR) {
            Object.assign(config, {
                url: removeSSL(config.url),
            });
        }

        if (debug) log(config);

        return config;
    });

    axiosInstance.API = { ...API };
    axiosInstance.DOMAIN = DOMAIN;

    return axiosInstance;
};

export default http;
