export default {
    methods: {
        datalayerCartView(pageName) {
            const {
                'product-data': { products },
                revenue_eur: value,
            } = this.$store.state.cart.dataLayer;

            const payload = {
                eventName: pageName,
                products,
                value,
            };
            this.$store.dispatch('datalayer/cartView', payload);
        },

        datalayerCartSucces(payload) {
            this.$store.dispatch('datalayer/transactionSuccess', payload);
        },

        datalayerPaymentInfo(payload) {
            this.$store.dispatch('datalayer/paymentInfo', payload);
        },

        datalayerPlaceOrder(payload) {
            this.$store.dispatch('datalayer/placeOrder', payload);
        },
    },
};
