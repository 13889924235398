import { SOURCE } from '../constants';

export default {
    computed: {
        isMobile() {
            return this.$store.state.params.source === SOURCE.MOBILE;
        },
        isSite() {
            return this.$store.state.params.source === SOURCE.SITE;
        },
    },
};
