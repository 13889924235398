export default async function fetchPageSubscribedService({
    $http,
    method,
    token,
    params,
    data,
}) {
    const response = await $http({
        method,
        url: `${$http.API.V3}user/subscriptions/${token}`,
        params,
        data,
    });

    return response.data;
}
