import { HTTP_METHOD_TYPE } from '../utils/constants';

export async function addToFavoritesService({ $http, params, data }) {
    const response = await $http({
        method: HTTP_METHOD_TYPE.POST,
        url: `${$http.API.V2}wishlist`,
        params,
        data: {
            product_id: data.productId,
        },
    });

    return response.data;
}

export async function removeFromFavoritesService({ $http, params, data }) {
    const response = await $http({
        method: HTTP_METHOD_TYPE.DELETE,
        url: `${$http.API.V2}wishlist`,
        params,
        data: {
            product_id: data.productId,
        },
    });

    return response.data;
}
