import itemProduct from './item-product';

function filteredProductsData(items, badges, queryParams) {
    return items.map((item) => {
        const formattedItem = itemProduct(item, undefined, badges);

        if (queryParams && queryParams.ch_id && queryParams.ch_type) {
            formattedItem.slug = `${formattedItem.slug}?ch_id=${queryParams.ch_id}&ch_type=${queryParams.ch_type}`;
        }

        delete formattedItem.datalayer;
        return formattedItem;
    });
}

export default (payload) => ({
    items: filteredProductsData(payload.items, payload.badges, payload.query_params),
    page: {
        current: payload.page,
        total: payload.page_info.total_results,
        totalPages: payload.page_info.total_pages,
        per: payload.perPage,
    },
});
