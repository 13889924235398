const DOMAINS = {
    'ro.vue.test.vivre.eu': { id: 1, lang: 'ro', platform: 'vivre' },
    'bg.vue.test.vivre.eu': { id: 2, lang: 'bg', platform: 'vivre' },
    'hu.vue.test.vivre.eu': { id: 3, lang: 'hu', platform: 'vivre' },
    'hr.vue.test.vivre.eu': { id: 4, lang: 'hr', platform: 'vivre' },
    'pl.vue.test.vivre.eu': { id: 5, lang: 'pl', platform: 'vivre' },
    'eu.vue.test.vivre.eu': { id: 6, lang: 'en', platform: 'vivre' },
    'si.vue.test.vivre.eu': { id: 7, lang: 'si', platform: 'vivre' },
    'sk.vue.test.vivre.eu': { id: 8, lang: 'sk', platform: 'vivre' },
    'cz.vue.test.vivre.eu': { id: 9, lang: 'cz', platform: 'vivre' },
    'gr.vue.test.vivre.eu': { id: 10, lang: 'gr', platform: 'vivre' },
    'md.vue.test.vivre.eu': { id: 11, lang: 'md', platform: 'vivre' },
    'ro.vue.test.casta.eu': { id: 1, lang: 'ro', platform: 'casta' },
    'bg.vue.test.casta.eu': { id: 2, lang: 'bg', platform: 'casta' },
    'hu.vue.test.casta.eu': { id: 3, lang: 'hu', platform: 'casta' },
    'hr.vue.test.casta.eu': { id: 4, lang: 'hr', platform: 'casta' },
    'pl.vue.test.casta.eu': { id: 5, lang: 'pl', platform: 'casta' },
    'eu.vue.test.casta.eu': { id: 6, lang: 'en', platform: 'casta' },
    'si.vue.test.casta.eu': { id: 7, lang: 'si', platform: 'casta' },
    'sk.vue.test.casta.eu': { id: 8, lang: 'sk', platform: 'casta' },
    'cz.vue.test.casta.eu': { id: 9, lang: 'cz', platform: 'casta' },
    'gr.vue.test.casta.eu': { id: 10, lang: 'gr', platform: 'casta' },
    'md.vue.test.casta.eu': { id: 11, lang: 'md', platform: 'casta' },
};

const API = {
    BASEURL: 'https://api-test.vivre.eu/',
    BASEURL_NODE_API: '//{domain}/api/',
    BASEURL_COURIER: 'https://api-test.vivre.eu/courier/v1/',
    BASEURL_PRODUCT_STOCK: 'https://product-stock-test.services.vivre.eu/',
    BASEURL_CASTA: 'https://api.casta.eu/',
};
API.V1 = `${API.BASEURL}mobile_v1/`;
API.V2 = `${API.BASEURL}mobile/v2/`;
API.V3 = `${API.BASEURL}mobile/v3/`;
API.V4 = `${API.BASEURL}mobile/v4/`;

const PARAMS = {
    speedy: {
        token: '?9zEgv/CLpAjqtqy7tk62rB8kOcAGwAcJ6lUCHd2Zbnov5H2ZNkBPaw==',
    },
    adyen: {
        clientKey: 'live_ZJO43MMY5JCNFN4NPUFFW2DPNUUZ27RC',
        env: 'live',
        publicKey: '10001|D73D190BB42F27527D958B340F5C44CF28097B921F7F0C67A2607F2F2175F38387917E049CE91F184FD262DA08D25349A60B98C2709AE0C5DA4CC725EDF4A4FD8215357668B6F301B6ECD8D970CAAB3E21BCCD1D50B9DC71FC7CFDA82CEE309249421030CB3781CB7EEFF1566C5EC9B8BBC51EBC7D69C70F05E64B886607DE00D1390D231C7AD86AF8EA4BE8E13B06953D9693485C9835F112152960E7A5F2BE2178BADE3536262DB4C54FD2666E6D2F78FD1DB69580D72F6CB3A52D55554DB5A751AF74D9FF1D2CAE8AA83C5401A094737A0B180AF3DC626BED0CBE30C2410CCE2F033C1DF1AA00AB4CBCAE01AD3A1DFE8BBAD2E06FAEC100BB97441F361CFB',
        merchantId: 'merchant.com.adyen.VIVREDECOTEST',
    },
    casta: {
        apiKey: 'I2ttBtg8lqrxe39PGYq1TBpqiTDusHrfExYgpYxK67ThpeYgjhY5P2OVxph2pPqCBUkTJySCaVQncgOInn9yPg',
        appId: '081f4c94-0524-11ee-a962-42010a54400f',
    },
    google: {
        maps: {
            apiKey: 'AIzaSyC5N1HWwUbtsYqmQ2w45_YQPmw5URBZgDY',
        },
        captcha: {
            siteKey: {
                vivre: '6Lc5ZyIUAAAAAOr1PKLB95X-C_CvjI-4PJeXOZDG',
                casta: '6Ld0fhciAAAAACjh_j9fSrEkqIUhZo-iV0cT5iOV',
            },
        },
    },
};

export default {
    DOMAINS,
    API,
    PARAMS,
};
