import { HTTP_METHOD_TYPE } from '../utils/constants';

const getStripeSettings = async ({
    $http,
    params,
}) => {
    const endpoint = (params.orderId ? 'stripe/initiate' : 'stripe/initiate-checkout');

    const response = await $http({
        url: `${$http.API.V3}${endpoint}`,
        params,
    });

    return response.data.data;
};

const postStripePay = async ({
    $http,
    params,
    data,
}) => {
    const response = await $http({
        method: HTTP_METHOD_TYPE.POST,
        url: `${$http.API.V3}stripe/pay`,
        params,
        data,
    });

    return response;
};

export { getStripeSettings, postStripePay };
