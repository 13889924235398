import listFilter from './list-filter';
import itemProduct from './item-product';
import queryParams from './query-params';
import itemCategoryNavigation from './item-category-navigation';

const enrichOutput = (source, data, payload) => {
    const output = { ...source };

    if (data.title && ['c', 'co', 's', 'b', 'v'].includes(payload.pageSlug)) output.title = data.title;

    if (payload.pageSlug === 'c') {
        output.saleEvent = {
            description: data.description,
            image: data.large_image && data.large_image.src,
            remainingTime: data.f_remaining_time,
            status: data.status,
        };
        output.datalayerListDetailsName = data.dataLayer['sale-event-data'].name_en;

        if (data.breaks) {
            let count = 6;

            for (let i = 0; i < data.breaks.length; i += 1) {
                output.items.data.splice(i + count, 0, data.breaks[i]);
                count += 6;
            }
        }
    } else if (payload.pageSlug === 's') {
        if (Array.isArray(data.category_breadcrumbs) && data.category_breadcrumbs.length) {
            output.categoryBreadcrumbs = data.category_breadcrumbs;
        }

        output.datalayerListDetailsName = data.dataLayer['category-event-data'].name_en;
    } else if (['b', 'v'].includes(payload.pageSlug) && data.title) {
        output.datalayerListDetailsName = data.title;
    } else if (payload.pageSlug === 'search') {
        output.datalayerListDetailsName = 'search result';
    } else if (payload.route.path === '/product-history') {
        output.accountProducts = true;
        output.titleKey = 'views_product_alternatives_recent_view_header';
        output.datalayerListDetailsName = 'product history';
    }

    if (['s', 'products'].includes(payload.pageSlug)) {
        if (Array.isArray(data.top_thumbnail_categories)) {
            output.categoryNavigation = data.top_thumbnail_categories
                .filter((item) => !item.hidden && item.type === 'category')
                .map((item) => itemCategoryNavigation(item));
        }
    }

    return output;
};

const formatSortChoices = (payload) => {
    if (!payload || !Object.keys(payload).length) return false;

    return payload.choices.map((item) => ({
        text: item.label,
        key: item.value,
        selected: item.is?.checked || false,
    }));
};

export default (data, payload) => {
    const items = data.items.map((item) => itemProduct(item, data.query_params, data.badges));

    const filters = (
        data.filters
        && data.filters.length
        && listFilter(data.filters, payload.route)
    );

    return enrichOutput(
        {
            author: data.author,
            id: data.id,
            items: {
                data: items,
                page: {
                    current: payload.page,
                    total: data.page_info.total_results,
                    totalPages: data.page_info.total_pages,
                    per: payload.perPage,
                },
                queryParams: queryParams(data.query_params),
            },
            categoryDescription: data.category_seo_description,
            categoryNavigation: [],
            categoryBreadcrumbs: [],
            datalayerListDetailsName: null,
            sortChoices: formatSortChoices(data.sort),
            filters,
        },
        data,
        payload,
    );
};
