import itemProduct from './item-product';

const MAX_QUERIES = 5;

const sliceQueries = (results) => (results?.length > 0 ? results.slice(0, MAX_QUERIES) : []);

const formatCategories = (payload) => ({
    title: payload.title,
    items: payload.items.map((item) => ({
        name: item.name,
        slug: `/${item.slug}`,
    })),
});

const formatProducts = (payload) => {
    if (
        !payload.items
        || !payload.items.length > 0
    ) return false;

    return {
        title: payload.title,
        items: payload.items.map((item) => {
            const output = {
                ...itemProduct(item, payload.query_params),
            };

            delete output.variants;
            delete output.labels;
            delete output.notification;

            return output;
        }),
        queryParams: payload.query_params,
    };
};

export default (payload) => {
    let { title } = payload.popular;
    let queries = sliceQueries(payload.popular.items);
    const categories = formatCategories(payload.categories);
    const products = formatProducts(payload.products);

    if (payload.queries.length > 0) {
        title = null;
        queries = sliceQueries(payload.queries);
    }

    return {
        title,
        queries,
        categories,
        products,
    };
};
