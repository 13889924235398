export default class FilterOption {
    /**
     * @param {Object} config
     */
    constructor(config) {
        this.id = config.id;
        this.type = config.type;
        this.name = config.name;
        this.value = config.value;
        this.selected = config.selected;
        this.noFollow = config.noFollow;
        this.url = null;
    }
}
