export function intersect(first, second) {
    return first.filter((element) => second.includes(element));
}

export function difference(first, second) {
    return first.filter((element) => (!second.includes(element)));
}

// TODO replace this with objectFilterProps
export function removePropertiesFromObject(obj, props) {
    for (let i = 0; i < props.length; i += 1) {
        // eslint-disable-next-line no-param-reassign
        delete obj[props[i]];
    }

    return obj;
}

export function reindexBy(collection, key) {
    const obj = {};

    for (let i = 0; i < collection.length; i += 1) {
        // eslint-disable-next-line no-prototype-builtins
        if (collection[i].hasOwnProperty(key)) {
            obj[collection[i][key]] = collection[i];
        }
    }

    return obj;
}
