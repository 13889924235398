<template>
    <div
        v-if="notifications.length"
        class="the-notifications"
    >
        <template v-for="(notification, index) in notifications">
            <base-modal
                v-if="notification.type === 'modal-alert'"
                :key="`notification-${index}`"
                :visible="notification.visible"
                :title="notification.content.title"
                :variant="notification.variant"
                :hide-backdrop="true"
                modal-class="modal-alert"
                @hidden="close({index})"
                @ok="notification.callback.ok()"
            >
                {{ notification.content.body }}
            </base-modal>
            <base-toast
                v-else-if="notification.type === 'toast'"
                :key="`notification-${index}`"
                :visible="notification.visible"
                :title="notification.content.title"
                :variant="notification.variant"
                :auto-hide="notification.autoHide"
                :position-y="notification.positionY"
                @hidden="close({index})"
            >
                <span v-html="notification.content.body" />
            </base-toast>
            <toast-newsletter
                v-else-if="notification.type === 'toast-newsletter'"
                :key="`notification-${index}`"
                :visible="notification.visible"
                :tracking-params="notification.trackingParams"
                @hidden="close({index})"
            />
            <modal-newsletter
                v-else-if="notification.type === 'modal-newsletter'"
                :key="`notification-${index}`"
                :visible="notification.visible"
                :tracking-params="notification.trackingParams"
                @hidden="close({index})"
            />
        </template>
    </div>
</template>

<script>
import { defineAsyncComponent } from 'vue';

import { Timer } from '../utils';

const BaseModal = defineAsyncComponent(() => import('./BaseModal.vue'));
const BaseToast = defineAsyncComponent(() => import('./BaseToast.vue'));
const ToastNewsletter = defineAsyncComponent(() => import('./ToastNewsletter.vue'));
const ModalNewsletter = defineAsyncComponent(() => import('./ModalNewsletter.vue'));

export default {
    name: 'TheNotifications',

    components: {
        BaseModal,
        BaseToast,
        ToastNewsletter,
        ModalNewsletter,
    },

    computed: {
        isLoggedin() {
            return this.$store.getters['user/isLoggedin'];
        },
        notifications() {
            return this.$store.state.ui.notifications.data;
        },
        isNotificationNewsletterVisible() {
            let output = false;
            const isUserSubscribed = this.$store.state.user.newsletter;
            const wasNotificationClosed = this.$store.getters['storage/parsedKeyValue']('notification-newsletter-closed');
            const isHomepage = this.$route.name === 'Homepage';

            if (!isUserSubscribed && !wasNotificationClosed && !isHomepage) output = true;

            return output;
        },
    },

    watch: {
        '$route.name': function $routeName(routeName) {
            if (!this.$ssrContext) {
                if (!this.pushNotificationNewsletter(routeName)) {
                    window.timerNotificationNewsletter?.stop();
                } else {
                    window.timerNotificationNewsletter?.start();
                }
            }
        },
        isNotificationNewsletterVisible(visible) {
            if (visible) {
                this.pushNotificationNewsletter();
            }
            this.updateVisible('notification-newsletter', visible);
        },
        isLoggedin() {
            this.$store.dispatch('storage/delete', 'notification-newsletter-closed');

            this.$store.dispatch('ui/notifications/delete', { id: 'notification-newsletter' });
            this.pushNotificationNewsletter();
        },
    },

    mounted() {
        this.pushNotificationNewsletter();
    },

    methods: {
        close(payload) {
            this.$store.dispatch('ui/notifications/hide', payload);
        },
        updateVisible(id, visible) {
            this.$store.dispatch('ui/notifications/visible', {
                id,
                visible,
            });
        },
        pushNotificationNewsletter(routeName = this.$route.name) {
            const isRoutePermitted = [
                'Cart',
                'Account',
                'Landing',
            ].filter((route) => routeName.includes(route)).length === 0;

            if (!isRoutePermitted || !this.isNotificationNewsletterVisible) return false;

            window.timerNotificationNewsletter = window.timerNotificationNewsletter
                || new Timer(() => {
                    this.$store.dispatch('ui/notifications/push', {
                        type: 'modal-newsletter',
                        id: 'notification-newsletter',
                        visible: true,
                        trackingParams: { i_source: 'cns_subscribe' }, // TODO chech if cns needs a rename
                    });
                }, 15000);

            return true;
        },
    },
};
</script>

<style lang="scss">
@import "./../scss/required";

.the-notifications {
    position: sticky;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: $zindex-sticky;
}
</style>
