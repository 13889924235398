let perPage = 10;
let page = 1;

export default async ({ $http, params }) => {
    perPage = params.perPage || perPage;
    page = params.page || page;

    const response = await $http({
        method: 'get',
        url: `${$http.API.V3}user/collections`,
        params: {
            'per-page': perPage,
            page,
            ...params,
        },
    });

    const { items, pagination } = response.data.data;

    return {
        items,
        pagination,
    };
};
