import { HTTP_METHOD_TYPE } from '../utils/constants';
import { headerSearch } from '../utils/adaptors';

export default async function searchTermService({ $http, params }) {
    const response = await $http({
        method: HTTP_METHOD_TYPE.GET,
        url: `${$http.API.V2}search-suggester`,
        params,
    });

    return headerSearch(response.data);
}
