import { HTTP_METHOD_TYPE } from '../utils/constants';
import { itemProductMini, queryParams } from '../utils/adaptors';

const PAGE = 1;

export default async function fetchPageShopTheLookService({ $http, params }) {
    const page = params.page || PAGE;

    const response = await $http({
        method: HTTP_METHOD_TYPE.GET,
        url: `${$http.API.V3}shop-the-look/list/5`,
        params: {
            ...params,
            page,
        },
    });

    const { data, data: { page_info: pageInfo } } = response;

    const items = data.items && data.items.map((shopTheLook) => {
        const filteredProducts = shopTheLook.products
            .map((item) => itemProductMini(item, queryParams(shopTheLook.query_params)));

        return {
            id: shopTheLook.id,
            name: shopTheLook.name,
            image: shopTheLook.image,
            products: filteredProducts,
        };
    });

    return {
        items: {
            data: items,
            page: {
                current: pageInfo.current_page,
                total: pageInfo.total_results,
                totalPages: pageInfo.total_pages,
                per: pageInfo.results_per_page,
            },
        },
    };
}
