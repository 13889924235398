import { ProductHistoryAdaptor } from '../../utils/adaptors';
import { fetchProductHistoryService } from '../../services';

export default {
    namespaced: true,

    actions: {
        async fetchHistory({
            commit, rootState, rootGetters, dispatch,
        }, {
            isProductPreview = true,
            pageSize,
        }) {
            try {
                const storageProductHistory = rootGetters['storage/parsedKeyValue']('product-history') || [];
                const productHistoryAdaptor = new ProductHistoryAdaptor(storageProductHistory);

                const accessToken = rootState.storage.api_v2_token;
                const response = await fetchProductHistoryService({
                    $http: this.$http,
                    params: {
                        ...(accessToken && { accessToken }),
                        ids: productHistoryAdaptor.sorted.ids,
                        pageSize,
                    },
                    items: productHistoryAdaptor.sorted.items,
                    isProductPreview,
                });

                const type = isProductPreview ? 'historyPreview' : 'history';
                commit('shared/SET_COMPONENT_DATA', {
                    components: {
                        [type]: response,
                    },
                }, { root: true });
                dispatch('shared/fetchFavoriteProducts', response.items, { root: true });
            } catch (error) {
                console.error('fetchHistory: ', error);
            }
        },
    },
};
