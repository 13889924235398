var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(require(`../assets${_vm.path}/${_vm.name}.svg?inline`).default, {
    tag: "component",
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }