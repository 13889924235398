class ProductHistoryAdaptor {
    #payload = [];

    data = {
        sorted: {
            ids: '',
            items: {},
        },
    };

    constructor(payload) {
        this.#payload = payload;

        this.setSorted();
    }

    setSorted() {
        this.#payload
            .sort((a, b) => b.timestamp - a.timestamp)
            .filter((v, i, a) => a.findIndex((t) => (t.id === v.id)) === i)
            .forEach(((item, index) => {
                this.data.sorted.ids += `${index === 0 ? '' : ','}${item.id}`;
                this.data.sorted.items[item.id] = {
                    chType: item.chType,
                    chId: item.chId,
                };
            }));
    }

    get sorted() {
        return this.data.sorted;
    }

    get idByTimestamp() {
        const output = {};

        this.#payload.forEach((item) => {
            output[item.timestamp] = item.id;
        });

        return output;
    }
}

export default ProductHistoryAdaptor;
